import React, { useEffect, useState } from "react";
import {
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import axios from "axios";
import MainCard from "../../../_Components/Common/Cards/MainCard";
import { PrimaryBgBtn, SecondaryOutlineBtn } from "../../../_Components/Common/Buttons/CustomBtns";
import Loader from "../../../_Components/Utils/loader/Loader";

const CurrentTermSemester = () => {
  const [academicYear, setAcademicYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [classes, setClasses] = useState([]);
  const [examNumbers, setExamNumbers] = useState([]);
  const [selectedExamNumber, setSelectedExamNumber] = useState(null);
  const [formValues, setFormValues] = useState([{ stdClass: "" }]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [messageResponse, setMessageResponse] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [data, setData] = useState(null);
const [examTypes, setExamTypes] = useState("");
  const nToken = localStorage.getItem("nToken");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const classURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection?schoolcode=${userinfo[0]?.institution}`;
        const classResponse = await axios.get(classURL, {
          headers: { Authorization: `Bearer ${nToken}` },
        });
        setClasses([...new Set(classResponse.data.class_section.map((item) => item.class))]);

        const yearURL = `${process.env.REACT_APP_nodewsPrefix}/getAcademicYear?schoolcode=${userinfo[0]?.institution}`;
        const yearResponse = await axios.get(yearURL, {
          headers: { Authorization: `Bearer ${nToken}` },
        });
        // setAcademicYear(yearResponse.data.academic_year_data);
        const data = yearResponse.data.academic_year_data;
        setAcademicYear(data)
        if (data.length > 0) {
          setSelectedYear(data[0].academic_year_id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setIsSubmitting(false);
    setFormValues([{ stdClass: "" }])
    setExamNumbers([]);
    setSelectedExamNumber(null);
  };
  const handleConfirm = async () => {
    if (!selectedExamNumber) {
      alert("Please select an exam.");
      return;
    }
  
    const requestData = {
      active_exam_number_id: data.active_exam_number_id, // ID from API response
      active_exam_number: selectedExamNumber, // User-selected exam number
    };
  
    try {
       
      const updateURL = `${process.env.REACT_APP_nodewsPrefix}/updateExamCount`
      const response = await axios.post(updateURL, requestData, {
        headers: { Authorization: `Bearer ${nToken}` },
      });
  
      console.log("Update Response:", response.data);
  
      if (response.data.success) {
        alert("Exam selection updated successfully!");
        handleClose(); // Close the dialog after success
      } else {
        alert("Failed to update exam selection. Please try again.");
      }
    } catch (error) {
      console.error("Error updating exam count:", error);
      alert("An error occurred while updating. Please try again.");
    }
  };
  

  const handleYearChange = (event) => setSelectedYear(event.target.value);
  const handleClassChange = (event) => {
    setFormValues([{ stdClass: event.target.value }]);
  };

  const handleExamSelection = (event) => {
    setSelectedExamNumber(parseInt(event.target.value));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setIsLoader(true);

    const requestData = {
      academic_year: selectedYear,
      class: formValues[0].stdClass,
      schoolcode: userinfo[0]?.institution,
    };

    try {
      const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getExamCount?schoolcode=${userinfo[0]?.institution}&class=${requestData.class}&academic_year=${requestData.academic_year}`;
      const response = await axios.get(baseURL, {
        headers: { Authorization: `Bearer ${nToken}` },
      });

      console.log("Response:", response.data);
      setData(response.data);
      setMessageResponse(response.data.message);

      if (response.data.error) {
        setOpen(true);
      } else if (response.data.exam_count) {
        setExamNumbers([...Array(response.data.exam_count)].map((_, i) => i + 1));
        setSelectedExamNumber(response.data.active_exam_number);
         setExamTypes(response.data.examtype_name)
        setOpen(true);
      }
    } catch (error) {
      console.error("Error fetching exam count:", error);
    }
  };

  return (
    <MainCard cardTitle="Progress Report">
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center", flexWrap: "wrap" }}>
        <FormControl size="small" sx={{ minWidth: 180 }}>
          <InputLabel>Academic Year</InputLabel>
          <Select value={selectedYear} onChange={handleYearChange} label="Academic Year">
            {Array.isArray(academicYear) &&academicYear.map((year) => (
              <MenuItem key={year.academic_year_id} value={year.academic_year_id}>
                {year.academic_year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: 100 }}>
          <InputLabel>Class</InputLabel>
          <Select value={formValues[0].stdClass} onChange={handleClassChange} label="Class">
            {classes.map((cls) => (
              <MenuItem key={cls} value={cls}>
                {cls}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!selectedYear || !formValues[0].stdClass}>
          {isSubmitting ? "Submit" : "Submit"}
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose} aria-labelledby="exam-dialog-title">
        <DialogTitle id="exam-dialog-title">Select Active {examTypes}</DialogTitle>
        <DialogContent>
          <DialogContentText>{messageResponse}</DialogContentText>

          {examNumbers.length > 0 ? (
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="exam"
                name="exam"
                value={selectedExamNumber || ""}
                onChange={handleExamSelection}
              >
                {examNumbers.map((exam) => (
                  <FormControlLabel key={exam} value={exam} control={<Radio />} label={`${examTypes.replace(/^./, char => char.toUpperCase())} ${exam}`} />
                ))}
              </RadioGroup>
            </FormControl>
          ) : (
            <DialogContentText>No exams available.</DialogContentText>
          )}
        </DialogContent>

        <DialogActions>
          <Box display="flex" justifyContent="flex-end" marginTop="20px" gap={2}>
          {/* <SecondaryOutlineBtn color="primary" onClick={handleClose}>
                    Cancel
                  </SecondaryOutlineBtn> */}
          <PrimaryBgBtn onClick={handleConfirm} disabled={!selectedExamNumber}>
            OK
          </PrimaryBgBtn>
          </Box>
        </DialogActions>
      </Dialog>
    </MainCard>
  );
};

export default CurrentTermSemester;
