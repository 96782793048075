import ReactECharts from "echarts-for-react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Padding } from "@mui/icons-material";

const AttendReport = ({ attendanceReport, echartsKey, attendanceLabel }) => {
  const seriesData = attendanceLabel?.map((label, index) => ({
    value: attendanceReport?.datasets[0].data[index],
    name: label,
  }));

  const mbBreakPoint = useMediaQuery("(min-width:600px)");

  let option;
  option = {
    title: {
      text: "Attendance Report",
      left: "center",
      align: "center",
      fontSize: 16,
      top: 0,
      textStyle: {
        fontWeight: "bolder",
        color: "rgba(0, 0, 0, 1)",
        fontFamily: "poppins",
      },
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: mbBreakPoint
      ? {
          orient: "vertical",
          right: 10,
          bottom: 35,
          data: attendanceLabel,
        }
      : {
          orient: "horizontal",
          left: "center",
          align: "center",
          bottom: 5,
          data: attendanceLabel,
        },
    toolbox: {
      show: true,
      orient: "horizontal",
      right: 48,
      top: mbBreakPoint ? "bottom" : 40,
      feature: {
        mark: { show: true },
        dataView: { show: false, readOnly: true },
        magicType: { show: false, type: ["bar", "stack"] },
        restore: { show: false },
        saveAsImage: { show: true },
      },
    },
    series: [
      {
        name: "Attendance",
        type: "pie",
        radius: mbBreakPoint ? "85%" : "65%",
        center: ["50%", "54%"],
        selectedMode: "single",
        avoidLabelOverlap: false,
        Padding: 20,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          position: "inner",
          margin: 20,
          fontSize: 10,
          fontWeight: "bold",
          show: true,
          formatter: "{b} {c}",
        },
        labelLine: {
          show: false,
        },
        color: ["#91cc75", "#ee6666", "#fac858", "#5470c6"],
        data: seriesData,
      },
    ],
  };
  return <ReactECharts key={echartsKey} option={option} />;
};

export default AttendReport;
