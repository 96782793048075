import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MainCard from "../../_Components/Common/Cards/MainCard";
import { useEffect, useState } from "react";
import axios from "axios";
import Scrollbar from "react-scrollbars-custom";

const DateSheet = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const nToken = localStorage.getItem("nToken");
  const classIDVal = JSON.parse(localStorage.getItem("classInfo"));
  const [upcomingEvent, setUpcomingEvent] = useState([]);
  const [dateSheet, setDateSheet] = useState([]);
  const [presentClasses, setPresentClasses] = useState([]);

  console.log(classIDVal.classid, "classID");
  useEffect(() => {
    const getUpcomingData = () => {
      const baseURL =
        userInfo[0]?.department.toLowerCase() === "student"
          ? ` ${process.env.REACT_APP_nodewsPrefix}/viewExamEvent?usertype=${userInfo[0]?.department}&schoolcode=${userInfo[0]?.institution}&classid=${classIDVal.classid}`
          : ` ${process.env.REACT_APP_nodewsPrefix}/viewExamEvent?usertype=${userInfo[0]?.department}&schoolcode=${userInfo[0]?.institution}`;
      axios

        .get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        })
        .then((response) => {
          console.log(response.data?.msg, "upcomingEventInfo");
          setUpcomingEvent(response.data?.msg);
        });
    };

    getUpcomingData();
  }, []);

  useEffect(() => {
    const groupEventPlanner = (upcomingEventData) => {
      const groupedData = {};

      upcomingEventData?.forEach((cert) => {
        const eventName = cert.name;
        const eventStart = cert.event_start;

        if (!groupedData[eventName]) {
          groupedData[eventName] = {};
        }

        if (!groupedData[eventName][eventStart]) {
          groupedData[eventName][eventStart] = [];
        }

        groupedData[eventName][eventStart].push(cert);
      });

      return groupedData;
    };

    if (upcomingEvent) {
      const upcomingEventInfo = groupEventPlanner(upcomingEvent);
      setDateSheet(upcomingEventInfo);
    }
  }, [upcomingEvent]);

  useEffect(() => {
    const getClassNames = (data) => {
      const classSet = new Set();
      Object.values(data).forEach((eventsByDate) => {
        Object.values(eventsByDate).forEach((events) => {
          if (Array.isArray(events)) {
            events.forEach((event) => classSet.add(event.class_name));
          }
        });
      });
      return [...classSet];
    };

    if (dateSheet && Object.keys(dateSheet).length > 0) {
      const presentClassesData = getClassNames(dateSheet);
      const sortedClasses = presentClassesData.sort((a, b) => {
        const [prefixA, suffixA] = a.split(".");
        const [prefixB, suffixB] = b.split(".");
        if (prefixA === prefixB) {
          return suffixA.localeCompare(suffixB);
        }
        return prefixA.localeCompare(prefixB);
      });

      console.log(sortedClasses, "presentClassesData");
      setPresentClasses(presentClassesData);
    }
  }, [dateSheet]);

  const getValueAfterLastDot = (str) => {
    const parts = str.split(".");
    return parts[parts.length - 1];
  };

  return (
    <MainCard cardTitle={"Date Sheet"} mainPage>
      <Scrollbar
        style={{ width: "100%", height: 671 }}
        className="customCardItem"
      >
        {dateSheet.length > 0 ? (
          <>
            {Object.keys(dateSheet).map((examName, index) => (
              <TableContainer component={Paper} key={index} sx={{ mb: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="Datesheet">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        colspan={presentClasses.length + 1}
                        sx={{ background: "#deeaff" }}
                      >
                        {examName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ width: "200px", minWidth: "150px" }}>
                        Date
                      </TableCell>
                      {presentClasses.map((className) => (
                        <TableCell
                          key={className}
                          sx={{ width: "150px", minWidth: "100px" }}
                        >
                          {getValueAfterLastDot(className).toUpperCase()}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(dateSheet[examName]).map((eventDate) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        key={eventDate}
                      >
                        <TableCell component="th" scope="row">
                          {eventDate}
                        </TableCell>
                        {presentClasses.map((classNameVal) => {
                          const event = dateSheet[examName][eventDate].find(
                            (e) => e.class_name === classNameVal
                          );
                          return (
                            <TableCell key={classNameVal}>
                              {event ? event.subject_name : "-"}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ))}
          </>
        ) : (
          <Typography
            variant="h6"
            mt={1}
            className="mssgCard"
            textAlign={"center"}
          >
            Date Sheet not assigned for now
          </Typography>
        )}
      </Scrollbar>
    </MainCard>
  );
};

export default DateSheet;
