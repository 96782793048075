import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import MainCard from "../../_Components/Common/Cards/MainCard";
import DeveloperPermission from "./DeveloperPermission";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";

export default function Setting() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { permissions } = usePermissions();

  return (
    <MainCard cardTitle={"Setting"}>
      <Grid container spacing={2}>
        {userInfo[0]?.department.toLowerCase() === "developer" ? (
          <DeveloperPermission />
        ) : (
          <>
            <Grid item>
              <Link
                rel="preload"
                to={{ pathname: "/permission" }}
                className="btn linkBtn primaryBtn"
                variant="contained"
                style={{ textDecoration: "none" }}
              >
                Permission For School
              </Link>
            </Grid>
            {/* <Grid item>
                <Link
                  to={{ pathname: "/assignrole" }}
                  className="btn linkBtn primaryBtn"
                  variant="contained"
                  style={{ textDecoration: "none" }}
                >
                  Assign Role
                </Link>
              </Grid> */}
            <Grid item>
              <Link
                to={{ pathname: "/attendanceFormat" }}
                className="btn linkBtn primaryBtn"
                variant="contained"
                style={{ textDecoration: "none" }}
              >
                Attendance Format
              </Link>
            </Grid>
            <Grid item>
              <Link
                to={{ pathname: "/subjectpermissions" }}
                className="btn linkBtn primaryBtn"
                variant="contained"
                style={{ textDecoration: "none" }}
              >
                Subject Permission
              </Link>
            </Grid>
            <Grid item>
              <Link
                to={{ pathname: "/assignTimeTable" }}
                className="btn linkBtn primaryBtn"
                variant="contained"
                style={{ textDecoration: "none" }}
              >
                Create Timetable
              </Link>
            </Grid>

            <Grid item>
              <Link
                to={{ pathname: "/Fee-Structure" }}
                className="btn linkBtn primaryBtn"
                variant="contained"
                style={{ textDecoration: "none" }}
              >
                Create Fee Structure
              </Link>
            </Grid>
            {permissions.reportTemplate?.write &&
              permissions.reportTemplate?.read && (
                <>
                <Grid item>
                  <Link
                    to={{ pathname: "/adminReport" }}
                    className="btn linkBtn primaryBtn"
                    variant="contained"
                    style={{ textDecoration: "none" }}
                  >
                    Progress Report Format
                  </Link>
                </Grid>
                

                <Grid item>
                <Link
                  to={{ pathname: "/currentTerm&Semester" }}
                  className="btn linkBtn primaryBtn"
                  variant="contained"
                  style={{ textDecoration: "none" }}
                >
                  Change Current Term & Semester
                </Link>
              </Grid>
              </>
              )}


            <Grid item>
              <Link
                to={{ pathname: "/uploadFiles" }}
                className="btn linkBtn primaryBtn"
                variant="contained"
                style={{ textDecoration: "none" }}
              >
                Others
              </Link>
            </Grid>
          </>
        )}
      </Grid>
    </MainCard>
  );
}
