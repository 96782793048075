import { useEffect, useState } from "react";
import MainCard from "../../../_Components/Common/Cards/MainCard";
import axios from "axios";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import {
  CenterButtonBoxArea,
  PrimaryBgBtn,
} from "../../../_Components/Common/Buttons/CustomBtns";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import ColorPicker from "../../../_Components/Utils/ColorPicker";
import "./AttendanceFormat.scss"

const AttendanceFormat = () => {
  const [initialInfo, setInitialInfo] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const nToken = localStorage.getItem("nToken")
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [sectionColors, setSectionColors] = useState({});

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/getConfigByDept`,
        {
          schoolcode: userinfo[0]?.institution,
          department: userinfo[0]?.department,
        }
      );
      const defaultAttendance = response.data.defaultConfig.attendance.marking;
      const schoolAttendance = response.data.config.attendance.marking;

      const attendanceData =
        Object.keys(schoolAttendance).length > 0 ? schoolAttendance : defaultAttendance;

      setInitialInfo(attendanceData);
      setSelectedValues(
        Object.fromEntries(Object.entries(attendanceData).map(([key, value]) => [key, value]))
      );

    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const attendanceLabelVal = Object.entries(initialInfo).map(([key, value]) => ({
    key: key,
    value: value
  }));

  const addCustomSection = () => {
    setSections([...sections, { isCustomChecked: false, customLabel: "" }]);
  };

  const removeCustomSection = (index) => {
    const updatedSections = sections.filter((_, i) => i !== index);
    setSections(updatedSections);
  };

  useEffect(() => {
    const hasEmptyCustomLabel = sections?.some(
      (section) => section.isCustomChecked && !section.customLabel.trim()
    );
    setIsSaveDisabled(hasEmptyCustomLabel);
  }, [sections]);



  const handleCheckboxChange = (key, value) => (event) => {
    setSelectedValues((prev) => {
      const updatedValues = { ...prev };
      if (event.target.checked) {
        updatedValues[key] = value;
      } else {
        delete updatedValues[key];
      }
      return updatedValues;
    });
  };

  const handleColorChange = (label, color) => {
    setSectionColors((prev) => ({
      ...prev,
      [label]: color,
    }));
  };

  const handleLabelChange = (index) => (event) => {
    const updatedSections = sections.map((section, i) =>
      i === index
        ? { ...section, customLabel: event.target.value, isCustomChecked: true }
        : section
    );
    setSections(updatedSections);
  };


  const handleSaveFormat = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    const invalidCustomLabel = sections?.some(
      (section) => section.isCustomChecked && !section.customLabel.trim()
    );

    if (invalidCustomLabel) {
      setError("Please provide valid custom labels for all checked sections.");
      setIsLoading(false);
      return;
    }

    const updateDataVal = {
      attendance: {
        marking: {
          ...selectedValues,
          ...sections.reduce((acc, section, index) => {
            if (section.isCustomChecked && section.customLabel.trim()) {
              acc[section.customLabel] = sectionColors[index];
            }
            return acc;
          }, {}),
        },
      },
    };

    const datas = JSON.stringify(updateDataVal);

    try {
      await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/updateConfigData`,
        {
          config: datas,
          schoolcode: userinfo[0]?.institution,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      await fetchData();
      alert("Updated Successfully");
      setSections([])
    } catch (error) {
      console.error("Error updating permissions:", error);
      setError("Failed to update the attendance format. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <MainCard cardTitle="Attendance Format">
      <form onSubmit={handleSaveFormat}>
        <Box display={"flex"} alignItems={"center"} gap={5}>
          {attendanceLabelVal.map((data) => (
            <Box display={"flex"} alignItems={"center"} key={data.key}>
              <FormControlLabel
                sx={{ marginRight: "5px" }}
                control={
                  <Checkbox
                    name={data.key}
                    checked={!!selectedValues[data.key]}
                    onChange={handleCheckboxChange(data.key, data.value)}
                  />
                }
                label={data.key}
              />
              {selectedValues[data.key] && (
                <ColorPicker
                  func={(color) => handleColorChange(data.key, color)}
                  cColor={(setColor) => setColor(selectedValues[data.key])}
                />
              )}
            </Box>
          ))}
        </Box>

        <Grid container mt={2}>
          {sections?.map((section, index) => (
            <Grid item xs={12} md={3} key={`${section}-${index}`} spacing={2} sx={{ marginBottom: "15px" }}>
              <Box display={"flex"} alignItems={"center"}>
                <TextField
                  onChange={handleLabelChange(index)}
                  value={section.customLabel}
                  id="outlined-basic"
                  label={`Custom Label ${index + 1}`}
                  variant="outlined"
                  error={section.isCustomChecked && !section.customLabel.trim()}
                  helperText={
                    section.isCustomChecked && !section.customLabel.trim()
                      ? "Label is required"
                      : ""
                  }
                />
                <Box className="colorPicker">
                  <ColorPicker
                    func={(color) => handleColorChange(index, color)}
                    cColor={(setColor) => setColor(sectionColors[index])}
                  />
                </Box>

                <IconButton
                  className="iconBtns"
                  aria-label="Remove row"
                  onClick={() => removeCustomSection(index)}
                  sx={{ ml: 1 }}
                >
                  <AiOutlineMinusCircle fontSize={26} />
                </IconButton>
              </Box>
            </Grid>
          ))}


        </Grid>
        <IconButton
          className="iconBtns"
          aria-label="Add custom attendance format label"
          onClick={addCustomSection}
        >
          <AiOutlinePlusCircle fontSize={26} color="primary" />
        </IconButton>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <CenterButtonBoxArea mt={2}>
          <PrimaryBgBtn type="submit" disabled={isLoading || isSaveDisabled}>
            {isLoading ? "Saving..." : "Submit"}
          </PrimaryBgBtn>
        </CenterButtonBoxArea>
      </form>
    </MainCard>
  );
};

export default AttendanceFormat;

