import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  CenterButtonBoxArea,
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../../_Components/Common/Buttons/CustomBtns";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchClassSection } from "../../../redux/apiSlice/classSectionSlice";
import CustomDialog from "../../../_Components/Common/CustomDialog/CustomDialog";
import {
  IoIosAddCircleOutline,
  IoIosRemoveCircleOutline,
} from "react-icons/io";
import { montArr } from "../../../_Components/Common/MonthArray";
import ViewFeeStructure from "./ViewFeeStructure";
import CircularLoader from "../../../_Components/Common/Loader/CircularLoader";

const FeeStructureForm = (editData) => {
  const nToken = localStorage.getItem("nToken");
  const financialYear = localStorage.getItem("AY");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [classData, setClassData] = useState([]);
  const [customAlert, setCustomAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customDialogData, setCustomDialogData] = useState({
    titleHead: "",
    message: "",
    contentData: "",
  });
  const [feeStructures, setFeeStructures] = useState([
    { id: "", feeTypeLabel: "", feeTypeValue: "" },
  ]);
  const [formData, setFormData] = useState({
    getMonth: "",
    selectedMonths: [],
    discount: "",
    discountType: "",
    classSec: "",
  });

  const dispatch = useDispatch();
  const { classSectionData, classSectionLoading } = useSelector(
    (data) => data.classSectionInfo
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleTypeChange = (e, rowId) => {
    const { name, value } = e.target;
    console.log(rowId, "rowId")
    setFeeStructures((prev) =>
      prev.map((item) =>
        item.id === rowId ? { ...item, [name]: value } : item
      )
    );
  };


  const addFeetype = () => {
    setFeeStructures((prev) => [
      ...prev,
      { id: Date.now(), feeTypeLabel: "", feeTypeValue: "" },
    ]);
  };



  const removeFeetype = (rowId) => {
    setFeeStructures((prev) => prev.filter((item) => item.id !== rowId));
  };


  const handleAlertClose = () => {
    setCustomAlert(false);
    setFeeStructures([{ feeTypeLabel: "", feeTypeValue: "" }]);
  };

  useEffect(() => {
    if (!classSectionLoading) {
      dispatch(
        fetchClassSection({
          userinfo,
          nToken,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!classSectionLoading && classSectionData?.length > 0) {
      setClassData(classSectionData);
    }
  }, [classSectionLoading, classSectionData]);

  const cleanFeeStructures = Array.isArray(feeStructures)
    ? feeStructures.map(({ feeTypeLabel, feeTypeValue }) => ({
      feeTypeLabel,
      feeTypeValue,
    }))
    : [];

  const feeTypePayload = cleanFeeStructures?.reduce(
    (acc, { feeTypeLabel, feeTypeValue }) => {
      acc[feeTypeLabel] = feeTypeValue;
      return acc;
    },
    {}
  );

  const feeTypePayloadVal = formData.selectedMonths.reduce((acc, month) => {
    acc[month] = { ...feeTypePayload };
    return acc;
  }, {});

  const handleFormData = async (event, val) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/classFeeStructureAdd`,
        {
          overwrite: val,
          schoolcode: userinfo[0].institution,
          academic_year: financialYear,
          class_name: formData.classSec,
          added_by: userinfo[0].id,
          fee_type: feeTypePayloadVal,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      const successMessage = response?.data?.message;
      setCustomDialogData({
        titleHead: response?.data?.error ? "Failed" : "Success",
        message: successMessage,
        contentData: response.data,
      });
      setCustomAlert(true);
      setSuccessMessage(successMessage)
    } catch (error) {
      console.error("Error adding fee structure:", error);
    }
  };

  const isFormEmpty =
    !formData.getMonth && !formData.discount && !formData.classSec;

  const handleEditedData = (data, dataIndex, fullMonthData, loaderInfo) => {
    setTimeout(() => {
      const filteredData = data.fee_type[dataIndex];

      setFormData({
        classSec: data.class,
        selectedMonths: Array.isArray(dataIndex) ? dataIndex : [dataIndex],
      });
      setFeeStructures(
        Object.entries(filteredData).map(([feeType, amount]) => ({
          id: `${Date.now()}-${Math.random()}`,
          feeTypeLabel: feeType,
          feeTypeValue: amount,
        }))
      );
      setEditing(true);
      setLoading(false);
    }, 0);
  };

  const onRefresh = () => {
    setFormData({
      getMonth: "",
      selectedMonths: [],
      discount: "",
      discountType: "",
      classSec: "",
    })
    setFeeStructures([{id: "", feeTypeLabel: "", feeTypeValue: "" }]);
  }

  return (
    <>
      <Box component={"form"} onSubmit={(e) => handleFormData(e, 0)}>
        {loading && <Box className="loadingFrame">
          <CircularLoader />
        </Box>}
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel id="classSecLabel">Class</InputLabel>
              <Select
                labelId="classSecLabel"
                id="classSec"
                value={formData.classSec}
                label="Class"
                name="classSec"
                onChange={handleChange}
                disabled={editing}
              >
                {Array.from(
                  new Set(
                    classData.map((obj) => obj.class).sort((a, b) => a - b)
                  )
                ).map((data) => (
                  <MenuItem key={data} value={data}>
                    Class {data}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel id="Months-label">Months</InputLabel>
              <Select
                labelId="Months-label"
                id="Months"
                multiple
                value={formData.selectedMonths || []}
                onChange={(event) => {
                  const { value } = event.target;
                  let updatedMonths = value;

                  setFormData((prev) => ({
                    ...prev,
                    selectedMonths: updatedMonths,
                  }));
                }}
                name="selectedMonths"
                label="Months"
                renderValue={(selected) =>
                  selected.map((index) => montArr[index - 1]).join(", ")
                }
                disabled={editing}
              >
                {montArr.map((data, index) => (
                  <MenuItem key={index} value={index + 1}>
                    <Checkbox
                      checked={formData.selectedMonths?.includes(index + 1)}
                    />
                    <ListItemText primary={data} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {feeStructures.map((feeStructure, index) => (
              <Grid container spacing={2} key={feeStructure.id} alignItems="center">
                <Grid item xs={4} md={4} mb={2}>
                  <FormControl fullWidth>
                    <TextField
                      id={`feeTypeLabel-${feeStructure.id}`}
                      label="Type"
                      variant="outlined"
                      name="feeTypeLabel"
                      type="text"
                      value={feeStructure.feeTypeLabel || ""}
                      onChange={(e) => handleTypeChange(e, feeStructure.id)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4} md={4} mb={2}>
                  <FormControl fullWidth>
                    <TextField
                      id={`feeTypeValue-${feeStructure.id}`}
                      label="Amount"
                      variant="outlined"
                      name="feeTypeValue"
                      type="number"
                      value={feeStructure.feeTypeValue || ""}
                      onChange={(e) => handleTypeChange(e, feeStructure.id)}
                    />
                  </FormControl>
                </Grid>

                <Grid md={2}>
                  <Box display="flex" justifyContent="flex-start" gap={2}>
                    {feeStructures.length > 1 && (
                      <IconButton onClick={() => removeFeetype(feeStructure.id)}>
                        <IoIosRemoveCircleOutline />
                      </IconButton>
                    )}
                    {index === feeStructures.length - 1 && (
                      <IconButton onClick={addFeetype}>
                        <IoIosAddCircleOutline />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              </Grid>
            ))}

          </Grid>
        </Grid>

        <CenterButtonBoxArea mt={2}>
          <PrimaryOutlineBtn onClick={onRefresh} disabled={isFormEmpty}>Reset</PrimaryOutlineBtn>
          <PrimaryBgBtn type="submit" disabled={isFormEmpty}>
            Submit
          </PrimaryBgBtn>
        </CenterButtonBoxArea>
        <CustomDialog
          isOpen={customAlert}
          cardTitle={customDialogData.titleHead}
          onClose={handleAlertClose}
          actionBtn={
            <>
              {customDialogData?.contentData.error === 1 && (
                <>
                  <PrimaryOutlineBtn onClick={handleAlertClose}>
                    Cancel
                  </PrimaryOutlineBtn>
                  <PrimaryBgBtn onClick={(e) => handleFormData(e, 1)}>
                    Save
                  </PrimaryBgBtn>
                </>
              )}{" "}
            </>
          }
        >
          <Box p={2} textAlign={"center"}>
            {customDialogData.message}
            {customDialogData?.contentData && (
              <>
                {customDialogData?.contentData?.fee_typeData && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Month</TableCell>
                        <TableCell colSpan={2}>Fee Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(
                        customDialogData.contentData.fee_typeData
                      ).map(([monthNumber, feeDetails]) => (
                        <TableRow key={monthNumber}>
                          <TableCell>
                            {montArr[parseInt(monthNumber, 10) - 1]}
                          </TableCell>
                          <TableCell>Tution: {feeDetails.Tution} Rs</TableCell>
                          <TableCell>
                            Development: {feeDetails.Development} Rs
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </>
            )}
          </Box>
        </CustomDialog>
      </Box>
      <ViewFeeStructure onEditData={handleEditedData} successMessage={successMessage} setLoading={setLoading} />
    </>
  );
};

export default FeeStructureForm;
