import React from 'react'
import { usePermissions } from '../../../_Components/Permission/PermissionsContext'
import ReportProfile from './ReportProfile';
import TeacherCard from './TeacherCard';
import { Box } from '@mui/material';

function Report() {
  const { permissions } = usePermissions();
  return (
    <Box>
      {permissions?.report?.read && permissions?.report?.write && <Box>
        <TeacherCard />
      </Box>
      }
      {permissions?.report?.read && !permissions?.report?.write && <Box>
        <ReportProfile />
      </Box>
      }
    </Box>


  )
}

export default Report