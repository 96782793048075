import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { PrimaryBgBtn, PrimaryOutlineBtn } from "../Buttons/CustomBtns";
import ProcessText from "../ProceessText";

const PrincipalFilter = ({
  forDepartment,
  forClass,
  forSection,
  forSubject,
  forStudent,
  onselectionchangeData,
}) => {
  const [classData, setClassData] = useState([]);
  const [subject, setSubject] = useState([]);
  const [studentInfo, setStudentInfo] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const token = localStorage.getItem("token");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const nToken = localStorage.getItem("nToken");

  useEffect(() => {
    const baseURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection?schoolcode=${userinfo[0]?.institution}`;
    axios
      .get(baseURL, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      })
      .then((response) => {
        setClassData(response.data.class_section);
      });

    const subjectURL = `${process.env.REACT_APP_wsPrefix}webservice/rest/server.php?wsfunction=core_course_get_courses_info&moodlewsrestformat=json&wstoken=${token}`;
    axios.get(subjectURL).then((response) => {
      setSubject(response.data);
    });
  }, []);

  const handleSelectionChange = async (event, selectionType) => {
    const value = event.target.value;
    if (selectionType === "class") {
      setSelectedClass(value);
      // onselectionchangeData(
      //   value,
      //   selectedSection,
      //   selectedSubject,
      //   selectedDepartment,
      //   selectedStudent
      // );
    } else if (selectionType === "section") {
      setSelectedSection(value);
      // onselectionchangeData(
      //   selectedClass,
      //   value,
      //   selectedSubject,
      //   selectedDepartment,
      //   selectedStudent
      // );
    } else if (selectionType === "subject") {
      setSelectedSubject(value);
      // onselectionchangeData(
      //   selectedClass,
      //   value,
      //   selectedSubject,
      //   selectedDepartment,
      //   selectedStudent
      // );
      await fetchData(selectedClass, selectedSection, value);
    } else if (selectionType === "department") {
      setSelectedDepartment(value);
      // onselectionchangeData(
      //   selectedClass,
      //   selectedSection,
      //   selectedSubject,
      //   value,
      //   selectedStudent
      // );
    } else if (selectionType === "student") {
      setSelectedStudent(value);
      // onselectionchangeData(
      //   selectedClass,
      //   selectedSection,
      //   selectedSubject,
      //   selectedDepartment,
      //   value
      // );
    }
  };

  const filterSubjects = (classSelected) => {
    const courses = subject.filter(
      (data) => ProcessText(data.displayname).number === classSelected
    );
    return courses;
  };

  const handleFilterData = () => {
    onselectionchangeData(
      selectedClass,
      selectedSection,
      selectedSubject,
      selectedDepartment,
      selectedStudent
    );
  };

  const handleRefreshData = () => {
    setSelectedClass("");
    setSelectedSection("");
    setSelectedSubject("");
    setSelectedDepartment("");
    setSelectedStudent("");
    onselectionchangeData("");
  };

  const fetchData = async (
    selectedClassValue,
    selectedSectionValue,
    selectedSubjectValue
  ) => {
    try {
      const enroledURL = `${
        process.env.REACT_APP_wsPrefix
      }/webservice/rest/server.php?wstoken=${token}&wsfunction=core_enrol_get_enrolled_users_info&moodlewsrestformat=json&courseid=${selectedSubjectValue}&schoolcode=${Number(
        userinfo[0]?.institution
      )}&class=${selectedClassValue}&section=${selectedSectionValue}`;
      const response2 = await axios.get(enroledURL);
      const studentData = response2.data.filter(
        (val) =>
          val.department?.toLowerCase() === "student" &&
          val.section === selectedSectionValue &&
          val.class == selectedClassValue &&
          val.institution === userinfo[0].institution
      );
      setStudentInfo(studentData);
    } catch (error) {
      console.error("Error fetching Student Info", error);
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {forDepartment && (
          <Grid item xs={6} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="department-select-department">
                Department
              </InputLabel>
              <Select
                labelid="department-select-department"
                id="department-select"
                value={selectedDepartment}
                input={<OutlinedInput label="Department" />}
                onChange={(event) => handleSelectionChange(event, "department")}
              >
                <MenuItem value="Pedagogy">Teacher</MenuItem>
                <MenuItem value="student">Student</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {selectedDepartment === "student" && (
          <>
            {forClass && (
              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="class-select-label">Class</InputLabel>
                  <Select
                    labelid="class-select-label"
                    id="class-select"
                    value={selectedClass}
                    input={<OutlinedInput label="Class" />}
                    onChange={(event) => handleSelectionChange(event, "class")}
                  >
                    {classData?.length > 0 &&
                      Array.from(
                        new Set(
                          classData
                            .map((obj) => obj.class)
                            .sort((a, b) => a - b)
                        )
                      ).map((data, index) => (
                        <MenuItem key={index} value={data}>
                          Class {data}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {forSection && (
              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="section-select-label">Section</InputLabel>
                  <Select
                    labelid="section-select-label"
                    id="section-select"
                    value={selectedSection}
                    input={<OutlinedInput label="Section" />}
                    onChange={(event) =>
                      handleSelectionChange(event, "section")
                    }
                  >
                    {classData.length > 0 &&
                      Array.from(
                        new Set(
                          classData
                            .map((obj) => obj.section)
                            .sort((a, b) => a - b)
                        )
                      ).map((data, index) => (
                        <MenuItem key={index} value={data}>
                          Section {data}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {forSubject && (
              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="subject-select-label">Subject</InputLabel>
                  <Select
                    labelid="subject-select-label"
                    id="subject-select"
                    value={selectedSubject}
                    input={<OutlinedInput label="Section" />}
                    onChange={(event) =>
                      handleSelectionChange(event, "subject")
                    }
                  >
                    {filterSubjects(selectedClass).map((data) => (
                      <MenuItem key={data.id} value={data.id}>
                        {ProcessText(data.displayname).text}
                        {data.fullname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {forStudent && (
              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="section-select-label">Student</InputLabel>
                  <Select
                    labelid="section-select-label"
                    id="section-select"
                    value={selectedStudent}
                    input={<OutlinedInput label="Student" />}
                    onChange={(event) =>
                      handleSelectionChange(event, "student")
                    }
                  >
                    {studentInfo.map((name) => (
                      <MenuItem key={name.id} value={name.fullname}>
                        {name.fullname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Box mt={3} display={"flex"} justifyContent="center" gap={2}>
        <PrimaryOutlineBtn
          onClick={handleRefreshData}
          disabled={!selectedDepartment}
        >
          Reset
        </PrimaryOutlineBtn>
        <PrimaryBgBtn type="submit" onClick={handleFilterData}>
          Submit
        </PrimaryBgBtn>
      </Box>
    </Box>
  );
};

export default PrincipalFilter;
