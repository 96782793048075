import { Box, Button, Card, List, ListItem, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { languages } from "../../App";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  CenterButtonBoxArea,
  PrimaryBgBtn,
} from "../../_Components/Common/Buttons/CustomBtns";

function QuizSummaryList(props) {
  const token = localStorage.getItem("token");
  let curLanguage = useContext(languages);
  const attemptSummaryURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_get_attempt_summary&moodlewsrestformat=json&attemptid=${props.attId}`;

  const [attemptSummary, SetattemptSummary] = useState();

  useEffect(() => {
    console.log("inder111 useeffect")
    axios.get(attemptSummaryURL).then((response) => {
      console.log("inde111 attemptResponse", response.data);
      console.log("inde111 attemptSummaryURL", attemptSummaryURL);
      SetattemptSummary(response.data);
    });

    const interval = setTimeout(() => {
      axios.get(attemptSummaryURL).then((response) => {
        console.log("inde111 attemptResponse", response.data);
        console.log("inde111 attemptSummaryURL", attemptSummaryURL);
        SetattemptSummary(response.data);
      });
    }, 500); // Poll every 3 seconds
  }, []);

  return (
    <>
      <List className="attemptUl">
        {attemptSummary?.questions.map((values) => (
          <ListItem key={values.slot} className="attemptLi">
            <NavLink
              to={`/assessmentAttempt?id=${props.attId}&pageNo=${values.slot}`}
              state={{
                page: values.page,
                attemptid: props.attId,
                quizSummarycheck: "yes",
              }}
            >
              <Typography>{values.slot}</Typography>
              <Typography>{values.status}</Typography>
            </NavLink>
          </ListItem>
        ))}
      </List>

      <CenterButtonBoxArea>
        <PrimaryBgBtn onClick={props.sumbitHandler}>
          {curLanguage.submit}
        </PrimaryBgBtn>
      </CenterButtonBoxArea>
    </>
  );
}

export default QuizSummaryList;
