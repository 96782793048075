import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  StyledInput,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { languages } from "../../App";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PushPinIcon from "@mui/icons-material/PushPin";
import Quiznavigation from "./Quiznavigation";
import { useNavigate } from "react-router";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import "./QuizQuestions.scss";

let answerAttempt = [];
let multichoiseVal;
let previousAttempt;
let isAttempted;

let pno = 0;
const QuizQuestions = ({ qdata, isLoading, handleChildAction }) => {
  const checkPreviousAttempt = () => {
    previousAttempt = "";
    console.log("checking.... answers", qdata);
    if (qdata.questionType === "multichoice") {
      console.log(qdata.answer, "qdata answer");

      let attemptedAnswerObj = qdata.answer.findIndex(
        (data) => data.checked === true
      );

      console.log(attemptedAnswerObj, "qdata answer1");
      previousAttempt = attemptedAnswerObj;
      console.log("checking.... previousAtt", previousAttempt);
    } else if (qdata.questionType === "multianswer") {
      previousAttempt[qdata.pageNo] = qdata.answerAttempt;
    } else if (qdata.questionType === "truefalse") {
      console.log(qdata, "I am true and false");
    }

    console.log("checking.... previous att", previousAttempt);
  };

  checkPreviousAttempt();

  console.log(qdata.answer, "ind qqq");
  const [SearchParams] = useSearchParams();
  const [pageNo, setpageNo] = useState(0);

  const attemptId = SearchParams.get("id");
  const quizID = SearchParams.get("quizId");
  const token = localStorage.getItem("token");

  const nextQuestion = () => {
    handleChildAction(pno++);
  };

  const previousQuestion = () => {
    handleChildAction(pno--);
  };

  /*  let quesHTMLDataURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_get_attempt_data_info&moodlewsrestformat=json&attemptid=${attemptId}&page=${0}`;

  const getQuestionData = async (url) => {
    console.log("INDERv1 qq gqD", qdata.pageNo)
    axios.get(url).then((response) => {
      console.log("INDERv1 qq1", response.data)
      console.log("INDERv1 qq", JSON.parse(response.data.questions[qdata.pageNo].html).answers_data)
    }).catch((error) => {
      console.log(error.message, "Error1")
    });
  }

  useEffect(() => {
    console.log("INDERv1 qq", quesHTMLDataURL)
    getQuestionData(quesHTMLDataURL);
  }, [quesHTMLDataURL]); */

  const [showTimings, setShowTimings] = useState();

  let intervalId;

  const [isThereError, setIsThereError] = useState({
    error: false,
    message: "",
  });
  console.log("inder qdata", attemptId, quizID);

  useEffect(() => {
    if (qdata.hasOwnProperty("error")) {
      setIsThereError({ error: true, message: qdata.error });
    } else {
      setIsThereError({ error: false, message: "" });
    }
  }, [qdata]);

  const attemptSummaryURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_get_attempt_summary&moodlewsrestformat=json&attemptid=${qdata.assesmentId}`;

  console.log("inder attemptSummaryURL", attemptSummaryURL);
  const [allAttemptedData, setAllAttemtptedData] = useState([]);
  const [allFlaged, setAllFlaged] = useState([]);

  const [flagChecked, setflagChecked] = useState();
  const navigation = useNavigate();

  let curLanguage = useContext(languages);

  const handlemultichoiceChange = (event) => {
    console.log(event.target.value, "xdxdxd 1");

    multichoiseVal = event.target.value;
  };
  /* 'noOfQuestion','questionType', 'questionText', 'answer', 'attempt','Answer saved', "qid"*/

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();
    str = str.replace(/(<([^>]+)>)/gi, "");
    str = str.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, " ");
    return str;
  }

  function createHTML(str) {
    if (str.includes("img")) {
      return <div dangerouslySetInnerHTML={{ __html: str }} />;
    } else {
      return removeTags(str);
    }
  }

  if (qdata.attempt === "Answer saved") {
    console.log("Answer saved!!!");
    isAttempted = true;
    checkPreviousAttempt();
  } else {
    isAttempted = false;
  }

  console.log(isAttempted, "is attempt. . .");

  const inputChangeHandler = () => {
    console.log("inputchangehandler");
  };

  function addInput(str) {
    let filterStr = removeTags(str);

    if (filterStr.includes("{#1}")) {
      filterStr = filterStr.replace(
        "{#1}",
        `<br><input  class="quizQuestInput" type="text" id="in1" value=${
          isAttempted ? previousAttempt[qdata.pageNo][0] : ""
        }>`
      );
    }
    if (filterStr.includes("{#2}")) {
      filterStr = filterStr.replace(
        "{#2}",
        `<input  class="quizQuestInput" type="text" id="in2" value=${
          isAttempted ? previousAttempt[qdata.pageNo][1] : ""
        }>`
      );
    }
    if (filterStr.includes("{#3}")) {
      filterStr = filterStr.replace(
        "{#3}",
        `<input  class="quizQuestInput" type="text" id="in3" value=${
          isAttempted ? previousAttempt[qdata.pageNo][2] : ""
        }>`
      );
    }
    if (filterStr.includes("{#4}")) {
      filterStr = filterStr.replace(
        "{#4}",
        `<input  class="quizQuestInput" type="text" id="in4" value=${
          isAttempted ? previousAttempt[qdata.pageNo][3] : ""
        }>`
      );
    }
    if (filterStr.includes("{#5}")) {
      filterStr = filterStr.replace(
        "{#5}",
        `<input class="quizQuestInput" type="text" id="in5" value=${
          isAttempted ? previousAttempt[qdata.pageNo][4] : ""
        }>`
      );
    }
    return <div dangerouslySetInnerHTML={{ __html: filterStr }} />;
  }

  const getAnswerValue = () => {
    if (
      qdata.questionType === "multichoice" ||
      qdata.questionType === "truefalse"
    ) {
      if (multichoiseVal) {
        answerAttempt[qdata.pageNo] = [];
        answerAttempt[qdata.pageNo].push(multichoiseVal);
        multichoiseVal = undefined;
      }
    } else if (qdata.questionType === "multianswer") {
      if (document.querySelector("#in1")?.value) {
        answerAttempt[qdata.pageNo] = [];
        if (document.querySelector("#in1")) {
          answerAttempt[qdata.pageNo].push(
            document.querySelector("#in1")?.value
          );
        }
        if (document.querySelector("#in2")) {
          answerAttempt[qdata.pageNo].push(
            document.querySelector("#in2")?.value
          );
        }
        if (document.querySelector("#in3")) {
          answerAttempt[qdata.pageNo].push(
            document.querySelector("#in3")?.value
          );
        }
        if (document.querySelector("#in4")) {
          answerAttempt[qdata.pageNo].push(
            document.querySelector("#in4")?.value
          );
        }
        if (document.querySelector("#in5")) {
          answerAttempt[qdata.pageNo].push(
            document.querySelector("#in5")?.value
          );
        }
      }
    }
  };

  const [attSaved, setAttSaved] = useState(0);
  console.log(qdata, "IND check: qno");
  let questionNo = qdata.qNo;
  let incrementBy = 5;
  let a1 = 0,
    a2 = 1,
    a3 = 2,
    a4 = 3,
    a5 = 4;

  if (questionNo !== 0) {
    let increment = questionNo * 6;
    a1 = increment;
    a2 = a1 + 1;
    a3 = a2 + 1;
    a4 = a3 + 1;
    a5 = a4 + 1;
  }

  console.log(a1, a2, a3, a4, a5, "IND check: array");
  let getFlagValue = qdata.formElements[a3].name.split(":").slice(0, -1);

  console.log(`IND check: name1 ${getFlagValue}`);
  console.log(`IND check: name2 ${getFlagValue[0]}:${getFlagValue[1]}:flagged`);

  let firstVal = `${getFlagValue[0]}:${getFlagValue[1]}:flagged`;

  const saveAttempt = (type) => {
    console.log(type, "ind111 type");
    console.log("ind111 multichoiseVal answer selected by user", multichoiseVal);
    console.log("ind111 previous attempt", previousAttempt);
    console.log("ind111 question No", qdata.qNo);
    console.log("ind111 answer", qdata.answer);

    if (multichoiseVal === undefined) {
      console.log("checking.... multichoise undefined.... its undefined");
      if (previousAttempt !== -1) {
        multichoiseVal = previousAttempt;
      }
    }

    let linkcreated;

    if (type === "multichoice" || type === "truefalse") {
      let setAnswer;
      
      if (type === "multichoice") {
        linkcreated = `${
          process.env.REACT_APP_wsPrefix
        }/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_save_attempt&moodlewsrestformat=json&attemptid=${
          qdata.assesmentId
        }&data[0][name]=${firstVal}&data[0][value]=${flagChecked}&data[1][name]=${
          qdata.formElements[a3].name
        }&data[1][value]=${1}&data[2][name]=${
          qdata.formElements[a5].name
        }&data[2][value]=${multichoiseVal}`;
      }

      if (type === "truefalse") {
        if (parseInt(multichoiseVal) === 1) {
          setAnswer = 0;
        } else {
          setAnswer = 1;
        }

        console.log("SETANSWER", setAnswer);

        linkcreated = `${
          process.env.REACT_APP_wsPrefix
        }/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_save_attempt&moodlewsrestformat=json&attemptid=${
          qdata.assesmentId
        }&data[0][name]=${firstVal}&data[0][value]=${0}&data[1][name]=${
          qdata.formElements[a3].name
        }&data[1][value]=${1}&data[2][name]=${
          qdata.formElements[a5].name
        }&data[2][value]=${setAnswer}`;
      }

      axios.get(linkcreated).then((response) => {
        console.log("checking1.0: response", linkcreated);
      });
    } else if (type === "multianswer") {
      console.log("xdxdxd 2 multianswer");
      let id;
      let valueInp = [];

      let noOfInput = (qdata.formElements.length - 4) / 2;

      for (let i = 1; i <= noOfInput; i++) {
        id = `#in${i}`;
        valueInp[i - 1] = document.querySelector(id).value;
        console.log("INDER VI" + valueInp);
      }

      let linkcreated = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_save_attempt&moodlewsrestformat=json&attemptid=${qdata.assesmentId}&data[0][name]=${qdata.formElements[0].name}&data[0][value]=${qdata.formElements[1].value}&data[1][name]=${qdata.formElements[2].name}&data[1][value]=${qdata.formElements[3].value}`;

      for (let i = 1; i <= noOfInput; i++) {
        // i = 1
        //`&data[2][name]=${qdata.formElements[4].name}&data[2][value]=${valueInp[0]}`;

        // i = 2
        //`&data[3][name]=${qdata.formElements[6].name}&data[3][value]=${valueInp[1]}`;

        // i = 3
        //`&data[4][name]=${qdata.formElements[8].name}&data[4][value]=${valueInp[2]}`;

        linkcreated += `&data[${i + 1}][name]=${
          qdata.formElements[i * 2 + 2].name
        }&data[${i + 1}][value]=${valueInp[i - 1]}`;
      }

      axios.get(linkcreated).then((response) => {
        console.log("xdxdxd 3 multianswer", response.data);
      });
    } /* else if (type === "truefalse") {
      console.log("xdxdxd 2 truefalse");

      console.log(
        `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_save_attempt&moodlewsrestformat=json&attemptid=${qdata.assesmentId}&data[0][name]=${qdata.formElements[0].name}`
      );

      let linkcreated = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_save_attempt&moodlewsrestformat=json&attemptid=${qdata.assesmentId}&data[0][name]=${qdata.formElements[0].name}&data[0][value]=${qdata.formElements[1].value}&data[1][name]=${qdata.formElements[2].name}&data[1][value]=${qdata.formElements[3].value}&data[2][name]=${qdata.formElements[4].name}&data[2][value]=${multichoiseVal}`;

      axios.get(linkcreated).then((response) => {
        console.log("xdxdxd 3 truefalse", response.data);
      });
    } */

    setAttSaved((prevState) => prevState + 1);
  };

  const goToPrevious = () => {
    qdata.previousQuestion();
    saveAttempt(qdata.questionType);
    previousQuestion();
  };
  const goToNext = () => {
    console.log("go to next");
    
    qdata.nextQuestion();
    saveAttempt(qdata.questionType);
    nextQuestion();
  };

  console.log(qdata);
  let navigationButtons = [];

  useEffect(() => {
    try {
      axios.get(attemptSummaryURL).then((response) => {
        console.log("inder uyuyuy", attemptSummaryURL);
        if (response.statusText !== "OK") {
          throw new Error("data not found");
        }

        console.log("getRes", response.data);

        const attVal = response.data.questions.map((data) => {
          return data.status;
        });

        const flagVal = response.data.questions.map((data) => {
          return data.flagged;
        });

        setAllAttemtptedData(attVal);
        setAllFlaged(flagVal);
      });
    } catch (err) {
      console.log("data not found");
    }

    setflagChecked(qdata.answerFlaged);
  }, [attemptSummaryURL, qdata.answerFlaged, attSaved]);

  console.log("IND", allAttemptedData);

  for (let i = 0; i < qdata.noOfQuestion; i++) {
    navigationButtons.push(
      <Quiznavigation
        qNo={i}
        from="attempt"
        isAttempted={allAttemptedData[i]}
        activeQ={qdata.pageNo}
        setPageNo={qdata.setPageNo}
        isFlaged={allFlaged[i]}
        key={i}
        setAttSaved={setAttSaved}
      />
    );
  }

  const submitHandler = (isTime) => {
    console.log("submitHandler")
    let timeUp = isTime === "timeup" ? true : false;

    if (qdata.hasOwnProperty("error")) {
      setIsThereError({ error: true, message: qdata.error });
    } else {
      setIsThereError({ error: false, message: "" });
    }

    console.log(isThereError);

    if (!isThereError.error) {
      console.log("checking1.0: ", isThereError);
      saveAttempt(qdata.questionType);

      navigation(
        `attemptSummary?id=${attemptId}&quizId=${quizID}&isTimeup=${timeUp}`,
        {
          state: {
            token: token,
            assessmentId: qdata.assesmentId,
            quizID: qdata.quizID,
          },
        }
      );
    }
  };

  const flagChanged = (e) => {
    let flag = e.target.checked ? 1 : 0;
    console.log("flag", flag);

    setflagChecked(e.target.checked);

    console.log("flag33 value", e.target.checked);

    const flagsetURL = `${
      process.env.REACT_APP_wsPrefix
    }/webservice/rest/server.php?wstoken=${token}&wsfunction=core_question_update_flag&qaid=${
      qdata.qaid
    }&qubaid=${qdata.qubaid}&slot=${qdata.pageNo + 1}&checksum=${
      qdata.checksum
    }&newstate=${flag}&questionid=${qdata.qid}`;

    axios.get(flagsetURL).then((response) => {
      console.log(response.data);
      console.log(flagsetURL);
    });
    //qdata.flagQuestion();
  };

  function createCountdown(initialMinutes) {
    console.log("createCountdown");
    let totalSeconds = initialMinutes * 60;

    intervalId = setInterval(() => {
      if (totalSeconds > 0) {
        totalSeconds--;
        console.log(formatTime(totalSeconds)); // Display formatted time
      } else {
        clearInterval(intervalId); // Stop the timer when it reaches 0
        console.log("Time's up!");
      }
    }, 1000);

    function formatTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;
      setShowTimings(
        `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(secs).padStart(2, "0")}`
      );

      console.log(seconds, "timeleft");
      if (seconds === 5) {
        submitHandler("timeup");
      }
    }
  }

  return (
    <>
      {/* <Box className="timer">Time left: {showTimings}</Box> */}
      <Grid container spacing={2} className="quizQuestionContainer">
        <Grid item xs={12} lg={9} className="assesmentSingQuest">
          {isThereError && <Box>{isThereError.message}</Box>}
          <Card
            maxWidth="xl"
            sx={{
              p: 1,
              backgroundColor: "cardColor.main",
              color: "cardColor.contrast",
              textAlign: "center",
              position: "relative",
            }}
            className="customCard assesmentQuestWrapper"
          >
            <Box
              className={`Flagged ${
                flagChecked ? "flagChecked" : "flagNotChecked"
              }`}
            >
              <input
                type="checkbox"
                id="myCheckbox1"
                onChange={flagChanged}
                checked={flagChecked}
              />
              <label for="myCheckbox1">
                <BookmarkAddedIcon />
              </label>
            </Box>
            {qdata.questionType === "multichoice" && (
              <Box className="assessmentQuest">
                <p>{createHTML(qdata.questionText)}</p>
                {console.log(
                  "PREVIOUS ATTEMPT: 225",
                  isAttempted ? previousAttempt : ""
                )}
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="quizQuest1"
                  defaultValue={isAttempted ? previousAttempt : ""}
                  className="radioOptions"
                  row
                >
                  {qdata.answer.map((option, indx) => {
                    return (
                      <FormControlLabel
                        value={indx}
                        control={<Radio />}
                        label={createHTML(option.answer)}
                        key={option.id}
                        onChange={handlemultichoiceChange}
                      />
                    );
                  })}
                </RadioGroup>
              </Box>
            )}

            {qdata.questionType === "multianswer" && (
              <Box className="assessmentQuest">
                <p>{addInput(qdata.questionText)}</p>
              </Box>
            )}

            {qdata.questionType === "truefalse" && (
              <Box className="assessmentQuest">
                <p>{createHTML(qdata.questionText)}</p>
                <Box>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label1"
                    name="quizQuest2"
                    defaultValue={isAttempted ? previousAttempt : ""}
                    className="radioOptions"
                    row
                  >
                    {console.log(previousAttempt)}
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="True"
                      onChange={handlemultichoiceChange}
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="False"
                      onChange={handlemultichoiceChange}
                    />
                  </RadioGroup>
                </Box>
              </Box>
            )}

            <Box className="quizNextBack">
              <Box>
                <Button
                  color="secondaryColor"
                  variant="contained"
                  size="small"
                  startIcon={<ArrowBackIosIcon />}
                  onClick={goToPrevious}
                  disabled={qdata.pageNo === 0 || isThereError.error}
                  className="formsBtns"
                >
                  {curLanguage.previous}
                </Button>
              </Box>
              <Box className="noOfQuest">{`${qdata.pageNo + 1}/${
                qdata.noOfQuestion
              }`}</Box>
              <Box>
                {qdata.pageNo === qdata.noOfQuestion - 1 ? (
                  <Button
                    color="secondaryColor"
                    variant="contained"
                    size="small"
                    endIcon={<ArrowForwardIosIcon />}
                    onClick={submitHandler}
                    className="formsBtns"
                    disabled={isThereError.error}
                  >
                    {curLanguage.submit}
                  </Button>
                ) : (
                  <Button
                    color="secondaryColor"
                    variant="contained"
                    size="small"
                    endIcon={<ArrowForwardIosIcon />}
                    onClick={goToNext}
                    className="formsBtns"
                    disabled={isThereError.error}
                  >
                    {curLanguage.next}
                  </Button>
                )}
              </Box>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} lg={3}>
          <Box className="Quiznavigation-inner roudned_box_assessment">
            <Grid>
              <Typography
                variant="h3"
                sx={{
                  color: "Quiznavigation.textColor",
                  borderBottom: "3px solid",
                  borderColor: "Quiznavigation.textColor",
                }}
              >
                {curLanguage.QuizNavigation}
              </Typography>
            </Grid>
            <Grid container className="Quiznavigation-box" gap={1}>
              {navigationButtons}
            </Grid>
            <Grid sx={{ textAlign: "center" }}>
              <Button
                color="secondaryColor"
                variant="contained"
                size="small"
                sx={{ marginBottom: "15px" }}
                onClick={submitHandler}
                className="formsBtns"
              >
                {curLanguage.submit}
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default QuizQuestions;
