import React, { useEffect, useState } from 'react';
import { MenuItem, FormControl, Select, InputLabel, Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import MainCard from "../../../_Components/Common/Cards/MainCard";
import StudentList from './StudentList';
import { PrimaryBgBtn } from '../../../_Components/Common/Buttons/CustomBtns';
import Loader from '../../../_Components/Utils/loader/Loader';
const TeacherCard = () => {
  const [academicYear, setAcademicYear] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const nToken = localStorage.getItem("nToken");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("token");
  const [formValues, setFormValues] = useState([
    {
      stdClass: "",
      stdsection: "",
    },
  ]);
  const [data, setData] = useState()
  const [stdClassInfo, setStdClassInfo] = useState([]);
  const [reportType, setReportType] = useState('');
  const [count, setCount] = useState('');
  const [open, setOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [messageReponse, setMessageReponse] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setIsSubmitting(false);
  };
  const handleReportTypeChange = (event) => {
    setReportType(event.target.value);
    setCount('');
  };
  const handleCountChange = (event) => setCount(event.target.value);

  const handleSubmit = async () => {
    console.log(isSubmitting);

    setIsSubmitting(true);
    console.log(isSubmitting);
    setIsLoader(true)

    const data = {
      selectedYear,
      formValues,
      reportType,
      count: parseInt(count, 10),
    };


    const newData = {
      academic_year: selectedYear,
      class: formValues[0].stdClass,
      section: formValues[0].stdsection,
      schoolcode: userinfo[0]?.institution
    }
    const department = userinfo[0]?.department === "Pedagogy" ? "teacher" : 'admin'
    try {
      const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getMarksReports?schoolcode=${userinfo[0]?.institution}&class=${newData.class}&section=${newData.section}&academic_year=${newData.academic_year}&department=${department}`;
      const response = await axios.get(baseURL, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      });
      setIsLoader(false)
      setData(response.data.studentData)

      setMessageReponse(response.data.message)
      if (response.data.error) {
        setOpen(true);
      }
    } catch (error) {
      console.error('Error submitting data:', error);

    }

  };
  const numberOptions = reportType === 'Semester' ? [1, 2, 3, 4] : [1, 2, 3, 4];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getAcademicYear?schoolcode=${userinfo[0]?.institution}`
        const response = await axios.get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });

        const data = response.data.academic_year_data;
        setAcademicYear(data)
        if (data.length > 0) {
          setSelectedYear(data[0].academic_year_id);
        }
      } catch (error) {
        console.error("Error fetching academicYear:", error);
      }
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection?schoolcode=${userinfo[0]?.institution}`;
        const response = await axios.get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });

        const data = response.data.class_section;
        console.log(data);


        setClasses([...new Set(data.map((item) => item.class))]);
        setSections([...new Set(data.map((item) => item.section))]);
      } catch (error) {
        console.error("Error fetching class and section data:", error);
      }

    };

    fetchData();

  }, []);
  const handleInputData = (index, event) => {
    const { name, value } = event.target;
    const newFormValues = [...formValues];
    newFormValues[index][name] = value;

    if (name === "stdsection" && value === sections) {
      newFormValues[index][name] = sections;
    } else {
      newFormValues[index][name] = value;
    }

    setFormValues(newFormValues);
    setIsSubmitting(false);
    if (name === "stdClass" || name === "stdsection") {
      const selectedClass =
        name === "stdClass" ? value : newFormValues[index].stdClass;
      const selectedSection =
        name === "stdsection" ? value : newFormValues[index].stdsection;

      if (selectedClass && selectedSection) {
        const selectedClassInfo = stdClassInfo.find(
          (item) =>
            item.class === parseInt(selectedClass) &&
            item.section === selectedSection
        );

      } else if (selectedClass) {
        const selectedClassInfo = stdClassInfo.find(
          (item) => item.class === parseInt(selectedClass)
        );

      }
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <MainCard cardTitle="Report Card" customClass="">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent:'center'
          }}
        >
          <Box sx={{ width:{xs:'50%' ,md:'33%'}, "@media (max-width: 420px)": {width:'98%'} }}>
          <FormControl size="small" sx={{ minWidth: '100%' }}>
            <InputLabel>Academic Year</InputLabel>
            <Select value={selectedYear} onChange={handleYearChange} label="Academic Year">
              {Array.isArray(academicYear) &&
                academicYear.map((year) => (
                  <MenuItem key={year.academic_year_id} value={year.academic_year_id}>
                    {year.academic_year}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          </Box>
          {formValues.map((formValue, index) => (
            <>
               <Box sx={{ width:{xs:'22%' ,md:'15%'}, "@media (max-width: 420px)": {width:'48%'} }}>
               <FormControl size="small" sx={{ minWidth: '100%' }}>
                <InputLabel id="stdClass-label">Class</InputLabel>
                <Select
                  labelId="stdClass-label"
                  id="stdClass"
                  name="stdClass"
                  value={formValue.stdClass}
                  onChange={(event) => handleInputData(index, event)}
                  label="Class"
                >
                  {classes.map((cls) => (
                    <MenuItem key={cls} value={cls}>
                      {cls}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Box>
              <Box sx={{ width:{xs:'22%' ,md:'15%'} , "@media (max-width: 420px)": {width:'48%'}}}>
               <FormControl size="small" sx={{ minWidth: '100%' }}>
                <InputLabel id="stdsection-label">Section</InputLabel>
                <Select
                  labelId="stdsection-label"
                  id="stdsection"
                  name="stdsection"
                  value={
                    formValue.stdsection === "All"
                      ? "All Section"
                      : formValue.stdsection
                  }
                  onChange={(event) => handleInputData(index, event)}
                  label="Section"
                >
                  {sections.map((section) => (
                    <MenuItem key={section} value={section}>
                      {section.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Box>
            </>
          ))}
          {reportType && (
            <FormControl size="small" sx={{ minWidth: 150 }}>
              <InputLabel>No. of {reportType}s</InputLabel>
              <Select value={count} onChange={handleCountChange} label={`Number of ${reportType}s`}>
                {numberOptions.map((num) => (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {console.log(isSubmitting)}
          <PrimaryBgBtn onClick={handleSubmit}
            disabled={isSubmitting || !selectedYear || !formValues[0].stdClass || !formValues[0].stdsection}>
            Submit
          </PrimaryBgBtn>

          {console.log(isSubmitting)}
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Progress Report"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {messageReponse || "Something went wrong"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <PrimaryBgBtn onClick={handleClose} autoFocus>
              OK
            </PrimaryBgBtn>
          </DialogActions>
        </Dialog>
      </MainCard >
      {isLoader ? <Loader /> : (data && data) &&
        <MainCard>
          <StudentList
            studentData={data}
            class_section={formValues}
            academic_year={selectedYear}
          />
        </MainCard>}
    </Box>
  );

};

export default TeacherCard;
