import React, { useState } from "react";
import styles from "./dashboard.module.scss";
import RecentItems from "../recentItems/RecentItems";
import { Box } from "@mui/material";
import Container from "@mui/material/Container";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AdminDashboard from "./AdminUser/AdminDashboard";
import MyClasses from "../myCourses/MyClasses";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";
import PrimaryCard from "../../_Components/Common/Cards/PrimaryCard";
import TeacherSyllabus from "../../_Components/Common/Charts/TeacherSyllabus";
import MyCourse from "../recentCourse/MyCourse";
import PrincipalDashboard from "../PrincipalUser/Dashboard/PrincipalDashboard";
import { Link } from "react-router-dom";
import StdMarks from "../../_Components/Common/Charts/StdMarks";

function Dashboard(props) {
  const { permissions } = usePermissions();
  const token = localStorage.getItem("token");
  const [teacherSubjects, setTeacherSubjects] = useState("");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));



  return (
    <Box className={styles.dashboardContainer}>
      <Container maxWidth="xl">
        {permissions.admindashboard?.write &&
          permissions.admindashboard?.read ? (
          <DndProvider backend={HTML5Backend}>
            <AdminDashboard />
          </DndProvider>
        ) : (
          !permissions.dashboard?.write &&
          !permissions.dashboard?.read && (
            <Link to="/devpermission">Development</Link>
          )
        )}
        {permissions.principalDashboard?.write &&
          permissions.principalDashboard?.read && <PrincipalDashboard />}

        {permissions.dashboard?.write && permissions.dashboard?.read && (
          <>
            <Box className={styles.dashboardFrame} pb={3}>
              <Box>
                {permissions.classes?.write && (
                  <PrimaryCard
                    curLanguage="Course Progress"
                    titleClass="mainHeading"
                  >
                    <Box width={"100%"} sx={{ minHeight: "360px" }}>
                      <TeacherSyllabus
                        theme="light"
                        userInfo={userinfo}
                        teacherSubjects={teacherSubjects}
                      />
                    </Box>
                  </PrimaryCard>
                )}
                {permissions.courses?.write && <MyCourse />}
              </Box>

              <Box>
                {(permissions.classes?.write || permissions.courses?.write) && (
                  <RecentItems token={token} />
                )}
              </Box>
            </Box>
            <Box className={styles.dashboardFrame2} sx={{ position: "relative" }}>
              {permissions.classes?.write && (
                <PrimaryCard
                  curLanguage="Students Progress"
                  titleClass="mainHeading"
                >
                  <StdMarks theme="light"
                    userInfo={userinfo} />
                </PrimaryCard>
              )}
            </Box>
            <Box className={styles.dashboardFrame2}>
              {permissions.classes?.write && <MyClasses />}
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
}

export default Dashboard;
