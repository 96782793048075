import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  SnackbarContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import GetCalendarEvent from "./getCalendarEvent/getCalendarEvent";

import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { languages } from "../../App";
import CircularLoader from "../../_Components/Common/Loader/CircularLoader";
import { MonthData } from "./MonthData";
import EventList from "./EventRecord/EventList";
import CreateCalendarEvent from "./createCalendarEvent/createCalendarEvent";

function CalendarData() {
  let curLanguage = useContext(languages);
  const [calendarData, setCalendarData] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [eventid, seteventid] = useState();
  const [popupVisible, setpopupVisible] = useState(false);
  const [createnew, setCreateNewPop] = useState(false);
  const [confirm, setConfirm] = useState({ isShow: false, eventCount: null });
  const [datecal, setDatecal] = useState();
  const [monthcal, setMonthcal] = useState();
  const [yearcal, setYearcal] = useState();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  const d = new Date();
  let tmpM = d.getMonth();
  let tmpY = d.getFullYear();
  let tmpD = d.getDate();
  const [monthYearCount, setMonthYearCount] = useState({
    year: tmpY,
    month: tmpM,
  });
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  useEffect(() => {
    const baseURL = `${
      process.env.REACT_APP_wsPrefix
    }/webservice/rest/server.php?wstoken=${token}&wsfunction=core_calendar_get_calendar_monthly_view&moodlewsrestformat=json&year=${
      monthYearCount.year
    }&month=${monthYearCount.month + 1}`;
    axios
      .get(baseURL)
      .then((response) => {
        setCalendarData(response.data);
        setLoading(true);
        console.log(response.data, "responsedata");
      });
  }, [monthYearCount, popupVisible]);

  const handleEventUpdated = () => {
    const baseURL = `${
      process.env.REACT_APP_wsPrefix
    }/webservice/rest/server.php?wstoken=${token}&wsfunction=core_calendar_get_calendar_monthly_view&moodlewsrestformat=json&year=${
      monthYearCount.year
    }&month=${monthYearCount.month + 1}`;
    axios.get(baseURL).then((response) => {
      setCalendarData(response.data);
    });
  };

  const handleCalenderDateClick = (e, monthName) => {
    const selectedMonth = monthName.replace(/\d+/g, "").trim();
    const monthnumber = MonthData[selectedMonth];
    const selectedYear = parseInt(monthName.match(/\d{4}/)[0], 10);
    setDatecal(e.currentTarget.getAttribute("data-date"));
    setMonthcal(monthnumber);
    setYearcal(selectedYear);
    if (selectedYear > tmpY) {
      setCreateNewPop(true);
    } else if (selectedYear === tmpY) {
      if (monthnumber > tmpM) {
        setCreateNewPop(true);
      } else if (monthnumber === tmpM) {
        if (e.currentTarget.getAttribute("data-date") < tmpD) {
          setCreateNewPop(false);
          alert("Events cannot be scheduled for past dates.");
        } else {
          setCreateNewPop(true);
        }
      } else {
        setCreateNewPop(false);
        alert("Events cannot be scheduled for past months.");
      }
    } else {
      setCreateNewPop(false);
      alert("Events cannot be scheduled for past years.");
    }
  };

  const onPopupClose = () => {
    setpopupVisible(false);
    setConfirm({ isShow: false, eventCount: null });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleCalendarNextPrev = (e) => {
    let tempY = monthYearCount.year;
    let tempM = monthYearCount.month;
    if (e.target.getAttribute("id") === "next") {
      if (tempM >= 12) {
        tempY = monthYearCount.year + 1;
        tempM = 0;
      }
      tempM = tempM + 1;
    } else {
      if (tempM <= 0) {
        tempY = monthYearCount.year - 1;
        tempM = 12;
      }
      tempM = tempM - 1;
    }
    setMonthYearCount({ year: tempY, month: tempM });
  };

  // breakpoint for small device
  const smBreakPoint = useMediaQuery("(max-width:580px)");

  return (
    <>
      <Box>
        {!loading ? (
          <CircularLoader />
        ) : (
          <>
          <Snackbar
              open={openSnackbar}
              onClose={handleSnackbarClose}
              autoHideDuration={5000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <SnackbarContent
                sx={{ backgroundColor: "greenColor.color" }}
                message="Event added successfully!"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={handleSnackbarClose}
                  >
                    OK
                  </Button>
                }
              />
            </Snackbar>

            <Grid
              className="calendarHeader"
              sx={{
                color: "calendar.bgcolor",
                borderColor: "calendar.bgcolor",
              }}
            >
              <Button
                startIcon={
                  <ArrowBackIosIcon
                    className="arrowIcon"
                    sx={{ color: "calendar.whiteColor" }}
                  />
                }
                variant="contained"
                sx={{ boxShadow: "none", backgroundColor: "calendar.bgcolor" }}
                color="calendarButton"
                id="prev"
                className="arrow"
                onClick={handleCalendarNextPrev}
              >
                {smBreakPoint || calendarData.previousperiodname}{" "}
              </Button>
              <Typography
                variant="h4"
                sx={{ color: "calendar.bgcolor", fontWeight: "600" }}
              >
                {calendarData.periodname}
              </Typography>
              <Button
                endIcon={
                  <ArrowForwardIosIcon
                    className="arrowIcon"
                    sx={{ color: "calendar.whiteColor" }}
                  />
                }
                variant="contained"
                sx={{ boxShadow: "none", backgroundColor: "calendar.bgcolor" }}
                color="calendarButton"
                id="next"
                className="arrow"
                onClick={handleCalendarNextPrev}
              >
                {smBreakPoint || calendarData.nextperiodname}
              </Button>
            </Grid>
            {calendarData.weeks
              ? calendarData.weeks.map((val, index) => {
                  return (
                    <Grid container key={index} className="week-cont">
                      {calendarData.weeks[index].days.map((val, indx) => {
                        return (
                          <Grid
                            key={index}
                            className={
                              tmpD === val.mday
                                ? "activeDays-cont days-cont"
                                : "days-cont"
                            }
                            variant={
                              tmpD === val.mday
                                ? "activeCalendar"
                                : "inactiveCalendar"
                            }
                          >
                            <Button
                              variant="outlined"
                              className={`${days[val.wday]} calendarCell`}
                              data-date={val.mday}
                              onClick={(e) =>
                                handleCalenderDateClick(
                                  e,
                                  calendarData.periodname
                                )
                              }
                            >
                              <Box
                                component="span"
                                className="eventListWrapper"
                              >
                                <Grid>
                                  <Grid
                                    className="dateNum"
                                    sx={{
                                      color: "secondaryColor.main",
                                      ":hover": {
                                        color: "#fff",
                                      },
                                    }}
                                  >
                                    {val.mday}
                                  </Grid>
                                  <Grid
                                    className="dateWeek"
                                    sx={{ color: "secondaryColor.main" }}
                                  >
                                    {days[val.wday]}
                                  </Grid>
                                </Grid>

                                {val.events.filter((filtEvent) => {
                                  return (
                                    filtEvent.component ||
                                    filtEvent.eventtype === "course" ||
                                    filtEvent.canedit
                                  );
                                }).length ? (
                                  <Box
                                    component="span"
                                    className="calHaveEvents"
                                  >
                                    {
                                      val.events.filter((filtEvent) => {
                                        return (
                                          filtEvent.component ||
                                          filtEvent.eventtype === "course" ||
                                          filtEvent.canedit
                                        );
                                      }).length
                                    }
                                  </Box>
                                ) : (
                                  ""
                                )}

                                {val.events.filter((filtEvent) => {
                                  return (
                                    filtEvent.component ||
                                    filtEvent.eventtype === "course" ||
                                    filtEvent.canedit
                                  );
                                }).length > 0 && (
                                  <Box
                                    component="span"
                                    className="calSepEvents"
                                  >
                                    <ul className="eventlist">
                                      <EventList
                                        eventArr={val.events}
                                        eventIntialDateCheck={val.mday}
                                        month={calendarData.periodname}
                                        seteventid={seteventid}
                                        setpopupVisible={setpopupVisible}
                                      />
                                    </ul>
                                  </Box>
                                )}
                              </Box>
                              <Box className="attendanceMarker">
                                <span
                                  className="attenMarkerText"
                                  aria-label="Present"
                                ></span>
                              </Box>
                            </Button>
                          </Grid>
                        );
                      })}
                    </Grid>
                  );
                })
              : ""}
          </>
        )}
      </Box>

      <Dialog
        open={popupVisible}
        close={onPopupClose}
        className="eventsDialogue"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <GetCalendarEvent
          setConfirm={setConfirm}
          confirm={confirm}
          isVisible={setpopupVisible}
          id={eventid}
          token={token}
        />
      </Dialog>

      <Dialog
        open={createnew}
        className="createEventDialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            backgroundColor: "popup.titleHeaderColor",
            color: "popup.popupheadertextcolor",
            mb: 4,
          }}
        >
          {curLanguage.createNewEvent}
          <Button
            className="popupCloseButton"
            onClick={() => setCreateNewPop(false)}
            color="popupbutton"
            variant="contained"
            sx={{ backgroundColor: "secondaryColor.main", color: "white" }}
          >
            {" "}
            <CloseIcon />
          </Button>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "cardColor.main",
            color: "cardColor.contrast",
          }}
        >
          <CreateCalendarEvent
            dates={datecal}
            months={monthcal}
            years={yearcal}
            close={setCreateNewPop}
            onEventUpdate={handleEventUpdated}
            setOpenSnackbar = {setOpenSnackbar}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
export default CalendarData;
