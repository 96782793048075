import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Grid,
  Typography,
  FormLabel,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import axios from "axios";
import ProfileBox from "../../userProfile/ProfileBox";
import ProfileImg from "../../userProfile/ProfileImg";
import MainCard from "../../../_Components/Common/Cards/MainCard";
import { languages } from "../../../App";
import StudentMarksReport from "./StudentMarksReport";

const ReportProfile = () => {
  const curLanguage = useContext(languages);
  const [profileInfo, setProfileInfo] = useState(null);
  const [schoolName, setSchoolName] = useState(null);
  const [Schoollogo, setSchoollogo] = useState(null);
  const userinfoObject = JSON.parse(localStorage.getItem("userInfo"));
  const classinfo = JSON.parse(localStorage.getItem("classInfo"));
  const userId = userinfoObject[0]?.id;
  const username = userinfoObject[0]?.id;
  const [profileImgInfo, setProfileImgInfo] = useState(
    userinfoObject[0]?.profileimageurl
  );
  const nToken = localStorage.getItem("nToken");
  const financialYear = localStorage.getItem("AY");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/viewSchoolConfig?schoolcode=${userinfoObject[0]?.institution}`;
        const response = await axios.get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });
        console.log(response.data);
        const school_logo = JSON.parse(response.data.config_data);
        console.log(school_logo);
        if (response.data) {
          setSchoollogo(school_logo.small_logo);
        }
      } catch (error) {
        console.error("Error fetching class and section data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_nodewsPrefix}/getUserProfile?userid=${userId}`
      )
      .then((response) => setProfileInfo(response.data.info))
      .catch((error) => console.error("Error fetching profile:", error));
    axios
      .get(`${process.env.REACT_APP_nodewsPrefix}/getAllSchools`)
      .then((response) => {
        const selectedSchoolInfo = response.data.info.find(
          (data) => data.id === Number(userinfoObject[0]?.institution)
        );
        setSchoolName(selectedSchoolInfo);
      })
      .catch((error) => console.error("Error fetching school details:", error));
  }, []);

  return (
    <Box className="profileWrapper">
      <MainCard
        className="profilecontent"
        mainPage
        cardTitle="Report  Card"
        mt="15px"
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
          sx={{ marginBottom: " 20px" }}
        >
          {/* Report Title Centered */}
          <Typography
            variant="h4"
            textAlign="center"
            sx={{
              outline: "1px solid #fff",
              outlineWidth: "medium",
              padding: "10px 25px 15px",
              background: "#2769aa",
              color: "#fff",
              outlineOffset: "-5px",
              borderRadius: " 15px",
              fontWeight: "500",
              fontFamily: '"Poppins", sans-serif !important',
              marginBottom: "20px",
            }}
          >
            Progress Report
          </Typography>

          {/* School Logo on the Right */}
          {Schoollogo && (
            <Box
              component="img"
              src={`${process.env.REACT_APP_nodewsPrefix}/${Schoollogo}`}
              alt="School Logo"
              sx={{
                width: 80,
                height: 80,
                objectFit: "cover",
                position: { xs: "relative", md: "absolute" },
                top: { xs: 0, md: 89 },
                right: { xs: 0, md: 55 },
                left: { xs: 0, md: "auto" },
                margin: "0 auto",
              }}
            />
          )}
        </Grid>
        <Grid container spacing={3} alignItems="center" mb={5}>
          <Grid
            item
            lg={1}
            md={2}
            sm={4}
            xs={12}
            display="flex"
            justifyContent="center"
          >
            <Box
              sx={{
                boxShadow:
                  "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
              }}
            >
              <ProfileImg
                profileImgInfo={profileImgInfo}
                userId={userId}
                username={username}
                showEditButton={false}
              />
            </Box>
          </Grid>
          <Grid item lg={11} md={10} sm={8} xs={12}>
            <Typography variant="h5" sx={{ fontWeight: "600" }}>
              {profileInfo?.[0]?.firstname} {profileInfo?.[0]?.lastname}
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              sx={{ fontWeight: "500" }}
            >
              {schoolName?.name || "N/A"}
            </Typography>
            <Grid container spacing={2} mt={0}>
              <ProfileBox>
                <Box
                  sx={{
                    borderRadius: "15px",
                    padding: "7px 15px 15px",
                    border: "1px solid #000000a1",
                  }}
                >
                  <FormLabel>Admission Number</FormLabel>
                  <Typography
                    sx={{ maxWidth: "85%", borderBottom: "2px dotted #000000" }}
                  >
                    {userinfoObject[0]?.id}
                  </Typography>
                </Box>
              </ProfileBox>
              <ProfileBox>
                <Box
                  sx={{
                    borderRadius: "15px",
                    padding: "7px 15px 15px",
                    border: "1px solid #000000a1",
                  }}
                >
                  <FormLabel>Class</FormLabel>
                  <Typography
                    sx={{ maxWidth: "85%", borderBottom: "2px dotted #000000" }}
                  >
                    {classinfo?.class || "N/A"}
                  </Typography>
                </Box>
              </ProfileBox>
              <ProfileBox>
                <Box
                  sx={{
                    borderRadius: "15px",
                    padding: "7px 15px 15px",
                    border: "1px solid #000000a1",
                  }}
                >
                  <FormLabel>Section</FormLabel>
                  <Typography
                    sx={{ maxWidth: "85%", borderBottom: "2px dotted #000000" }}
                  >
                    {classinfo?.section || "N/A"}
                  </Typography>
                </Box>
              </ProfileBox>
              <ProfileBox>
                <Box
                  sx={{
                    borderRadius: "15px",
                    padding: "7px 15px 15px",
                    border: "1px solid #000000a1",
                  }}
                >
                  <FormLabel>Roll Number</FormLabel>
                  <Typography
                    sx={{ maxWidth: "85%", borderBottom: "2px dotted #000000" }}
                  >
                    {profileInfo?.[0]?.idnumber || "N/A"}
                  </Typography>
                </Box>
              </ProfileBox>
            </Grid>
          </Grid>
        </Grid>
        <StudentMarksReport />
      </MainCard>
    </Box>
  );
};

export default ReportProfile;
