import { useEffect, useState } from "react";
import axios from "axios";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import MainCard from "../../_Components/Common/Cards/MainCard";
import CreatePDF from "../../_Components/Common/pdf/CreatePDF";
import CircularLoader from "../../_Components/Common/Loader/CircularLoader";
import { Scrollbar } from "react-scrollbars-custom";
import ChapterAccordion from "./ChapterAccordion";

const StudentProgress = () => {
  const userinfoObject = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState([]);
  const nToken = localStorage.getItem("nToken");
  const location = useLocation();
  const financialYear = localStorage.getItem("AY");
  const searchParams = new URLSearchParams(location.search);

  const stdId = searchParams.get("id");
  const studentName = searchParams.get("studentName");
  const courseId = searchParams.get("courseid");
  const coursenames = searchParams.get("coursename");
  const classSection =
    searchParams.get("stdclass") + searchParams.get("section");


  useEffect(() => {
    const fetchData = async () => {
      try {
        const reportDataURL = `${process.env.REACT_APP_nodewsPrefix}/viewActivityCompletion`;
        const response = await axios.post(
          reportDataURL,
          {
            course_id: courseId,
            userid: stdId,
            department: userinfoObject[0]?.department,
            schoolcode: userinfoObject[0].institution,
            academic_year: financialYear,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );
        const data = response.data.course_data;
        console.log(data, "datadata")
        setActivityData(data);

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <MainCard
      cardTitle={`${coursenames} ${classSection.toUpperCase()}`}
      customClass="progressCard"
      additionalData={
        (activityData.length > 0) &&
        <CreatePDF
          id={studentName}
          fileName="progress_report"
          orientation={"portrait"}
          btnTitle={"Download Report"}
          pdfHeading={"Report"}
        />
      }
    >
      <Typography className="progressHeader" variant="h6">
        Progress Report
      </Typography>
      {loading ? (
        <CircularLoader />
      ) : (
        <Scrollbar style={{ maxWidth: "100%", height: `calc(70vh - 30px)` }}>
          <Box id={studentName} className="progressContent">
            <Box display={"flex"} alignItems={"center"} gap={2} p={1}>
              <Typography>
                <b>Name:</b> {studentName}
              </Typography>
              <Typography>
                <b>Class:</b> {classSection.toUpperCase()}
              </Typography>
            </Box>

            <Box className="activitySec">
              {console.log(activityData, "activityData")}
              {activityData.map((section, index) => (
                <ChapterAccordion key={index} section={section} />
              ))}

            </Box>
          </Box>
        </Scrollbar>
      )}
    </MainCard>
  );
};

export default StudentProgress;
