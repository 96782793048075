import React, { useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import MainCard from "../../_Components/Common/Cards/MainCard";
import { Scrollbar } from "react-scrollbars-custom";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./StudentProgress.scss";
import CreatePDF from "../../_Components/Common/pdf/CreatePDF";
import CircularLoader from "../../_Components/Common/Loader/CircularLoader";
import SectionWiseReport from "./SectionWiseReport";
import PrimaryCard from "../../_Components/Common/Cards/PrimaryCard";
import SectionWiseChart from "./SectionWiseChart";

const StudentProgressDetail = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [progressData, setProgressData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const courseId = searchParams.get("courseid");
  const coursenames = searchParams.get("coursename");
  const classess = searchParams.get("stdclass");
  const section = searchParams.get("section");
  const classSection = classess + section;
  const nToken = localStorage.getItem("nToken");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reportDataURL = `${process.env.REACT_APP_nodewsPrefix}/getActivityCompletion?course_id=${courseId}&class=${classess}&section=${section}&schoolcode=${userInfo[0].institution}`;
        const response = await axios.get(reportDataURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });
        setCourseData(response.data.course_module);
        console.log(response.data, "course module data");
        const getActivityName = (id) => {
          let courseName;
          response.data.course_module.forEach((courseData) => {
            courseData.id.forEach((activityId) => {
              if (parseInt(activityId) === id) {
                courseName = courseData.name;
              }
            });
          });
          return courseName;
        };

        const studentFullData = response.data.course_user.map((studentData) => {
          let studentActivities = studentData.attendId.map(
            (studentAttemptID) => {
              return getActivityName(studentAttemptID);
            }
          );

          const counts = studentActivities.reduce((acc, curr) => {
            acc[curr] = (acc[curr] || 0) + 1;
            return acc;
          }, {});

          const result = Object.entries(counts).map(([key, value]) => ({
            activity: key,
            count: value,
          }));

          return { ...studentData, count: result };
        });
        setLoading(false);
        setProgressData(studentFullData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [courseId, classSection]);

  const moduleShortun = (heading) => {
    let size = "11px";
    if (heading.length > 10 && heading.length <= 20) {
      size = "8px";
    } else if (heading.length > 20) {
      size = "7px";
    }
    return [heading, size];
  };

  const studentClickHandler = (id, studentName) => {
    navigate({
      pathname: "/GetCourseContent/StudentReport",
      search: `id=${id}&studentName=${studentName}&courseid=${courseId}&coursename=${coursenames}&stdclass=${searchParams.get(
        "stdclass"
      )}&section=${searchParams.get("section")}`,
    });
  };

  const getScoreofAct = (activityName, student) => {
    const activityCount = student.count.find(
      (item) => item.activity === activityName
    );
    return activityCount ? activityCount.count : 0;
  };

  const getTotalActivity = (activityName) => {
    const selectedActivity = courseData.find(
      (activity) => activity.name === activityName
    );
    return selectedActivity ? selectedActivity.id.length : 0;
  };


  const scoredActivities = courseData.filter(activity => activity.scorm_type === "1");
  const nonScoredActivities = courseData.filter(activity => activity.scorm_type === "0");
  // const otherActivities = courseData.filter(activity => activity.scorm_type !== "1" && activity.scorm_type !== "0");



  return (
    <MainCard
      cardTitle={`${coursenames} ${classSection.toUpperCase()}`}
      customClass="progressCard"
      additionalData={
        progressData.length > 0 && (
          <CreatePDF
            id="reportPdf"
            fileName="progress_report"
            orientation={"landscape"}
            btnTitle={"Download Report"}
            pdfHeading={"Report"}
          />
        )
      }
    >
      <Typography className="progressHeader" variant="h6">
        Progress Report
      </Typography>

      <Scrollbar
        style={{ maxWidth: "100%", height: 600 }}
        className="customCardItem"
      >
        <Box className="scoreContainer" id="reportPdf">
          {loading ? (
            <CircularLoader />
          ) : (
            <>
              <Box className="scoreHeading scoreView">
                <Box className="rollNo" sx={{ fontSize: "12px" }}>
                  Roll No
                </Box>
                <Box className="name" sx={{ fontSize: "12px !important" }}>
                  Student Name
                </Box>
                {/* <Box>Scored Activity</Box> */}
                {courseData?.map((data, indx) => (
                  <Tooltip title={data.name} arrow placement="top" key={indx}>
                    <Box className="scoreBox" sx={{ fontSize: "12px" }}>
                      {moduleShortun(data.name)[0]}
                    </Box>
                  </Tooltip>
                ))}
              </Box>
              {progressData?.map((student, vIndex) => (
                <Box className="scoredata scoreView" key={student.id}>
                  <Box className="rollNo borderBox" pl={1}>
                    {student.roll_no}
                  </Box>
                  <Tooltip
                    title={`${student.firstname} ${student.lastname}`}
                    arrow
                    placement="right"
                  >
                    <Box
                      className="name borderBox"
                      onClick={() =>
                        studentClickHandler(
                          student.id,
                          `${student.firstname} ${student.lastname}`
                        )
                      }
                    >
                      {`${student.firstname} ${student.lastname}`}
                    </Box>
                  </Tooltip>
                  {courseData?.map((score, indx) => (
                    <Box
                      className="scoreBox borderBox"
                      key={indx}
                      sx={{ borderLeft: "1px dotted" }}
                    >
                      {getScoreofAct(score.name, student)}/
                      {getTotalActivity(score.name)}

                    </Box>
                  ))}
                </Box>
              ))}
            </>
          )}
        </Box>
        {/* <PrimaryCard>
          <SectionWiseChart/>
        </PrimaryCard>
        <SectionWiseReport/> */}
      </Scrollbar>

    </MainCard>
  );
};

export default StudentProgressDetail;
