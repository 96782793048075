import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Menu,
  MenuItem,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CgMenuGridO } from "react-icons/cg";
import { IoClose } from "react-icons/io5";
import QuickLinks from "../../_pages/QuickLinks/QuickLinks";
import { useEffect, useRef, useState } from "react";
import "./MenuLinks.scss";
import zIndex from "@mui/material/styles/zIndex";

const MenuLinks = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const mbBreakPoint = useMediaQuery("(min-width:557px)");

  return (
    <div>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        aria-label="Menu Button"
      >
        <Avatar
          sx={{
            width: 40,
            height: 40,
            backgroundColor: "secondaryColor.main",
          }}
        >
          <CgMenuGridO size={30} />
        </Avatar>
      </Button>

      <Menu
        anchorEl={anchorRef.current}
        id="accountMenu"
        open={open}
        onClose={handleClose}
        className="customDropdown menuDropdown main-menu-blk"
        props={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            minwidth: "250px",
            background: "cardColor.main",
            mt: 1.5,
            zIndex: "99999",
            "& .MuiAvatar-root": {
              width: 55,
              height: 55,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          sx: {
            left: `0 !important`,
            right: 0,
            width: "auto",
            margin: `0 auto`,
            maxWidth: "calc(75% - 32px)",
          },
        }}
      >
        {mbBreakPoint || (
          <MenuItem className="close-btn-mn-btn">
            <IoClose onClick={handleClose} color={"#fff"} />
          </MenuItem>
        )}
        <ClickAwayListener onClickAway={handleClose}>
          <QuickLinks handleClose={handleClose} />
        </ClickAwayListener>
      </Menu>
    </div>
  );
};
export default MenuLinks;
