import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  IconButton,
  TextField,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Loader from "../../../_Components/Utils/loader/Loader";
import MainCard from "../../../_Components/Common/Cards/MainCard";
import {
  CenterButtonBoxArea,
  PrimaryBgBtn,
} from "../../../_Components/Common/Buttons/CustomBtns";

function AddQuestions() {
  const token = localStorage.getItem("token");
  const [SearchParams] = useSearchParams();
  const courseID = SearchParams.get("courseID");
  const quizID = SearchParams.get("quizID");
  const questionID = SearchParams.get("questionID");
  const catID = SearchParams.get("catID");
  const categoryID = SearchParams.get("categoryID");
  const editing = SearchParams.get("editing");
  const moduleID = SearchParams.get("moduleID");
  const userId = JSON.parse(localStorage.getItem("userInfo"))[0].id;
  const [questionAdded, setQuestionAdded] = useState({
    message: "",
    status: "",
    isAdded: false,
  });
  const [select_default, setselect_default] = useState(0)
  const [questionType, setQuestionType] = useState("multichoice");

  const defaultMultiChoiceData = {
    url: `${process.env.REACT_APP_wsPrefix}/`,
    token: token,
    qtype: "multichoice",
    courseid: parseInt(courseID),

    name: "Testing question1111",
    parent: 0,
    length: 1,
    questiontext: "",
    generalfeedback: "Test genral feedbacktext first1111",
    answernumbering: "abc",
    correctfeedback: "Your answer is correct",
    partiallycorrectfeedback: "Your answer is partially correct.",
    incorrectfeedback: "Your answer is incorrect.",
    answer: ["", ""],
    fraction: ["1.0", "0.0"],
    answerfeedback: ["first", "second"],
    createdby: parseInt(userId),
    maxgrade: 2,
    quizid: quizID,
    category: catID,
  };

  const defaultMultiAnswerData = {
    url: `${process.env.REACT_APP_wsPrefix}/`,
    qtype: "multianswer",
    courseid: parseInt(courseID),
    category: catID,
    quizid: quizID,
    length: 1,
    name: "",
    parent: 0,
    questiontext: "",
    questionoption: ["", ""],
    questionanswers: ["", ""],
    generalfeedback: "Test q1 General feedback",
    usecase: 0,
    createdby: parseInt(userId),
  };

  const defaultTrueFlaseData = {
    url: `${process.env.REACT_APP_wsPrefix}/`,
    token: token,
    qtype: "truefalse",
    courseid: parseInt(courseID),
    category: catID,
    quizid: quizID,
    length: 1,
    name: "Test TF Question",
    parent: 0,
    questiontext: "",
    generalfeedback: "Test q1 General feedback",
    feedbacktrue: "Feedback for the response 'True'.",
    feedbackfalse: "Feedback for the response 'False'.",
    correctanswer: 1,
    createdby: parseInt(userId),
    maxgrade: 2,
  };
  const multichoiceOptions = ["option0", "option1"];
  const [isAddingQuestion, setIsAddingQuestion] = useState(false);
  const [multiChoiceQuestionData, setMultiChoiceQuestionData] = useState(
    defaultMultiChoiceData
  );
  const [multiAnswerQuestionData, setMultiAnswerQuestionData] = useState(
    defaultMultiAnswerData
  );

  const selectRef = useRef(null);

  const [trueFlaseQuestionData, setTrueFalseQuestionData] =
    useState(defaultTrueFlaseData);
  const [questOptions, setQuestOptions] = useState(multichoiceOptions);

  const getQuestionData = () => {
    const getQuestionDataURL = `${process.env.REACT_APP_nodewsPrefix}/questionview?questionID=${questionID}`;
    axios
      .get(getQuestionDataURL, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      })
      .then((response) => {
        // console.log(response.data.questionData.qtype, "ind124");
        console.log("inder111",response)
        setQuestionType(response.data.questionData.qtype);

        if (response.data.questionData.qtype === "multichoice") {
          let answerOption = response.data.answerData.map(
            (answer) => answer.answer
          );
          let correctAnswer = response.data.answerData.map(
            (data) => data.fraction
          );
          let optionsUpdate = response.data.answerData.map(
            (data, indx) => `option${indx}`
          );
          /*  correctAnswer.map(
             (data) => {
               if(data > 0){
                 setselect_default(parseInt(data))
               }
             }
           ) */
          let correctAnswerIndex = 0;
          response.data.answerData.forEach((data, indx) => {
            if (parseFloat(data.fraction) > 0) {
              correctAnswerIndex = indx;
            }
          });
          console.log('select_default', select_default)
          setQuestOptions(optionsUpdate);
          setMultiChoiceQuestionData((prevState) => {
            console.log(prevState, "inder1111")
            return {
              ...prevState,
              name: response.data.questionData.questionName,
              questiontext: response.data.questionData.questiontext,
              answer: answerOption,
              fraction: correctAnswer,
            };
          });
          setselect_default((prev) => correctAnswerIndex)
          if (selectRef.current) {
            selectRef.current.value = correctAnswerIndex;
          }
        }

        if (response.data.questionData.qtype === "truefalse") {
          setTrueFalseQuestionData((prevState) => {
            return {
              ...prevState,
              name: response.data.questionData.questionName,
              questiontext: response.data.questionData.questiontext
            };
          });
          let answerData = response.data.answerData
          let answ_txt = ''
          let answ_data = 0
          answerData.map((value, index, array) => {
            answ_data = parseInt(value.fraction)
            if (answ_data > 0) {
              answ_txt = value.answer
              answ_txt = answ_txt.toLowerCase()
            }
          })
          setTrueFalseQuestionData((prevState) => {
            return {
              ...prevState,
              correctanswer: (answ_txt == true) ? 1 : 0
            }
          })

          // console.log('answ_data',answ_data,'answ_txt',answ_txt)
        }
      })
      .catch((error) => {
        setQuestionAdded({
          message: "Some problem please try again",
          status: "error",
          isAdded: false,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    if (editing === "true") {
      getQuestionData();
    }
  }, [editing]);

  const nToken = localStorage.getItem("nToken");
  const addQuestionURL = `${process.env.REACT_APP_nodewsPrefix}/createQuestion`;
  const handleQuestionType = (e) => {
    setQuestionType(e.target.value);
  };

  const addQuestionHandler = (e, questionData) => {
    e.preventDefault();
    setIsAddingQuestion(true);
    if (editing === "true") {
      questionData.name = questionData.questiontext;
      console.log('questionData', questionData)
      let editData = {
        ...questionData,
        category: categoryID,
        quizid: moduleID,
        qid: questionID,
      };
      axios
        .post(addQuestionURL, editData, {
          headers: {
            Authorization: `Bearer ${nToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsAddingQuestion(false);
          console.log(response.data);
          setIsAddingQuestion(false);
          setQuestionAdded({
            message: "Question edited successfully",
            status: "success",
            isAdded: true,
          });
          setTimeout(() => {
            setQuestionAdded({ message: "", status: "", isAdded: false });
          }, 5000);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      questionData.name = questionData.questiontext;
      console.log('questionData', questionData)
      axios
        .post(addQuestionURL, questionData, {
          headers: {
            Authorization: `Bearer ${nToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data, "ind1234");
          setIsAddingQuestion(false);
          setMultiChoiceQuestionData(defaultMultiChoiceData);
          setTrueFalseQuestionData(defaultTrueFlaseData);
          setQuestionAdded({
            message: "Question added successfully",
            status: "success",
            isAdded: true,
          });
          setTimeout(() => {
            setQuestionAdded({ message: "", status: "", isAdded: false });
          }, 5000);
        })
        .catch((err) => {
          console.log(err);
          setIsAddingQuestion(false);
          setQuestionAdded({
            message: "Some problem question not added",
            status: "error",
            isAdded: false,
          });
          setTimeout(() => {
            setQuestionAdded({ message: "", status: "", isAdded: false });
          }, 5000);
        });
    }
  };

  const handleQuestionCreateInputChange = (e, qType) => {
    console.log(qType, e.target.name, e.target.value);
    if (qType === "multianswer") {
      setMultiAnswerQuestionData((prevState) => {
        return { ...prevState, [e.target.name]: e.target.value };
      });

    }

    if (qType === "multichoice") {
      setMultiChoiceQuestionData((prevState) => {
        console.log({[e.target.name]: e.target.value})
        return { ...prevState, [e.target.name]: e.target.value };
      });
      if (selectRef.current) {
        setMultiChoiceQuestionData((prevState) => {
          let answerfeed = questOptions.map((feedback) => "OK");
          console.log(answerfeed);
          let prevFraction = questOptions.map((options) => "0.0");
          prevFraction[selectRef.current.value] = "1.0";
          return {
            ...prevState,
            fraction: prevFraction,
            answerfeedback: answerfeed,
          };
        });
        // console.log('multiChoiceQuestionData',multiChoiceQuestionData)
      }
    }

    if (qType === "truefalse") {
      setTrueFalseQuestionData((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
          name: e.target.value,
        };
      });
    }
  };

  const handleQuestionCreateOptionChange = (e, qType) => {
    if (qType === "multichoice") {
      if (e.target.name.includes("option")) {
        // console.log(e.target.name.split("option")[1], e.target.value);
        let optionNumber = e.target.name.split("option")[1];

        setMultiChoiceQuestionData((prevState) => {
          console.log('multiChoiceQuestionData', selectRef.current.value)
          console.log(questOptions, "qopt");
          let prevAnswer = prevState.answer;
          prevAnswer[optionNumber] = e.target.value;
          let prevFraction = questOptions.map((options) => "0.0");
          let answerfeed = questOptions.map((feedback) => "OK");
          // prevFraction[e.target.value] = "1.0";
          if (selectRef.current) {
            prevFraction[selectRef.current.value] = "1.0";
          }
          console.log('selectRef.current.value1', prevFraction)
          return {
            ...prevState,
            answer: prevAnswer,
            fraction: prevFraction,
            answerfeedback: answerfeed,
          };
        });
      } else {
        setMultiChoiceQuestionData((prevState) => {
          let answerfeed = questOptions.map((feedback) => "OK");
          let prevFraction = questOptions.map((options) => "0.0");
          prevFraction[e.target.value] = "1.0";
          /*  if (selectRef.current) {
             console.log('selectRef.current.value2',selectRef.current.value)
             prevFraction[selectRef.current.value] = "1.0";
           } */
          return {
            ...prevState,
            fraction: prevFraction,
            answerfeedback: answerfeed,
          };
        });
      }
      setselect_default(e.target.value)
      if (selectRef.current) {
        selectRef.current.value = e.target.value;
      }
      console.log("New Selected Value:", e.target.value);
    }
  };

  const addRemoveOptions = (edit) => {
    console.log(edit, questOptions);
    if (edit === "add") {
      //console.log(edit,indx)
      setQuestOptions((prevState) => {
        return [...prevState, `option${questOptions.length}`];
      });
    } else {
      setQuestOptions((prevState) => {
        let arr = prevState.toSpliced(-1);
        return arr;
      });
    }
  };

  return (
    <MainCard
      cardTitle={
        <Box>{editing === "true" ? "Edit Question" : "Add Question"}</Box>
      }
    >
      <Box className="quizForm">
        <FormControl>
          <FormLabel id="questionType">Question Type</FormLabel>
          <RadioGroup
            aria-labelledby="questionType"
            value={questionType}
            name="qtype"
            onChange={handleQuestionType}
            row
          >
            <FormControlLabel
              value="multichoice"
              control={<Radio />}
              label="MCQ"
              disabled={isAddingQuestion || (editing === "true" ? true : false)}
            />
            <FormControlLabel
              value="truefalse"
              control={<Radio />}
              label="True false"
              disabled={isAddingQuestion || (editing === "true" ? true : false)}
            />
          </RadioGroup>
        </FormControl>

        {questionType === "multichoice" && (
          <form
            onSubmit={(e) => {
              addQuestionHandler(e, multiChoiceQuestionData);
            }}
          >
            <Grid container>
              <Grid item md={10} className="fieldWrapper">
                <TextField
                  label="Question Text"
                  name="questiontext"
                  className="inputField"
                  type="text"
                  required
                  value={multiChoiceQuestionData.questiontext}
                  autoFocus={true}
                  sx={{ width: "100%", mt: 3 }}
                  onChange={(e) => handleQuestionCreateInputChange(e, questionType)}
                  disabled={isAddingQuestion}
                />
              </Grid>
              <Grid item md={2} className="fieldWrapper">
                <TextField
                  label="Max Mark"
                  name="maxgrade"
                  className="inputField"
                  type="text"
                  required
                  value={multiChoiceQuestionData.maxgrade}
                  autoFocus={true}
                  sx={{ width: "100%", mt: 3 }}
                  onChange={(e) => handleQuestionCreateInputChange(e, questionType)}
                  disabled={isAddingQuestion}
                />
              </Grid>
            </Grid>
            <Grid container className="options">
              {questOptions.map((optionName, indx) => {
                return (
                  <>
                    <Grid
                      key={indx}
                      item
                      md={6}
                      xs={12}
                      className="fieldWrapper"
                    >
                      <TextField
                        name={optionName}
                        required
                        label={`Option ${indx + 1}`}
                        className="inputField"
                        onChange={(e) =>
                          handleQuestionCreateOptionChange(e, questionType)
                        }
                        value={multiChoiceQuestionData.answer[indx]}
                        disabled={isAddingQuestion}
                      />
                      <Box>
                        {questOptions.length - 1 === indx ? (
                          indx > 1 ? (
                            <>
                              <IconButton>
                                <RemoveCircleOutlineIcon
                                  onClick={() => {
                                    addRemoveOptions("remove", indx);
                                  }}
                                />
                              </IconButton>
                              <IconButton>
                                <AddCircleOutlineIcon
                                  onClick={() => {
                                    addRemoveOptions("add");
                                  }}
                                />
                              </IconButton>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {questOptions.length - 1 === indx && indx < 2 && (
                          <IconButton>
                            <AddCircleOutlineIcon
                              onClick={() => {
                                addRemoveOptions("add", indx);
                              }}
                            />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                  </>
                );
              })}

              {
                <FormControl fullWidth sx={{ mt: 3 }}>
                  <InputLabel id="selectCorrectAnswer">
                    Select Correct Answer
                  </InputLabel>
                  <Select
                    labelid="selectCorrectAnswer"
                    id="selectCorrectAnswer"
                    name="selectCorrectAnswer"
                    input={<OutlinedInput label="Select Correct Answer" />}
                    value={select_default}
                    inputRef={selectRef} //so that we can get default or changed selected value
                    label="Age"
                    onChange={(e) =>
                      handleQuestionCreateOptionChange(e, questionType)
                    }
                    disabled={isAddingQuestion}
                  >
                    {questOptions.map((optionName, indx) => {
                      return (
                        <MenuItem key={indx} value={indx}>
                          Option {indx + 1}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              }
            </Grid>
            {isAddingQuestion && <Loader />}
            {console.log(questionAdded, "RSPL 111")}
            <Box
              sx={{ mt: 3 }}
              className={`quizMessage ${questionAdded.status}`}
            >
              {questionAdded.message}
            </Box>
            <CenterButtonBoxArea>
              <PrimaryBgBtn type="submit" variant="contained">
                {editing === "true" ? "Edit Question" : "Save"}
              </PrimaryBgBtn>
            </CenterButtonBoxArea>
          </form>
        )}

        {questionType === "truefalse" && (
          <form
            onSubmit={(e) => {
              addQuestionHandler(e, trueFlaseQuestionData);
            }}
          >
            <TextField
              label="Question Text"
              name="questiontext"
              className="inputField"
              type="text"
              required
              value={trueFlaseQuestionData.questiontext}
              autoFocus={true}
              disabled={isAddingQuestion}
              sx={{ width: "100%", mt: 3 }}
              onChange={(e) => handleQuestionCreateInputChange(e, questionType)}
            />
            <FormControl sx={{ mt: 3 }}>
              <FormLabel id="correctAnswer">Correct Answer</FormLabel>
              <RadioGroup
                aria-labelledby="correctAnswer"
                defaultValue={trueFlaseQuestionData.correctanswer}
                name="correctanswer"
                onChange={(e) =>
                  handleQuestionCreateInputChange(e, questionType)
                }
                row
                disabled={isAddingQuestion}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="True"
                  disabled={isAddingQuestion}
                />
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="False"
                  disabled={isAddingQuestion}
                />
              </RadioGroup>
            </FormControl>
            {console.log(editing, "ind323232")}
            {isAddingQuestion && <Loader />}
            <Box
              sx={{ mt: 3 }}
              className={`quizMessage ${questionAdded.status}`}
            >
              {questionAdded.message}
            </Box>
            <CenterButtonBoxArea>
              <PrimaryBgBtn type="submit" disabled={isAddingQuestion}>
                {editing === "true" ? "Edit Question" : "Save"}
              </PrimaryBgBtn>
            </CenterButtonBoxArea>
          </form>
        )}

        {questionType === "multianswer" && (
          <form
            onSubmit={(e) => {
              addQuestionHandler(e, multiAnswerQuestionData);
            }}
          >
            <TextField
              label="Question Text"
              name="questiontext"
              className="inputField"
              type="text"
              required
              value={multiAnswerQuestionData.questiontext}
              autoFocus={true}
              disabled={isAddingQuestion}
              sx={{ width: "100%", mt: 3 }}
              onChange={(e) => handleQuestionCreateInputChange(e, questionType)}
            />
            <Grid container className="options">
              {multiAnswerQuestionData.questionanswers.map((answer, indx) => {
                return (
                  <Grid key={indx} item md={6} xs={12} className="fieldWrapper">
                    <TextField
                      name={answer}
                      required
                      label={`Option ${indx + 1}`}
                      className="inputField"
                      onChange={(e) =>
                        handleQuestionCreateOptionChange(e, questionType)
                      }
                      value={multiAnswerQuestionData.questionanswers[indx]}
                      disabled={isAddingQuestion}
                    />
                    <Box>
                      {multiAnswerQuestionData.questionanswers.length - 1 ===
                        indx ? (
                        indx > 0 ? (
                          <>
                            <RemoveCircleOutlineIcon
                              onClick={() => {
                                addRemoveOptions("remove", indx);
                              }}
                            />
                            <AddCircleOutlineIcon
                              onClick={() => {
                                addRemoveOptions("add");
                              }}
                            />
                          </>
                        ) : (
                          "4"
                        )
                      ) : (
                        "2"
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </form>
        )}
      </Box>
    </MainCard>
  );
}

export default AddQuestions;
