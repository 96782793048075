import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import "./SettingPage.scss";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { Scrollbar } from "react-scrollbars-custom";
import MainCard from "../Common/Cards/MainCard";
import { PrimaryBgBtn } from "../Common/Buttons/CustomBtns";
import CircularLoader from "../Common/Loader/CircularLoader";

const AdminPermission = () => {
  const [permissions, setPermissions] = useState();
  const nToken = localStorage.getItem("nToken");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_nodewsPrefix}/getSchoolConfigData`,
          {
            schoolcode: userinfo[0]?.institution,
          }
        );
        setPermissions(response.data.info);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchData();
  }, []);

  const handlePermissionChange = (role, section, permissionType) => {
    setPermissions((prevPermissions) => {
      const newPermissions = {
        ...prevPermissions,
        [role]: {
          ...prevPermissions[role],
          [section]: {
            ...prevPermissions[role][section],
            [permissionType]: !prevPermissions[role][section][permissionType],
          },
        },
      };
      return newPermissions;
    });
  };

  const handleFormData = async (e) => {
    e.preventDefault();
    const datas = JSON.stringify(permissions);
    try {
      await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/updateConfigData`,
        {
          permission: datas,
          schoolcode: userinfo[0]?.institution,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      alert("Updated Successfully");
    } catch (error) {
      console.error("Error updating permissions:", error);
    }
  };

  /// This function filter out all data in which canAccess permission is given by site administrator
  // function filterByCanAccessTrue(obj) {
  //   const result = {};
  //   for (const key in obj) {
  //     if (
  //       obj[key] &&
  //       typeof obj[key] === "object" &&
  //       !Array.isArray(obj[key])
  //     ) {
  //       const filteredSubObj = filterByCanAccessTrue(obj[key]);
  //       if (Object.keys(filteredSubObj).length > 0) {
  //         result[key] = filteredSubObj;
  //       }
  //     } else if (key === "canAccess" && obj[key] === true) {
  //       return obj;
  //     }
  //   }
  //   return result;
  // }

  // const filteredJSON = filterByCanAccessTrue(permissions);
  const filteredJSON = permissions;

  console.log(filteredJSON, "filteredJSON")
  return (
    <MainCard cardTitle="Set Permission" customClass="setCard">
      {!permissions ? (
        <CircularLoader />
      ) : (
        <Box className="settingCard">
          <form onSubmit={handleFormData}>
            <CardContent className="cardContent">
              <Scrollbar
                style={{ width: "100%", height: 600 }}
                className="customCardItem"
              >
                {Object.keys(filteredJSON).map((role, index) => (
                  <Accordion key={role} className="customAccordion">
                    <AccordionSummary
                      expandIcon={<GridExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography
                        variant="h6"
                        sx={{ textTransform: "capitalize" }}
                      >
                        <b>{role === "pedagogy" ? "Teacher" : role}</b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {Object.keys(filteredJSON[role]).map((section) => (
                          <Grid key={section} item md={3} sm={6} xs={12}>
                            <Box key={section} className="componentArea">
                              <Typography
                                sx={{
                                  textTransform: "capitalize",
                                  wordWrap: "break-word",
                                }}
                              >
                                <b>{section}</b>
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <label>
                                  Read:&nbsp;
                                  <input
                                    type="checkbox"
                                    checked={filteredJSON[role][section].read}
                                    onChange={() =>
                                      handlePermissionChange(
                                        role,
                                        section,
                                        "read"
                                      )
                                    }
                                  />
                                  &nbsp;&nbsp;
                                </label>
                                <label>
                                  Write:&nbsp;
                                  <input
                                    type="checkbox"
                                    checked={filteredJSON[role][section].write}
                                    onChange={() =>
                                      handlePermissionChange(
                                        role,
                                        section,
                                        "write"
                                      )
                                    }
                                  />
                                  &nbsp;&nbsp;
                                </label>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Scrollbar>
            </CardContent>
            <CardActions className="settingSave">
              <Box textAlign="center" width={"100%"}>
                <PrimaryBgBtn type="submit">Save</PrimaryBgBtn>
              </Box>
            </CardActions>
          </form>
        </Box>
      )}
    </MainCard>
  );
};

export default AdminPermission;
