import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { MenuItem, Select, FormControl, InputLabel, Typography } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchClassTeacher } from "../../../redux/apiSlice/classTeacherSlice";
import CircularLoader from "../Loader/CircularLoader";

const StdMarks = ({ userInfo }) => {
  const [selectedTerm, setSelectedTerm] = useState("");
  const [chartData, setChartData] = useState({ highScorers: {}, mediumScorers: {}, lowScorers: {} });
  const [examData, setExamData] = useState(null);
  const financialYear = localStorage.getItem("AY");
  const nToken = localStorage.getItem("nToken");
  const [enroledClass, setEnroledClass] = useState([]);
  const [classesInfo, setClassesInfo] = useState([]);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const { classTeacherData: data, loading, error } = useSelector((state) => state.classTeacher);

  useEffect(() => {
    dispatch(
      fetchClassTeacher({
        userId: userInfo[0].id,
        schoolcode: userInfo[0].institution,
        nToken: nToken,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!loading && Array.isArray(data) && data.length > 0) {
      const sortedData = [...data].sort((a, b) => a.id - b.id);
      setEnroledClass(sortedData);
      const allClasses = sortedData.map((data) => data.classsection)
      setClassesInfo(allClasses.join(','));
    }
  }, [data, loading]);

  useEffect(() => {
    if (classesInfo) {
      const stdMarksDetails = async () => {
        setLoader(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_nodewsPrefix}/getExamAverage?class=${classesInfo}&schoolcode=${userInfo[0]?.institution}&academic_year=${financialYear}`,
            {
              headers: { Authorization: `Bearer ${nToken}` },
            }
          );
          setExamData(response.data);
          console.log(response.data, "exam data");
        } catch (error) {
          console.error("API Error:", error.response ? error.response.data : error.message);
        } finally {
          setLoader(false);
        }
      };

      stdMarksDetails();
    }
  }, [classesInfo]);


  useEffect(() => {
    if (examData?.exam_average_data) {
      processChartData(selectedTerm);
    }
    if (examData && Object.keys(examData?.exam_average_data || {}).length > 0) {
      const firstClass = Object.keys(examData.exam_average_data)[0];
      const termKeys = Object.keys(examData.exam_average_data[firstClass] || {});
      if (termKeys.length > 0 && !selectedTerm) {
        setSelectedTerm(termKeys[0]);
      }
    }
  }, [selectedTerm, examData]);


  const processChartData = (term) => {
    let highScorers = {};
    let mediumScorers = {};
    let lowScorers = {};

    Object.keys(examData?.exam_average_data || {}).forEach((className) => {
      const termData = examData.exam_average_data[className]?.[term] || [];
      let high = 0,
        medium = 0,
        low = 0;

      termData.forEach((student) => {
        const marks = parseFloat(student.average_marks || "0");
        if (marks >= 80) high++;
        else if (marks >= 50) medium++;
        else low++;
      });

      highScorers[className] = high;
      mediumScorers[className] = medium;
      lowScorers[className] = low;
    });

    setChartData({ highScorers, mediumScorers, lowScorers });
  };

  const option = {
    tooltip: { trigger: "axis", axisPointer: { type: "shadow" } },
    legend: { top: 0 },
    grid: { left: "3%", right: "3%", top: "20%", bottom: "5%", containLabel: true },
    xAxis: {
      type: "category",
      data: examData?.exam_average_data
        ? Object.keys(examData.exam_average_data).map(key => key.toUpperCase())
        : [],
    },
    yAxis: {
      type: "value",
      axisLabel: { formatter: "{value}" },
      interval: 5,
    },
    series: [
      {
        name: "81-100%",
        type: "bar",
        barGap: 0,
        barWidth: 80,
        data: Object.values(chartData.highScorers || {}),
        itemStyle: { color: "#3c9f0d" },
        markPoint: {
          data: Object.values(chartData.highScorers || {}).map((value, index) => ({
            value,
            xAxis: index,
            yAxis: value,
            name: "Completed",
            symbolSize: 50,
            itemStyle: { color: "#3c9f0d" },
          })),
        },
      },
      {
        name: "50-80%",
        type: "bar",
        barGap: 0,
        barWidth: 80,
        data: Object.values(chartData.mediumScorers || {}),
        itemStyle: { color: "#fac858" },
        markPoint: {
          data: Object.values(chartData.mediumScorers || {}).map((value, index) => ({
            value,
            xAxis: index,
            yAxis: value,
            name: "Completed",
            symbolSize: 50,
            itemStyle: { color: "#fac858" },
          })),
        },
      },
      {
        name: "Below 50%",
        type: "bar",
        barGap: 0,
        barWidth: 80,
        data: Object.values(chartData.lowScorers || {}),
        itemStyle: { color: "#ff8585" },
        markPoint: {
          data: Object.values(chartData.lowScorers || {}).map((value, index) => ({
            value,
            xAxis: index,
            yAxis: value,
            name: "Completed",
            symbolSize: 50,
            itemStyle: { color: "#ff8585" },
          })),
        },
      },
    ],
  };

  return (
    <>{loader ? <CircularLoader /> :
      <>
        {Object.keys(examData?.exam_average_data || {}).length <= 0 ?
          <Typography className='commonMssgBox' sx={{ width: "250px", margin: "10px auto" }}>Report not generated.</Typography> :
          <>
            <FormControl style={{ width: 150, position: "absolute", right: "15px", top: "55px", zIndex: 1, background: "#fff", textTransform: "capitalize" }} size="small">
              <InputLabel id="demo-simple-select-label">Select Term</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedTerm}
                label="Select Term"
                onChange={(e) => setSelectedTerm(e.target.value)}
              >
                {Object.keys(examData?.exam_average_data?.[Object.keys(examData?.exam_average_data || {})[0]] || {}).map((term) => (
                  <MenuItem key={term} value={term} sx={{ textTransform: "capitalize" }}>
                    {term.replace(/(\D+)(\d+)/, "$1 $2")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {examData ? (
              <ReactEcharts option={option} style={{ height: "400px", width: "100%" }} />
            ) : (
              <CircularLoader />
            )}
          </>
        }
      </>


    }</>


  );
};

export default StdMarks;
