import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./TimeTable.scss";
import CircularLoader from "../Common/Loader/CircularLoader";
import TimeTableView from "./TimeTableView";
import { usePermissions } from "../Permission/PermissionsContext";
import CreatePDF from "../Common/pdf/CreatePDF";
import MainCard from "../Common/Cards/MainCard";
import AdminTimeTableView from "./AdminTimeTableView";

const TimeTable = () => {
  const { permissions } = usePermissions();
  const currentDate = new Date();
  const financialYear = localStorage.getItem("AY");
  const dayOfWeek = currentDate.getDay();
  const nToken = localStorage.getItem("nToken");

  const stdUser = JSON.parse(localStorage.getItem("classInfo"));
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [timeTblData, setTimeTblData] = useState("");
  const [teacherPeriod, setTeacherPeriod] = useState([]);
  const [holiday, setHoliday] = useState([]);
  const [loading, setLoading] = useState(false);
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  useEffect(() => {
    const fetchTimeTbl = async () => {
      try {
        let timeTblUrl = permissions.timetable?.write
          ? `${process.env.REACT_APP_nodewsPrefix}/viewTimeTable?teacherid=${userInfo[0]?.id}&financial_year=${financialYear}&schoolcode=${userInfo[0]?.institution}`
          : permissions.timetable?.read
          ? `${process.env.REACT_APP_nodewsPrefix}/viewTimeTable?class=${stdUser.class}&section=${stdUser.section}&financial_year=${financialYear}&schoolcode=${userInfo[0]?.institution}`
          : undefined;

        const response = await axios.get(timeTblUrl, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });
        setTimeTblData(response.data.timetable_data);
        setTeacherPeriod(response.data.period_info);
        setHoliday(response.data?.holiday);

        setLoading(true);
      } catch (error) {
        console.error("Error: ", error);
      }
    };

    fetchTimeTbl();
  }, []);

  const handleTimeTable = (data) => {
    setTimeTblData(data);
    console.log(data, "data");
  };

  const timetableLength = Object.keys(timeTblData).length > 0;
  return (
    <MainCard
      cardTitle="Timetable"
      additionalData={
        <Box display={"flex"} alignItems={"center"} gap={2}>
          {loading && timetableLength && (
            <CreatePDF
              id="pdfContent"
              fileName="Timetable"
              orientation="landscape"
              btnTitle="Download"
              pdfHeading="Timetable"
            />
          )}
          {permissions.timetable?.write &&
            !permissions.timetable?.canAccess && (
              <AdminTimeTableView onTimeTblData={handleTimeTable} />
            )}
        </Box>
      }
    >
      <Box className="customCardContent" id="pdfContent">
        {!loading ? (
          <CircularLoader />
        ) : (
          <TimeTableView
            timeTblData={timeTblData}
            teacherPeriod={teacherPeriod}
            daysOfWeek={daysOfWeek}
            dayOfWeek={dayOfWeek}
            permissions={permissions}
            holiday={holiday}
          />
        )}
      </Box>
    </MainCard>
  );
};

export default TimeTable;
