import { Box } from "@mui/material";
import axios from "axios";
import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";
import CircularLoader from "../Loader/CircularLoader";

const TeacherSyllabus = ({ userInfo }) => {
  const [syllabusData, setSyllabusData] = useState([]);
  const financialYear = localStorage.getItem("AY");
  const nToken = localStorage.getItem("nToken");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSyllabusData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_nodewsPrefix}/viewSyllabusCourseSection`,
          {
            userid: userInfo[0].id,
            financial_year: financialYear,
            schoolcode: userInfo[0].institution,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );

        console.log("API Response:", response);
        console.log("API Response Data:", response.data);

        if (response.data) {
          setSyllabusData(response.data);
        }
      } catch (error) {
        console.error("API Error:", error.response ? error.response.data : error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSyllabusData();
  }, []);


  const totalChaptersMap = {};
  (syllabusData?.course_chapter_count || []).forEach((course) => {
    totalChaptersMap[course.course] = course.chapter_count;
  });

  const completedChaptersByClass = {};
  (syllabusData?.checked_data || []).forEach((data) => {
    const key = `${data.CourseName} - ${data.class_name}`;
    completedChaptersByClass[key] =
      (completedChaptersByClass[key] || 0) + data.count;
  });

  const courseNamesWithClass = Object.keys(completedChaptersByClass);
  const completedChapters = Object.values(completedChaptersByClass);
  const totalChapters = courseNamesWithClass.map(
    (key) => totalChaptersMap[syllabusData?.checked_data.find((d) => `${d.CourseName} - ${d.class_name}` === key)?.courseid] || 0
  );
  const remainingChapters = totalChapters.map((total, index) => total - completedChapters[index]);

  const option = {
    // title: { text: "Course Progress", left: "center" },
    grid: {
      left: "3%",
      right: "3%",
      top: "20%",
      bottom: "3%",
      containLabel: true,
    },
    tooltip: { trigger: "axis", axisPointer: { type: "shadow" } },
    legend: { top: 2, data: ["Completed", "Remaining"] },
    xAxis: { type: "category", data: courseNamesWithClass },
    yAxis: { type: "value", interval: 2, },
    series: [
      {
        name: "Completed",
        type: "bar",
        stack: "total",
        barWidth: 80,
        data: completedChapters,
        itemStyle: { color: "#8B8000" },
        markPoint: {
          data: completedChapters.map((value, index) => ({
            value, 
            xAxis: index,
            yAxis: value,
            name: "Completed",
            symbolSize: 50, 
            itemStyle: { color: "#8B8000" }, 
          })),
        },
      },
      {
        name: "Remaining",
        type: "bar",
        stack: "total",
        barWidth: 80,
        data: remainingChapters,
        itemStyle: { color: "#ffecc1" },
      },
    ],
  };

  return (
    <>
      {loading ? (
        <CircularLoader />
      ) : (
        <>
          {syllabusData?.checked_data?.length > 0 ||
            syllabusData?.course_chapter_count?.length > 0 ? (
            <ReactEcharts option={option} style={{ width: "100%", height: "350px" }} />
          ) : (
            <Box display={"flex"} justifyContent="center">
              <img
                src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/Datanotfound.gif`}
                alt="No Data Available"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Box>
          )}
        </>
      )}
    </>
  );

};

export default TeacherSyllabus;


