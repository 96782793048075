import { Box, Grid, IconButton, Typography } from "@mui/material";
import CustomMenu from "../../_Components/Common/CustomMenu";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import io from "socket.io-client";
import { languages } from "../../App";
import { useContext, useEffect, useRef, useState } from "react";
import "./Notification.scss";
import { GrCertificate } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import { Scrollbar } from "react-scrollbars-custom";
import { relativeTime } from "dayjs/locale/en-gb";

const Notification = ({ triggerClose }) => {
  let curLanguage = useContext(languages);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [notification, setNotification] = useState([]);
  const navigate = useNavigate();
  let [notiId, setNotiId] = useState(0);
  let socketRef = useRef(null);
  const nToken = localStorage.getItem("nToken");
  let count = 0;
  let clname = "";

  useEffect(() => {
    if (userinfo && userinfo[0]?.id) {
      axios
        .get(
          `${process.env.REACT_APP_nodewsPrefix}/getAllNotification?studentid=${userinfo[0]?.id}`,
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        )
        .then((response) => {
          setNotification(response.data.rows);
        });

      /* axios.get(`${process.env.REACT_APP_nodewsPrefix}/getNotification?studentid=${userinfo[0]?.id}`, {
            headers: {
              Authorization: `Bearer ${nToken}`
            }
          })
        .then((response) => {
          setNotification(response.data);
        }); */

      socketRef.current = io(`${process.env.REACT_APP_nodewsPrefix}`);
      socketRef.current.on("newUserConnected", () => {
        console.log("newUserConnected..");
      });

      socketRef.current.on("onMessage", (message, callBack) => {
        console.log(message, "NNO ", notiId);
        setNotiId((notiId += 1));
      });

      return () => {
        socketRef.current.disconnect();
      };
    } else {
      localStorage.clear();
    }
  }, [notiId]);

  const getNotificationCount = () => {
    notification?.map((val, index) => {
      if (String(val.timeread) == "null") {
        count = parseInt(count);
        count += 1;
      }
    });
    return count;
  };

  const getClassname = (val) => {
    if (String(val.timeread) == "null") {
      clname = "unread";
    } else {
      clname = "";
    }
    return clname;
  };

  const navigateToComponent = (e) => {
    let component = "";
    switch (e.currentTarget.getAttribute("comp")) {
      case "certificate":
        component = "stdcertificate";
        break;
      case "calendar":
        component = "calendar";
        break;
      case "Assignments":
        component = "Assignments";
        break;
      default:
        break;
    }

    axios
      .get(
        `${process.env.REACT_APP_nodewsPrefix}/markNotificationRead?studentid=${userinfo[0].id}&notificationid=${e.currentTarget.id}`,
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      )
      .then((response) => {
        setNotiId((notiId += 1));
        navigate("/" + component);
      });
  };

  return (
    <CustomMenu
      imgName="notification.png"
      altTxt="notification"
      imgSize={40}
      tooltipTitle="Notification"
      iconClass="notification circleBtn"
      certCount={notification && getNotificationCount()}
    >
      <Box className="notificationWrapper">
        <Typography
          variant="h6"
          className="heading"
          textAlign="center"
          position={"relative"}
        >
          Notifications
          <Box
            className="close-btn-notification"
            position={"absolute"}
            top={"13px"}
            right={"12px"}
            sx={{ cursor: "pointer" }}
          >
            <IoClose onClick={triggerClose} color={"#fff"} fontSize={24} />
          </Box>
        </Typography>

        <Box className="notificationContent">
          <Scrollbar style={{ width: "100%", height: "65vh" }}>
            {notification?.length ? (
              notification?.map((val, index) => {
                return (
                  <>
                    <Box
                      onClick={navigateToComponent}
                      key={index}
                      id={val.id}
                      comp={val.component}
                      className={"notificationBox " + getClassname(val)}
                    >
                      <IconButton>
                        <GrCertificate />
                      </IconButton>
                      <Typography variant="body1">
                        {val.fullmessage} {/* {val.subject} */}
                      </Typography>
                    </Box>
                  </>
                );
              })
            ) : (
              <Box>
                <Typography textAlign="center">
                  No new notifications.
                </Typography>
              </Box>
            )}
          </Scrollbar>
        </Box>
      </Box>
    </CustomMenu>
  );
};

export default Notification;
