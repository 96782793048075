import { Box, Button, Grid, TextField } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import "./CreateQuiz.scss";
import Loader from "../../../_Components/Utils/loader/Loader";
import QuestionListing from "./questionListing";
import MainCard from "../../../_Components/Common/Cards/MainCard";
import {
  CenterButtonBoxArea,
  PrimaryBgBtn,
} from "../../../_Components/Common/Buttons/CustomBtns";

function CreateQuiz() {
  const navigate = useNavigate();
  const [SearchParams] = useSearchParams();
  const courseID = SearchParams.get("courseid");
  const section = SearchParams.get("section");
  const stdclass = SearchParams.get("stdclass");
  const moduleID = SearchParams.get("moduleID");
  const editQ = SearchParams.get("editing");
  const userId = JSON.parse(localStorage.getItem("userInfo"))[0].id;
  const token = localStorage.getItem("token");
  const nToken = localStorage.getItem("nToken");
  const [qDeleted, setQDeleted] = useState(false);
  const [isAttempted, setIsAttempted] = useState(false);

  const getQuizData = () => {
    const getQuizDataURL = `${process.env.REACT_APP_nodewsPrefix}/quizview?quizID=${moduleID}&courseID=${courseID}`;

    axios
      .get(getQuizDataURL, {
        headers: {
          Authorization: `Bearer ${nToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        let finalData = response.data.quizData_resp[0];
        console.log(finalData);

        setCreateQuizData((prevData) => {
          console.log(prevData)
          return {
            ...prevData,
            name: finalData.name,
            description: finalData.description,
            gradepass: finalData.gradepass,
            timeopen: finalData.timeopen,
            timeclose: finalData.timeclose,
            categoryID: response.data.quizCategoryID,
            timelimit: finalData.timelimit,
          };
        });
      })
      .catch((err) => {
        console.log("error");
      });
  };

  const ifQuizAttempted = () => {
    let ifAttemptedURL = `${process.env.REACT_APP_nodewsPrefix}/quizAttempt?quizID=${moduleID}`;
    axios
      .get(ifAttemptedURL, {
        headers: {
          Authorization: `Bearer ${nToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(ifAttemptedURL, "IS ATTEMPTED");
        if (response.data.quizCount > 0) {
          setIsAttempted(true);
        } else {
          setIsAttempted(false);
        }
      });
  };

  useEffect(() => {
    if (editQ === "true") {
      getQuizData();
      ifQuizAttempted();
    }
  }, [editQ]);

  const getDateFormated = () => {
    const currentDate = new Date();
    let cDate = currentDate.getDate();
    if (cDate < 10) {
      cDate = "0" + cDate;
    }
    let cMonth = currentDate.getMonth() + 1;
    if (cMonth < 10) {
      cMonth = "0" + cMonth;
    }
    let cYear = currentDate.getFullYear();
    let cHours = currentDate.getHours();
    if (cHours < 10) {
      cHours = "0" + cHours;
    }
    let cMinutes = currentDate.getMinutes();
    if (cMinutes < 10) {
      cMinutes = "0" + cMinutes;
    }
    console.log(`${cYear}-${cMonth}-${cDate}T${cHours}:${cMinutes}`);

    return `${cYear}-${cMonth}-${cDate}T${cHours}:${cMinutes}`;
  };

  const getDateforJason = (cDate) => {
    let year = cDate.split("-")[0];
    let month = cDate.split("-")[1];
    let date = cDate.split("-")[2].split("T")[0];
    let hrs = cDate.split("-")[2].split("T")[1].split(":")[0];
    let mints = cDate.split("-")[2].split("T")[1].split(":")[1];

    let fullDate = `${year}-${month}-${date} ${hrs}:${mints}`;

    return fullDate;
  };

  const defaultQuizData = {
    url: `${process.env.REACT_APP_wsPrefix}/`,
    token: token,
    name: "",
    description: "",
    questionsperpage: 1,
    shuffleanswers: 0,
    sequence: 1,
    gradepass: "",
    gradecat: 5,
    attempts: 1,
    course: parseInt(courseID),
    section: parseInt(section),
    timeopen: getDateforJason(getDateFormated()),
    timeclose: getDateforJason(getDateFormated()),
    timecreated: 1728384695,
    timelimit: "",
    timemodified: 0,
    grademethod: 2,
    userid: parseInt(userId),
    add: 0,
    feedbacktext: ["100% feedback", "100% feedback"],
    feedbackboundaries: [50, 10],
  };

  const [createQuizData, setCreateQuizData] = useState(defaultQuizData);
  const [quizCreated, setQuizCreated] = useState(false);
  const [quizLoading, setQuizLoading] = useState(false);

  const [quizMessage, setQuizMessage] = useState({});

  let newQuizID;
  let catID;

  const createQuizHandler = (e) => {
    console.log("Add question component");
    e.preventDefault();
    const createQuizURL = `${process.env.REACT_APP_nodewsPrefix}/quizcreate`;
    let open = convertToTimestamp(createQuizData.timeopen);
    let close = convertToTimestamp(createQuizData.timeclose);

    if (close < open) {
      setQuizMessage({
        message: "The close date/time should not be earlier than the open date",
        status: "error",
      });
    } else if (close === open) {
      setQuizMessage({
        message: "The close date/time should not be equal to open date",
        status: "error",
      });
    } else if (createQuizData.timelimit <= 0) {
      setQuizMessage({
        message: createQuizData.timelimit === 0 ? "Time Limit can not be 0":"Time Limit must be greater than 0",
        status: "error",
      });
    } else {
      setQuizLoading(true);
      axios
        .post(createQuizURL, createQuizData, {
          headers: {
            Authorization: `Bearer ${nToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data, "ind111");

          setQuizLoading(false);
          setQuizCreated(true);
          setQuizMessage({
            message: "Assessment created successfully",
            status: "success",
          });
          newQuizID = parseInt(response.data.quizData.quizID);
          catID = parseInt(response.data.quizData.question_categoryID);

          navigate(
            `/GetCourseContent/addquestion?quizID=${newQuizID}&catID=${catID}&courseID=${courseID}&&stdclass=${stdclass}&section=${section}`
          );
        })
        .catch((err) => {
          setQuizLoading(false);
          setQuizCreated(false);
          setQuizMessage({
            message: "Some Problem assessment not created",
            status: "error",
          });
        });
    }
  };

  function createTimestamp(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date format");
    }
    return date.getTime() / 1000;
  }

  const convertToTimestamp = (date) => {
    if (date.includes("T")) {
      return createTimestamp(date);
    } else {
      const dateArr = date.split(" ");
      dateArr.splice(1, 0, "T");
      return createTimestamp(dateArr.join(""));
    }
  };

  const editQuizHandler = (e) => {
    e.preventDefault();
    console.log(createQuizData);
    let open = convertToTimestamp(createQuizData.timeopen);
    let close = convertToTimestamp(createQuizData.timeclose);
    if (close < open) {
      setQuizMessage({
        message: "The close date/time should not be earlier than the open date",
        status: "error",
      });
    } else if (close === open) {
      setQuizMessage({
        message: "The close date/time should not be equal to open date",
        status: "error",
      });
    } else if (createQuizData.timelimit <= 0) {
      setQuizMessage({
        message: createQuizData.timelimit === 0 ? "Time Limit can not be 0":"Time Limit must be greater than 0",
        status: "error",
      });
    } else {
      setQuizLoading(true);
      axios
        .put(
          `${process.env.REACT_APP_nodewsPrefix}/quizupdate`,
          {
            url: `${process.env.REACT_APP_wsPrefix}/`,
            token: token,
            name: createQuizData.name,
            description: createQuizData.description,
            gradepass: createQuizData.gradepass,
            timeopen: open,
            timeclose: close,
            timelimit: createQuizData.timelimit,
            quizID: moduleID,
            courseID: courseID,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        )
        .then((response) => {
          setQuizLoading(false);
          setQuizMessage({
            message: "Assessment updated successfully",
            status: "success",
          });
          setTimeout(() => {
            setQuizMessage({});
          }, 3000);
        })
        .catch((error) => {
          setQuizMessage({
            message: "Some Problem assessment not updated",
            status: "error",
          });
        });
    }
  };

  const handleQuizCreateInputChange = (e) => {
    if (e.target.name === "timeclose" || e.target.name === "timeopen") {
      console.log(1);

      let getDate = getDateforJason(e.target.value);

      setCreateQuizData((prevData) => {
        return { ...prevData, [e.target.name]: getDate };
      });
    } else if (e.target.name === "timelimit") {
      console.log(1);
      setCreateQuizData((prevData) => {
        return { ...prevData, [e.target.name]: e.target.value };
      });
    } else {
      console.log(3);
      setCreateQuizData((prevData) => {
        return { ...prevData, [e.target.name]: e.target.value };
      });
    }
  };

  return (
    <MainCard
      cardTitle={
        <>{editQ === "true" ? <>Edit Assessment</> : <>Create Assessment</>}</>
      }
    >
      {isAttempted && (
        <Box sx={{ m: 3 }}>
          The student attempted this quiz and it can no longer be edited.
        </Box>
      )}
      {quizLoading ? (
        <Loader />
      ) : (
        <form onSubmit={editQ === "true" ? editQuizHandler : createQuizHandler}>
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item md={5} sx={12}>
              <TextField
                label="Assessment Name"
                labelid="assessmentname"
                id="formcode-select"
                name="name"
                required
                sx={{ width: "100%" }}
                onChange={handleQuizCreateInputChange}
                disabled={quizCreated || quizLoading || isAttempted}
                value={createQuizData.name}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                label="Assessment Description"
                labelid="assessmentdescription"
                id="formcode-select"
                name="description"
                required
                sx={{ width: "100%" }}
                onChange={handleQuizCreateInputChange}
                disabled={quizCreated || quizLoading || isAttempted}
                value={createQuizData.description}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                label="Passing Grade"
                id="formcode-select"
                name="gradepass"
                type="number"
                required
                sx={{ width: "100%" }}
                onChange={handleQuizCreateInputChange}
                disabled={quizCreated || quizLoading || isAttempted}
                value={
                  createQuizData.gradepass !== ""
                    ? Math.trunc(createQuizData.gradepass)
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {console.log(createQuizData)}
            <Grid item md={5} xs={12}>
              <TextField
                id="openthequiz"
                label="Open the quiz"
                type="datetime-local"
                name="timeopen"
                value={createQuizData.timeopen}
                sx={{ width: "100%" }}
                onChange={handleQuizCreateInputChange}
                required
                disabled={quizCreated || quizLoading || isAttempted}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                id="closethequiz"
                label="Close the quiz"
                type="datetime-local"
                name="timeclose"
                required
                sx={{ width: "100%" }}
                value={createQuizData.timeclose}
                onChange={handleQuizCreateInputChange}
                disabled={quizCreated || quizLoading || isAttempted}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                label="Time Limit (minutes)"
                id="timelimit"
                name="timelimit"
                value={createQuizData.timelimit}
                type="number"
                required
                sx={{ width: "100%" }}
                onChange={handleQuizCreateInputChange}
                disabled={quizCreated || quizLoading || isAttempted}
              />
            </Grid>
          </Grid>
          {quizMessage !== "" && (
            <Box className={`quizMessage ${quizMessage.status}`} sx={{ mt: 3 }}>
              {quizMessage.message}
            </Box>
          )}

          <CenterButtonBoxArea>
            <PrimaryBgBtn disabled={quizCreated || isAttempted} type="submit">
              {editQ === "true" ? "Save Edits" : "Create"}
            </PrimaryBgBtn>
          </CenterButtonBoxArea>
        </form>
      )}
      {console.log('createQuizData', createQuizData)}
      {editQ === "true" && !isAttempted && (
        <QuestionListing categoryID={createQuizData.categoryID} />
      )}
    </MainCard>
  );
}

export default CreateQuiz;
