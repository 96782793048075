import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';

const ChapterAccordion = ({ section }) => {
    const getStatusWithColor = (activity) => {
        const { lesson_status, attempt } = activity;
        let status;
        if (lesson_status !== undefined) {
            status = lesson_status;
        } else if (attempt !== undefined && attempt === false) {
            status = "Not Attempted";
        } else {
            status = "Unknown";
        }

        let color;
        switch (status.toLowerCase()) {
            case "complete":
            case "passed":
            case "completed":
                color = "#016501";
                break;
            case "incomplete":
                color = "#cba100";
                break;
            case "not attempted":
                color = "#a80000";
                break;
            default:
                color = "inherit";
        }
        return { status, color };
    };

    const scoredActivities = section.scorm_data.filter(
        (activity) => activity.scromtype === 1
    );
    const nonScoredActivities = section.scorm_data.filter(
        (activity) => activity.scromtype === 0
    );


    const capitalize = (str) =>
        str ? str.charAt(0).toUpperCase() + str.slice(1) : '';

    return (
        <Accordion>
            <AccordionSummary expandIcon={<GridExpandMoreIcon />} className='activityHeader '>
                <Typography >{section.section_name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Typography className='activityType' >
                            Scored Activity
                        </Typography>
                        {scoredActivities.length > 0 ?
                            <>
                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={5}>
                                        <Typography>
                                            <b>Activity</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography>
                                            <b>Score</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography className='status'>
                                            <b>Status</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {scoredActivities.map((activity, index) => (
                                    <Grid container key={index} spacing={1} sx={{ mb: 1 }}>
                                        <Grid item xs={5}>
                                            <Typography>
                                                {activity.scrom_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography>
                                                {activity.score_raw !== undefined && activity.score_max !== undefined
                                                    ? `${activity.score_raw}/${activity.score_max}`
                                                    : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography style={{ color: getStatusWithColor(activity).color }} className='status'>
                                                {getStatusWithColor(activity).status}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                            </> : (
                                <Typography className='commonMssgBox'>Activity unavailable for this chapter.</Typography>
                            )}

                    </Grid>
                    {/* Non‑Scored Activity Column */}
                    <Grid item xs={12} sm={6} md={6}>
                        <Typography className='activityType'>
                            Non‑Scored Activity
                        </Typography>
                        {nonScoredActivities.length > 0 ?
                            <>
                                <Grid container spacing={1} sx={{ mb: 1 }}>
                                    <Grid item xs={5}>
                                        <Typography>
                                            <b>Activity</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography>

                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography className='status'>
                                            <b>Status</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {nonScoredActivities.map((activity, index) => (
                                    <Grid container key={index} spacing={1} sx={{ mb: 1 }}>
                                        <Grid item xs={5}>
                                            <Typography>
                                                {activity.scrom_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {/* No score displayed for non-scored activities */}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography style={{ color: getStatusWithColor(activity).color }} className='status'>
                                                {getStatusWithColor(activity).status}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                            </> : (
                                <Typography className='commonMssgBox'>Activity unavailable for this chapter.</Typography>
                            )}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default ChapterAccordion;
