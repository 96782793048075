import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Scrollbar } from "react-scrollbars-custom";
import React from "react";
import dayjs from "dayjs";

const TimeTableView = ({ timeTblData, holiday }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const order = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const holidayDays = holiday
    ? holiday.split(", ").map((day) => day.trim())
    : [];

  const combinedArray = Array.from(
    new Set([
      ...Object.values(timeTblData)
        .flatMap((period) => Object.keys(period))
        .filter((day) => order.includes(day)),
      ...holidayDays,
    ])
  );
  const days = combinedArray.sort((a, b) => {
    return order.indexOf(a) - order.indexOf(b);
  });
  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };
  const formatPeriodName = (period) => {
    return period
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const activeDay = order[dayjs().day()];

  return (
    <TableContainer className="teacherTimeTblWrapper">
      <Scrollbar style={{ width: "100%", height: `calc(72vh - 20px)` }}>
        {isEmpty(timeTblData) ? (
          <>
            {userInfo[0].department.toLowerCase() === "admin" ? (
              <Typography
                variant="h6"
                mt={1}
                className="mssgCard"
                textAlign={"center"}
              >
                Kindly select a class and section to view the timetable.
              </Typography>
            ) : (
              <Typography
                variant="h6"
                mt={1}
                className="mssgCard"
                textAlign={"center"}
              >
                The timetable has not been assigned.
              </Typography>
            )}
          </>
        ) : (
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="timeTbl"
          >
            <TableHead>
              <TableRow>
                <TableCell>Day/Period</TableCell>
                {Object.keys(timeTblData).map((period, index) => (
                  <TableCell key={index} className="weekBox">
                    {period.includes("recess")
                      ? "Recess"
                      : formatPeriodName(period)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {days.map((day) => (
                <React.Fragment key={day}>
                  <TableRow>
                    <TableCell>{day}</TableCell>
                    {Object.keys(timeTblData).map((period, index) => (
                      <TableCell
                        key={`${period}-${day}-teacher`}
                        className={
                          activeDay
                            ? "weekBox blueShade"
                            : "weekBox yellowShade"
                        }
                      >
                        {holidayDays.includes(day) ? (
                          "Holiday"
                        ) : (
                          <>
                            <Typography className="subjTitle">
                              {period.includes("recess") && timeTblData[period]
                                ? `${timeTblData[period]} min`
                                : timeTblData[period]?.[day]?.subject_name}
                            </Typography>
                            <Typography className="subTitle">
                              {timeTblData[period][day]?.teacher_name}
                            </Typography>
                          </>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        )}
      </Scrollbar>
    </TableContainer>
  );
};

export default TimeTableView;
