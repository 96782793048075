import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { montArr } from "../../_Components/Common/MonthArray";

const MonthSelector = ({ selectedMonths, handleChange, label = "Months" }) => {
 

  return (
    
      <FormControl fullWidth>
        <InputLabel id={`${label}-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-label`}
          id={label}
          multiple
          value={selectedMonths || []}
          onChange={handleChange}
          name="month"
          label={label}
          renderValue={(selected) =>
            selected.map((index) => montArr[index - 1]).join(", ")
          }
        >
          {montArr.map((data, index) => (
            <MenuItem key={index} value={index + 1}>
              <Checkbox checked={selectedMonths?.includes(index + 1)} />
              <ListItemText primary={data} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  
  );
};

export default MonthSelector;
