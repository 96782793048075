import { Box, Card, Container, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import "./QuizAttemptReview.scss";
import { languages } from "../../App";
import OptionReview from "./OptionReview";
import ReviewQuestion from "./ReviewQuestion";
import PushPinIcon from "@mui/icons-material/PushPin";
import Quiznavigation from "./Quiznavigation";
import BackButton from "../../_Components/Common/BackBtn/BackBtn";
import { Scrollbar } from "react-scrollbars-custom";

function QuizAttemptReview() {
  let curLanguage = useContext(languages);
  let questArr = [];
  let optionArr = [];
  const location = useLocation();
  const allQuestions = location.state.attemptDetail.questions;
  console.log(allQuestions);
  
  const fullName = location.state.fullname;

  console.log("inderv1",allQuestions)

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();
    str = str.replace(/(<([^>]+)>)/gi, "");
    str = str.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, " ");
    return str;
  }

  if (allQuestions) {
    console.log("454545", allQuestions);
    allQuestions.map((question, indx) => {
      let questionText = removeTags(JSON.parse(question.html).questiontext);
      console.log("4545457", question);
      if (question.type === "multianswer") {
        let correctAnswer = JSON.parse(question.html)
          .correct_answer.split(";")
          .map((data) => data.split(":")[1]);

        let answer = JSON.parse(question.html).answer_response;
        let createQuestion = (
          <ReviewQuestion
            questionText={questionText}
            answer={answer}
            type={question.type}
            qNo={indx + 1}
            status={question.status}
            correctAnswer={correctAnswer}
            mark={question.mark}
          />
        );
        questArr.push(createQuestion);
        optionArr.push("");
      } else if (question.type === "multichoice") {
        console.log(JSON.parse(question.html), "tftftf");
        let correctAnswer = JSON.parse(question.html).correct_answer.replace(
          "\n",
          ""
        );

        let optionData = JSON.parse(question.html).answers_data;

        let tempOption = optionData.map((option) => {
          return {
            answerText: option.answer,
            isChecked: option.checked,
          }; /* <OptionReview answerText={removeTags(option.answer)} isChecked= {option.checked}/> */
        });

        let createQuestion = (
          <ReviewQuestion
            questionText={questionText}
            options={tempOption}
            type={question.type}
            qNo={indx + 1}
            status={question.status}
            correctAnswer={correctAnswer}
            mark={question.mark}
          />
        );

        questArr.push(createQuestion);
      } else if (question.type === "truefalse") {
        let correctAnswer = JSON.parse(question.html).correct_answer;
        let createQuestion = (
          <ReviewQuestion
            questionText={questionText}
            qNo={indx + 1}
            type={question.type}
            mark={question.mark}
            status={question.status}
            correctAnswer={correctAnswer}
            answerAttempt={JSON.parse(question.html).answer_response[0]}
          />
        );

        questArr.push(createQuestion);
      }
      return null;
    });

    console.log(optionArr);
  }

  let navigationData = {
    noOfQuestion: allQuestions.length,
  };
  let navigationButtons = [];

  for (let i = 0; i < navigationData.noOfQuestion; i++) {
    navigationButtons.push(
      <Quiznavigation
        qNo={i}
        from="Review"
        status={allQuestions[i].status}
        flag={allQuestions[i].flagged}
      />
    );
  }

  return (
    <Container maxWidth="xl">
      <Card
        maxWidth="xl"
        sx={{
          p: 0,
          mb: 4,
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
        }}
        elevation={5}
        className="customCard"
      >
        <Typography
          className="title"
          variant="h5"
          px={2}
          py={1}
          sx={{ borderColor: "borderLine.main", marginBottom: "30px" }}
        >
          <Box className="titleHead">
            <BackButton />
            <b>{fullName}</b>
          </Box>
        </Typography>

        <Box px={2}>
          <Scrollbar
            style={{ width: "100%", height: 671 }}
            className="customCardItem"
          >
            <Grid container spacing={2}>
              <Grid item lg={8} xs={12}>
                {questArr}
              </Grid>
              <Grid item lg={4} xs={12}>
                <Box className="Quiznavigation-inner quizReviewNavigation">
                  <Grid>
                    <Typography
                      variant="h3"
                      sx={{
                        color: "Quiznavigation.textColor",
                        borderBottom: "3px solid",
                        borderColor: "Quiznavigation.textColor",
                      }}
                    >
                      <PushPinIcon /> <span>{curLanguage.QuizNavigation}</span>
                    </Typography>
                  </Grid>
                  <Grid container className="Quiznavigation-box" gap={1}>
                    {navigationButtons}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Scrollbar>
        </Box>
      </Card>
    </Container>
  );
}

export default QuizAttemptReview;
