import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { montArr } from "../../../../_Components/Common/MonthArray";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

const ViewDiscountForm = ({ trigger, onDiscountEdit }) => {
  const [discounts, setDiscounts] = useState([]);
  const nToken = localStorage.getItem("nToken");
  const financialYear = localStorage.getItem("AY");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const fetchDiscounts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_nodewsPrefix}/viewFeeDiscount?schoolcode=${userinfo[0]?.institution}&academic_year=${financialYear}`,
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      setDiscounts(response.data.class_fee_structure);
    } catch (error) {
      console.error("Error fetching discounts:", error);
    }
  };

  useEffect(() => {
    fetchDiscounts();
  }, [trigger]);

  /** 🔹 Update State After Edit */
  const handleDiscountEdit = (data) => {
    onDiscountEdit(data);
  };

  /** 🔹 Update State After Delete (Without Refresh) */
  const handleDeleteDiscount = async (id) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/classFeeDiscountDelete`,
        { class_fee_discountID: id },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      setDiscounts((prev) => prev.filter((item) => item.class_fee_structureID !== id)); // ✅ Remove item from state
    } catch (error) {
      console.error("Error deleting discount:", error);
    }
  };

  return (
    <Box sx={{ margin: 0 }}>
      {discounts.length === 0 ? (
        <Typography>No Discounts Available</Typography>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Class</TableCell>
              <TableCell>Period</TableCell>
              <TableCell>Discount</TableCell>
              <TableCell>Months</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {discounts.map((data) => (
              <TableRow key={data.class_fee_structureID}>
                <TableCell>{data.class}</TableCell>
                <TableCell>{data.time_period}</TableCell>
                <TableCell>
                  {data.discount}
                  {data.discount_type.toLowerCase().includes("percentage") ? "%" : "Rs"}
                </TableCell>
                <TableCell>
                  {data.discount_period
                    .split(",")
                    .map((index) => montArr[index - 1])
                    .join(", ")}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDiscountEdit(data)}>
                    <FiEdit size={20} />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteDiscount(data.class_fee_structureID)}>
                    <RiDeleteBin6Line size={20} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default ViewDiscountForm;
