import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Grid,
} from "@mui/material";
import MainCard from "../../../_Components/Common/Cards/MainCard";
import { MdLibraryAddCheck } from "react-icons/md";
import axios from "axios";

const StudentMarksReport = () => {
  const [initialData, setInitialData] = useState([]);
  const [terms, setTerms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState("");
  const [showAll, setShowAll] = useState(true);
  const [remarks, setRemarks] = useState("");
  const [principalSignature, setPrincipalSignature] = useState("");
  const [teacherSignature, setTeacherSignature] = useState("");
  const [examAverages, setExamAverages] = useState({});
  const nToken = localStorage.getItem("nToken");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const classinfo = JSON.parse(localStorage.getItem("classInfo"));
 
  const financialYear = localStorage.getItem("AY");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/viewSignature?usertype=principal&schoolcode=${userinfo[0]?.institution}&academic_year=${financialYear}`;
        const response = await axios.get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });
        if (response.data && response.data.principal_signature) {
          setPrincipalSignature(response.data.principal_signature);
        }
        const data = response.data.principal_signature;
        console.log(data, "rajji");
      } catch (error) {
        console.error("Error fetching class and section data:", error);
      }
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/viewSignature?classteacher=1&schoolcode=${userinfo[0]?.institution}&academic_year=${financialYear}&classid=${classinfo.classid}`;
        const response = await axios.get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });

        const data = response.data;
        if (response.data && response.data.teacher_signature) {
          setTeacherSignature(response.data.teacher_signature);
        }
        console.log(data, "rajji");
      } catch (error) {
        console.error("Error fetching class and section data:", error);
      }
     
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getStudentMarksReports?schoolcode=${userinfo[0]?.institution}&academic_year=${financialYear}&student_id=${userinfo[0]?.id}`;
        const response = await axios.get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });

        if (response.data) {
          setRemarks(response.data.remarks);
          setExamAverages(response.data.exam_average);
          const { data, terms } = transformData(response.data.studentData);
          setInitialData(data);
          setTerms(terms);
          setSelectedTerm(terms[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const transformData = (data) => {
    const groupedData = {};
    const terms = new Set();

    data.forEach((item) => {
      if (!groupedData[item.course_name]) {
        groupedData[item.course_name] = { course_name: item.course_name };
      }
      groupedData[item.course_name][item.exam_name] = item.marks;
      terms.add(item.exam_name);
    });

    return { data: Object.values(groupedData), terms: Array.from(terms) };
  };

  const filteredData = showAll
    ? initialData
    : initialData.map((row) => ({
      course_name: row.course_name,
      marks: row[selectedTerm],
    }));

  // const reStrAverage = examAverages.map((arr) => {
  //   [arr] = arr;
  // });
  console.log(examAverages);
  // console.log(reStrAverage);
  return (
    <>
      <Box mb={2}></Box>

      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 2,
          boxShadow: 2,
          marginBottom: "35px",
        }}
      >
        <Table>
          <TableHead sx={{ backgroundColor: "#2769aa" }}>
            <TableRow>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Subject
              </TableCell>
              {showAll ? (
                terms.map((term) => (
                  <TableCell
                    key={term}
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    {term
                      .replace(/(\D)(\d)/, "$1 $2")
                      .charAt(0)
                      .toUpperCase() +
                      term.replace(/(\D)(\d)/, "$1 $2").slice(1)}{" "}
                  </TableCell>
                ))
              ) : (
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Marks
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                  borderBottom: "2px solid #2769aa",
                }}
              >
                <TableCell
                  sx={{ "&:not(:first-of-type)": { maxWidth: "60px" } }}
                >
                  {row.course_name}
                </TableCell>
                {showAll ? (
                  terms.map((term) => (
                    <TableCell key={term}>{row[term] || 0.0}</TableCell>
                  ))
                ) : (
                  <TableCell>{row.marks || 0.0}</TableCell>
                )}
              </TableRow>
            ))}
            <TableRow
              sx={{
                boxShadow: "rgb(183 209 235) 0px -50px 36px -28px inset",
                fontSize: "0.95rem",
              }}
            >
              <TableCell>
                <strong>Average Marks</strong>
              </TableCell>
              {Object.keys(examAverages).map((term) => (
                <TableCell>{examAverages[term].toFixed(2) + "%"}</TableCell>
              ))}
            </TableRow>{" "}

          </TableBody>
        </Table>
      </TableContainer>


      {/* <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid
          item
          xs={12}
          md={12}
          sx={{ maxWidth: "1000px", margin: "0 auto" }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{
              maxWidth: "max-content",
              margin: "0 auto 10px",
              fontSize: "1.2rem",
            }}
          >
            Remarks
          </Typography>
          <Typography
            sx={{
              mb: 2,
              border: "2px dotted #ffffff",
              textAlign: "center",
              background: "#a5a5a5",
              color: "#fff",
              padding: "10px 0 5px",
              maxWidth: "1000px",
              margin: "0 auto",
              width: " 100%",
            }}
          >
            {remarks || "No remarks available."}
          </Typography>
        </Grid>

      </Grid> */}
      <Grid container spacing={2} justifyContent="center">
        {terms.map((term) => (
          <Grid item key={term} xs={12} md={4}>
            <Typography variant="body1" fontWeight="bold">
              {term.replace(/(\D)(\d)/, "$1 $2").toUpperCase()} Remarks
            </Typography>
            <Typography
              sx={{
                mb: 2,
                border: "2px dotted #ffffff",
                textAlign: "center",
                background: "#a5a5a5",
                color: "#fff",
                padding: "10px 0 5px",
                width: "100%",
              }}
            >
             { console.log(remarks)}
              
              {remarks?.[term] || "No remarks available."}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          marginTop: { xs: "15px", md: "0" },
        }}
        xs={12}
        md={12}
      >
        <Grid item>
          <Typography variant="body1" fontWeight="bold">Teacher's Signature</Typography>
          <Box
            sx={{
              width: 300,
              height: 50,
              mt: 1,
              borderRadius: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: principalSignature ? "none" : "2px dashed #000",
              backgroundColor: principalSignature ? "transparent" : "#f0f0f0",
            }}
          >
            {teacherSignature ? (
              <img
                src={`${process.env.REACT_APP_nodewsPrefix}/${teacherSignature}`}
                alt="Teacher's Signature"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            ) : (
              <Typography variant="body2">No signature available</Typography>
            )}
          </Box>
          {/* <Box sx={{ width: 200, height: 50, border: "2px dashed #000", mt: 1, borderRadius: 1 }} /> */}
        </Grid>
        <Grid item>
          <Typography variant="body1" fontWeight="bold">
            {" "}
            Signature
          </Typography>
          <Box
            sx={{
              width: 200,
              height: 50,
              mt: 1,
              borderRadius: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: principalSignature ? "none" : "2px dashed #000",
              backgroundColor: principalSignature ? "transparent" : "#f0f0f0",
            }}
          >
            {principalSignature ? (
              <img
                src={`${process.env.REACT_APP_nodewsPrefix}/${principalSignature}`}
                alt="Principal's Signature"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            ) : (
              <Typography variant="body2">No signature available</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default StudentMarksReport;
