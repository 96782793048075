import React, { useState, useEffect } from "react";
import { Container, Box, Typography, Checkbox, Button, Grid, FormControl, InputLabel, OutlinedInput, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import axios from "axios";
import { CenterButtonBoxArea, PrimaryBgBtn } from "../Buttons/CustomBtns";
import MainCard from "../Cards/MainCard";

const SubjectPermission = () => {
    const [teachers, setTeachers] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState("");
    const [selectedClasses, setSelectedClasses] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [selections, setSelections] = useState([]);
    const [classes, setClasses] = useState([]);
    const userinfo = JSON.parse(localStorage.getItem("userInfo"));
    // const subjects = ["Math", "Science", "English", "History", "Physics"];
    const [subjects, setSubjects] = useState([]);
    const nToken = localStorage.getItem("nToken");
    const classinfo = JSON.parse(localStorage.getItem("classInfo"));
    useEffect(() => {
        const fetchTeachers = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_nodewsPrefix}/FetchUsers`,
                    { department: "Pedagogy" },
                    { headers: { Authorization: `Bearer ${nToken}` } }
                );
                setTeachers(response.data.records);
            } catch (error) {
                console.error("Error fetching teachers:", error);
            }
        };

        const fetchData = async () => {
            try {
                const baseURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection?schoolcode=${userinfo[0]?.institution}`;
                const response = await axios.get(baseURL, {
                    headers: {
                        Authorization: `Bearer ${nToken}`,
                    },
                });

                const data = response.data.class_section;
                console.log(data);


                setClasses([...new Set(data.map((item) => item.class))]);
            } catch (error) {
                console.error("Error fetching class and section data:", error);
            }

        };
        fetchData();

        const fetchSubjects = async () => {
            try {
                const subjectBaseURL = `${process.env.REACT_APP_nodewsPrefix}/getClassSubjects`;
                const response = await axios.post(
                    subjectBaseURL,
                    {

                        group_name: classinfo.group_prefix,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${nToken}`,
                        },
                    }
                );
                setSubjects(response.data.subject_data);
            } catch (error) {
                console.error("Error fetching subjects:", error);
            }
        }
        fetchSubjects()
        const fetchSelections = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_nodewsPrefix}/GetSelections`,
                    { headers: { Authorization: `Bearer ${nToken}` } }
                );
                setSelections(response.data.records);
            } catch (error) {
                console.error("Error fetching selections:", error);
            }
        };

        fetchTeachers();
        fetchSelections();
    }, []);

    const handleSubmit = async () => {
        try {
            await axios.post(
                `${process.env.REACT_APP_nodewsPrefix}/SubmitSelection`,
                { teacher: selectedTeacher, classes: selectedClasses, subjects: selectedSubjects },
                { headers: { Authorization: `Bearer ${nToken}` } }
            );
            alert("Selection submitted successfully!");
            setSelectedTeacher("");
            setSelectedClasses([]);
            setSelectedSubjects([]);
            const response = await axios.get(
                `${process.env.REACT_APP_nodewsPrefix}/GetSelections`,
                { headers: { Authorization: `Bearer ${nToken}` } }
            );
            setSelections(response.data.records);
        } catch (error) {
            console.error("Submission error:", error);
        }
    };

    return (
        <MainCard cardTitle="Subject Permission">


            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <FormControl size="small" fullWidth>
                        <InputLabel>Teacher</InputLabel>
                        <Select value={selectedTeacher} onChange={(e) => setSelectedTeacher(e.target.value)} label="Teacher">
                            {teachers.map((teacher) => (
                                <MenuItem key={teacher.id} value={teacher.id}>{teacher.firstname}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <FormControl size="small" fullWidth>
                        <InputLabel>Class</InputLabel>
                        <Select multiple value={selectedClasses} onChange={(e) => setSelectedClasses(e.target.value)} renderValue={(selected) => selected.join(", ")} label="Class">
                            {classes.map((cls) => (
                                <MenuItem key={cls} value={cls}>
                                    <Checkbox checked={selectedClasses.includes(cls)} />
                                    <ListItemText primary={cls} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <FormControl size="small" fullWidth>
                        <InputLabel>Subjects</InputLabel>
                        {console.log(selectedSubjects, "selectedSubjects")}
                       
                        <Select
                            multiple
                            value={selectedSubjects}
                            onChange={(e) => setSelectedSubjects(e.target.value)}
                            renderValue={(selected) =>
                                subjects
                                    .filter((subj) => selected.includes(subj.courseid))
                                    .map((subj) => subj.course_name)
                                    .join(", ")
                            }
                            label="Subjects"
                        >
                            {subjects.map((subj) => (
                                <MenuItem key={subj.courseid} value={subj.courseid}>
                                    <Checkbox checked={selectedSubjects.includes(subj.courseid)} />
                                    <ListItemText primary={subj.course_name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <CenterButtonBoxArea >
                <PrimaryBgBtn variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 3 }}>Submit</PrimaryBgBtn>
            </CenterButtonBoxArea>
            {selections.length > 0 && (
                <Box mt={3}>
                    <Typography variant="h6">Selected Assignments</Typography>
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Teacher</b></TableCell>
                                    <TableCell><b>Classes</b></TableCell>
                                    <TableCell><b>Subjects</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selections.map((entry, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{entry.teacher}</TableCell>
                                        <TableCell>{entry.classes.join(", ")}</TableCell>
                                        <TableCell>{entry.subjects.join(", ")}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}

        </MainCard>
    );
};

export default SubjectPermission;
