
import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card, CardContent, MenuItem, Select, FormControl, InputLabel, TextField, OutlinedInput } from "@mui/material";
import axios from "axios";
import MainCard from "../Cards/MainCard";
import { CenterButtonBoxArea, PrimaryBgBtn } from "../../../_Components/Common/Buttons/CustomBtns";

const InsertSignature = () => {
  const [logo, setLogo] = useState("");
  const [signature, setSignature] = useState("");
  const [logoType, setLogoType] = useState("");
  const [customLogoType, setCustomLogoType] = useState("");
  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("Something went wrong");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const nToken = localStorage.getItem("nToken");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const financialYear = localStorage.getItem("AY");
 const userinfoObject = JSON.parse(localStorage.getItem("userInfo"));
  const handleFileChange = (event, setFile) => {
    const file = event.target.files[0];
    if (file) {
      const validSize = logoType === "large" ? file.size <= 500000 : file.size <= 250000; // Large logo: 500KB, Small logo: 250KB
      if (!validSize) {
        alert(`File size too large. Max size for ${logoType} logo is ${logoType === "large" ? "500KB" : "250KB"}.`);
        return;
      }
      setFile(file);
      checkSubmitEnabled(logoType, file);
    }
  };

  const checkSubmitEnabled = (selectedLogoType, selectedFile) => {
    if (selectedLogoType && selectedFile) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const handleLogoTypeChange = (event) => {
    setLogoType(event.target.value);
    checkSubmitEnabled(event.target.value, logo);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("schoolcode", userinfo[0]?.institution);
    formData.append("academic_year", financialYear);
    formData.append("addedBy", "1");
    formData.append("principal_signature", signature);
    formData.append("school_logo", logo);
    formData.append("logo_type", logoType === "other" ? customLogoType : logoType);

    try {
      let endpoint = logoType === "other" ? customLogoType : logoType;
      if (endpoint == 'large') {
        endpoint = 'large_logo'
      }
      if (endpoint == 'small') {
        endpoint = 'small_logo'
      }
      const response = await axios.post(`${process.env.REACT_APP_nodewsPrefix}/uploadReportFiles/${endpoint}`, formData, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      });
      if (response.data.success) {
        setDialogMessage("Upload successful");
      } else {
        setDialogMessage("Something went wrong");
      }
      setOpen(true);
    } catch (error) {
      console.error("Error uploading:", error);
      setDialogMessage("Something went wrong");
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/viewSchoolConfig?schoolcode=${userinfoObject[0]?.institution}`;
        const response = await axios.get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });
        console.log(response.data);
        const school_logo = JSON.parse(response.data.config_data);
        console.log(school_logo);
        // if (response.data) {
        //   setSchoollogo(school_logo.small_logo);
        // }
      } catch (error) {
        console.error("Error fetching class and section data:", error);
      }
    };

    fetchData();
  }, [])


  return (
    <MainCard className="profilecontent" mainPage cardTitle="Upload Files" mt="15px">
      <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ flexWrap: 'nowrap' }}>
        <Grid item>
          <FormControl sx={{ width: '200px' }}>
            <InputLabel>Select Logo Type</InputLabel>
            <Select value={logoType} onChange={handleLogoTypeChange} input={<OutlinedInput label="Department" />}>
              <MenuItem value="large">Large Logo</MenuItem>
              <MenuItem value="small">Small Logo</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {logoType === "other" && (
          <Grid item>
            <TextField
              label="Custom Logo Type"
              variant="outlined"
              value={customLogoType}
              onChange={(e) => setCustomLogoType(e.target.value)}
              sx={{ width: '200px' }}
            />
          </Grid>
        )}
        <Grid item>
          <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, setLogo)} />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitDisabled}>
            Submit
          </Button>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload Status</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryBgBtn onClick={handleClose} autoFocus>
            OK
          </PrimaryBgBtn>
        </DialogActions>
      </Dialog>
    </MainCard>
  );
};
export default InsertSignature;

