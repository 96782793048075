import "./UserProfileDetails.scss";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { languages } from "../../App";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import ProfileBox from "./ProfileBox";
import ProfileImg from "./ProfileImg";
import ChangePassword from "../ChangePassword/ChangePassword";
import MainCard from "../../_Components/Common/Cards/MainCard";
import {
  CenterButtonBoxArea,
  PrimaryBgBtn,
} from "../../_Components/Common/Buttons/CustomBtns";

function UserProfileDetails() {
  let curLanguage = useContext(languages);
  const [profileInfo, setProfileInfo] = useState("");
  const [schoolName, setSchoolName] = useState(null);
  const userinfoObject = JSON.parse(localStorage.getItem("userInfo"));
  const classInfoObject = JSON.parse(localStorage.getItem("classInfo"));
  const userId = userinfoObject[0].id;
  const username = userinfoObject[0].id;
  const schholCode = userinfoObject[0].institution;
  const financialYear = localStorage.getItem("AY");
  const nToken = localStorage.getItem("nToken");
  const [saveImg, setSaveImg] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [signature, setSignature] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const teacher = userinfoObject[0].department.toLowerCase() === "pedagogy";
  const student = userinfoObject[0].department.toLowerCase() === "student";
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");


  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_nodewsPrefix}/getUserProfile?userid=${userId}`
      )
      .then((response) => {
        setProfileInfo(response.data.info);
      });

    const fetchSchoolDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_nodewsPrefix}/getAllSchools`
        );

        const selectedSchoolInfo = response.data.info.find(
          (data) => data.id === Number(userinfoObject[0]?.institution)
        );
        setSchoolName(selectedSchoolInfo);
      } catch (error) {
        console.error("Error fetching school details:", error);
      }
    };

    fetchSchoolDetails();
  }, []);

  const [profileImgInfo, setProfileImgInfo] = useState(
    userinfoObject[0]?.profileimageurl
  );
  const handleUpdateImg = ({ updatedIMG }) => {
    setProfileImgInfo(updatedIMG);
  };

  const handleImgUpload = () => {
    setSaveImg(true);
  };
  const handleEditing = () => {
    setIsEditing(false);
  };
  const department = classInfoObject.department === 'Pedagogy' ? "teacher" : classInfoObject.department;

  // const handleSignatureChange = (event) => {
  //   setSelectedFile(event.target.files[0]);
  // };
  const handleSignatureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const previewURL = URL.createObjectURL(file);
      setSelectedFile(file);
      setSignature(previewURL);  // Show preview before uploading
    }
  };
  // const handleSubmit = async () => {

  //   if (selectedFile) {
  //     const formData = new FormData();
  //     formData.append("schoolcode", schholCode);
  //     formData.append("academic_year", financialYear);
  //     formData.append("userID", userId);
  //     formData.append("addedBy", "1");
  //     if (classInfoObject.department === 'principal') {
  //       formData.append("principal_signature", selectedFile);
  //     }
  //     else {
  //       formData.append("signature", selectedFile);
  //     }


  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_nodewsPrefix}/uploadReportFiles/${department}signature`,
  //         formData, {
  //         headers: {
  //           Authorization: `Bearer ${nToken}`,
  //         },
  //       });
  //       setSignature(response.data.signatureUrl);
  //       setSelectedFile(null)
  //       setSignature(null)
  //       setDialogMessage("Signature uploaded successfully!");
  //       setOpenDialog(true);
  //     } catch (error) {
  //       console.error("Error uploading signature:", error);
  //       setDialogMessage("Error uploading signature. Please try again.");
  //       setOpenDialog(true);
  //     }
  //   }
  // };
// const handleSubmit = async () => {
//   if (!selectedFile) {
//     setDialogMessage("Please select a signature to upload.");
//     setOpenDialog(true);
//     return;
//   }

//   const formData = new FormData();
//   formData.append("schoolcode", schholCode);
//   formData.append("academic_year", financialYear);
//   formData.append("userID", userId);
//   formData.append("addedBy", "1");

//   if (classInfoObject.department === 'principal') {
//     formData.append("principal_signature", selectedFile);
//   } else {
//     formData.append("signature", selectedFile);
//   }

//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_nodewsPrefix}/uploadReportFiles/${department}signature`,
//       formData,
//       { headers: { Authorization: `Bearer ${nToken}` } }
//     );
    
//     setSignature(response.data.signatureUrl);  // Update to uploaded signature
//     setDialogMessage("Signature uploaded successfully!");
//   } catch (error) {
//     console.error("Error uploading signature:", error);
//     setDialogMessage("Error uploading signature. Please try again.");
//   }

//   setOpenDialog(true);
// };
const handleSubmit = async () => {
  if (!selectedFile) {
    setDialogMessage("Please select a signature to upload.");
    setOpenDialog(true);
    return;
  }

  const formData = new FormData();
  formData.append("schoolcode", schholCode);
  formData.append("academic_year", financialYear);
  formData.append("userID", userId);
  formData.append("addedBy", "1");

  if (classInfoObject.department === 'principal') {
    formData.append("principal_signature", selectedFile);
  } else {
    formData.append("signature", selectedFile);
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_nodewsPrefix}/uploadReportFiles/${department}signature`,
      formData,
      { headers: { Authorization: `Bearer ${nToken}` } }
    );

    setSignature(response.data.signatureUrl);  // Update to uploaded signature
    setDialogMessage("Signature uploaded successfully!");
    
    // Clear the file input and preview after successful submission
    setSelectedFile(null);
    setSignature(null);
    document.querySelector('input[type="file"]').value = "";  // Clear the file input
  } catch (error) {
    console.error("Error uploading signature:", error);
    setDialogMessage("Error uploading signature. Please try again.");
  }

  setOpenDialog(true);
};

  return (
    <Box className="profileWrapper">
      <MainCard
        className="profilecontent"
        mainPage={true}
        cardTitle="Profile"
        mt="15px"
      >
        <Box className="profileBox">
          <Grid
            container
            className="profiler"
            sx={{
              textAlign: { xs: "center", md: "left" },
              gap: { xs: "0", sm: 2 },
              flexWrap: { xs: "wrap", sm: "nowrap" },
            }}
          >
            <Grid item lg={1.4} md={1.9} sm={3} xs={12} className="imgBox">
              <ProfileImg
                isEditing={isEditing}
                profileImgInfo={profileImgInfo}
                userId={userId}
                onImgUpload={handleImgUpload}
                username={username}
                onUpdatedProfileImgUrl={handleUpdateImg}
                handleEditing={handleEditing}
              />
            </Grid>
            <Grid item lg={10.6} md={10.1} sm={9} xs={12} className="imgBox">
              <Box
                className="profileInformation"
                sx={{ textAlign: { xs: "center", sm: "left" } }}
              >
                <Typography variant="h4" className="heading">
                  {profileInfo[0]?.firstname} {profileInfo[0]?.lastname}&nbsp;
                  <span className="subHeading">
                    ({teacher ? "Teacher" : userinfoObject[0].department})
                  </span>
                </Typography>
                <Typography variant="h5">School: {schoolName?.name}</Typography>
                <Grid container mt={1}>

                  <Grid item md={4} sm={12} xs={12} className="flex-con-mb">
                    <FormLabel sx={{ paddingRight: 1 }}>Last Access:</FormLabel>
                    <Typography>
                      {profileInfo[0]?.lastaccess.replaceAll("-", " ")}
                    </Typography>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12} className="flex-con-mb">
                    <FormLabel sx={{ paddingRight: 1 }}>Joined on:</FormLabel>
                    <Typography>
                      {profileInfo[0]?.timecreated.replaceAll("-", " ")}
                    </Typography>
                  </Grid>

                  {department !== 'student' ? (
                    <Grid item xs={12} md={4}>
                      <Box className="signatureBox">
                        <Typography variant="h6">Upload Signature</Typography>
                        <input type="file" accept="image/*" onChange={handleSignatureChange} />
                        {/* {signature && <img src={signature} alt="Signature Preview" className="signaturePreview" />} */}
                        {signature && (
                          <img
                            src={signature}
                            alt="Signature Preview"
                            className="signaturePreview"
                            style={{ width: "150px", marginTop: "10px", border: "1px solid #ddd" }}
                          />
                        )}
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          sx={{ mt: 2 }}
                          disabled={!selectedFile} // Disable button if no signature is selected
                        >
                          Submit Signature
                        </Button>
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </MainCard>
      <MainCard customClass="personalinfocontent" mt="20px">
        <Box className="profileBoxsec">
          <Box p={2}>
            <Typography
              variant="h5"
              mb={2}
              paddingBottom={1}
              sx={{ borderBottom: "1px solid #d3d3d3", fontWeight: "500" }}
            >
              Personal Information
            </Typography>
            <Grid container className="profileInfo" spacing={2}>
              {student && (
                <ProfileBox>
                  <FormLabel>Roll number</FormLabel>
                  <Typography>{profileInfo[0]?.idnumber}</Typography>
                </ProfileBox>
              )}

              <ProfileBox>
                <FormLabel>{curLanguage.username}</FormLabel>
                <Typography>{userinfoObject[0].username}</Typography>
              </ProfileBox>
              <ProfileBox>
                <FormLabel>{curLanguage.Emailaddress}</FormLabel>
                <Typography>{userinfoObject[0].email}</Typography>
              </ProfileBox>

              <ProfileBox>
                <FormLabel>Gender</FormLabel>
                <Typography>
                  {profileInfo?.[0]?.gender
                    ? profileInfo[0]?.gender === "F"
                      ? "Female"
                      : profileInfo[0]?.gender === "M"
                        ? "Male"
                        : "Not Specified"
                    : "Not Specified"}
                </Typography>
              </ProfileBox>
              <ProfileBox>
                <FormLabel>Primary Contact</FormLabel>
                <Typography display={"flex"} alignItems={"center"} gap={0.5}>
                  <Box className="country-cd">+91</Box>
                  {profileInfo[0]?.phone1}
                </Typography>
              </ProfileBox>

              <ProfileBox>
                <FormLabel>Alternate Contact</FormLabel>
                <Typography>{profileInfo[0]?.phone2}</Typography>
              </ProfileBox>
              <ProfileBox>
                <FormLabel>Address</FormLabel>
                <Typography>{profileInfo[0]?.address}</Typography>
              </ProfileBox>
              <ProfileBox>
                <FormLabel>{curLanguage.Citytown}</FormLabel>
                <Typography>{profileInfo[0]?.city}</Typography>
              </ProfileBox>
              <ProfileBox>
                <FormLabel>{curLanguage.state}</FormLabel>
                <Typography>{profileInfo[0]?.state}</Typography>
              </ProfileBox>
              <ProfileBox>
                <FormLabel>{curLanguage.country}</FormLabel>
                <Typography>
                  {profileInfo[0]?.country === "IN" && "India"}
                </Typography>
              </ProfileBox>
            </Grid>
          </Box>
        </Box>
        <CenterButtonBoxArea>
          <ChangePassword />
        </CenterButtonBoxArea>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">

          <DialogTitle id="alert-dialog-title">{"Upload Status"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <PrimaryBgBtn onClick={() => setOpenDialog(false)} >
              OK
            </PrimaryBgBtn>
          </DialogActions>
        </Dialog>
      </MainCard>
    </Box>
  );
}
export default UserProfileDetails;
