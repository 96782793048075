import { Box, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import CustomSnackBar from "../../_Components/Common/SnackBar/CustomSnackBar";
import {
  CenterButtonBoxArea,
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../_Components/Common/Buttons/CustomBtns";
import { IoMdAttach } from "react-icons/io";
import { FaPencilAlt } from "react-icons/fa";


const ProfileImg = ({ userId, onUpdatedProfileImgUrl,showEditButton=true}) => {
  const [file, setFile] = useState(null);
  const [base64URL, setBase64URL] = useState("");
  const token = localStorage.getItem("token");
  const [draftItemId, setDraftItemId] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpen2, setIsDialogOpen2] = useState(false);
  const profileImg = localStorage.getItem("profileImg");
  const [hideImage, setHideImage] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const openDialog2 = () => {
    setIsDialogOpen2(true);
    setIsDialogOpen(false);
  };

  const closeDialog2 = () => {
    setIsDialogOpen2(false);
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];

    getBase64(selectedFile)
      .then((result) => {
        setFile(selectedFile);
        setBase64URL(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadFile = () => {
    if (!file || !token) {
      console.log("No file or token available");
      return;
    }
    setIsDialogOpen(false);
    setOpenSnackbar(true);
    const formData = new FormData();
    formData.append("component", "user");
    formData.append("filearea", "draft");
    formData.append("itemid", "0");
    formData.append("filepath", "/");
    formData.append("filename", file.name);
    formData.append("filecontent", base64URL.split(",")[1]);
    formData.append("contextlevel", "user");
    formData.append("instanceid", userId);

    axios
      .post(
        `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_files_upload&moodlewsrestformat=json`,
        formData
      )
      .then((response) => {
        setDraftItemId(response.data.itemid);
        updateUserProfilePicture(response.data.itemid);
        setFile(null);
        setBase64URL("");
        document.getElementById("upload-photo").value = null; // Clear the file input
        setIsDialogOpen(false);
        setOpenSnackbar(true);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const updateUserProfilePicture = (draftItemId) => {
    if (!draftItemId || !userId || !token) {
      console.log("Missing required parameters");
      return;
    }

    const formData = new FormData();
    formData.append("draftitemid", draftItemId);
    formData.append("userid", userId);

    axios
      .post(
        `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_user_update_picture&moodlewsrestformat=json`,
        formData
      )
      .then((response) => {
        console.log(
          "User profile picture updated:",
          response.data.profileimageurl
        );
        setProfileImage(response.data.profileimageurl);
        onUpdatedProfileImgUrl(response.data.profileimageurl);
        localStorage.setItem("profileImg", response.data.profileimageurl);
      })
      .catch((error) => {
        console.error("Error updating user profile picture:", error);
      });
  };

  const deleteUserProfilePicture = () => {
    const formData = new FormData();
    formData.append("draftitemid", 0);
    formData.append("userid", userId);
    formData.append("delete", 1);

    axios
      .post(
        `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_user_update_picture&moodlewsrestformat=json`,
        formData
      )
      .then((response) => {
        console.log("User profile picture updated:", response.data);
      })
      .catch((error) => {
        console.error("Error updating user profile picture:", error);
      });
    localStorage.removeItem("profileImg");

    setIsDialogOpen2(false);
    setBase64URL("");
  };
  const handleUploadArea = () => {
    setHideImage(!hideImage);
  };
  const defaultImg = `${process.env.REACT_APP_wsPrefix}/lms-assets/images/defaultuser.png`;
  const imgSrc = profileImg
    ? profileImg
    : userInfo[0].profileimageurl
    ? userInfo[0].profileimageurl
    : defaultImg;

  const handleBaseUrl = () => {
    setBase64URL("");
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
    setBase64URL("");
    setHideImage(!hideImage);
  };
  return (
    <Box>
      <Box className="profileImgSec">
        <img src={imgSrc} alt="profileImg" />
        <Box className="edit-icon-div" display={'none'}>
          <FaPencilAlt style={{fill:'white', fontSize:'12px'}}/>
        </Box>
      </Box>
      {showEditButton && (
      <Box className="edit-btn-div" mt={1} textAlign={'center'} onClick={openDialog}>
       <PrimaryBgBtn className="edit-btn-mn">Edit Profile</PrimaryBgBtn>
      </Box>
      )}

      <CustomSnackBar
        message={"Profile Image updated successfully!"}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
      />

      <CustomDialog isOpen={isDialogOpen} onClose={closeDialog} width="xs">
        <Box p={2}>
          <Box className="photoFrame">
            {!base64URL && (
              <label htmlFor="upload-photo" className="custom-file-upload">
                <IoMdAttach className="peIcon" />
                You can drag and drop files here to add them.
              </label>
            )}
            <input
              id="upload-photo"
              type="file"
              name="file"
              onChange={handleFileInputChange}
            />
            {!hideImage && (
              <Box className="activeImg">
                <img src={imgSrc} alt="profileImg" />
              </Box>
            )}
            <Box className="profileImgSec">
              {base64URL && (
                <img
                  src={base64URL}
                  alt="preview"
                  style={{ maxHeight: "200px", textAlign: "center" }}
                />
              )}
            </Box>
          </Box>

          <CenterButtonBoxArea mt={2} flexWrap={`wrap`}>
            {hideImage && (
              <PrimaryBgBtn onClick={uploadFile} disabled={!base64URL}>
                Upload
              </PrimaryBgBtn>
            )}
            {!hideImage && (
              <PrimaryBgBtn onClick={handleUploadArea}>Edit</PrimaryBgBtn>
            )}

            <PrimaryOutlineBtn
              onClick={closeDialog}
            >
              Close
            </PrimaryOutlineBtn>
            {!base64URL && imgSrc && (
              <PrimaryBgBtn onClick={openDialog2}>Delete</PrimaryBgBtn>
            )}
            {base64URL && (
              <PrimaryBgBtn onClick={handleBaseUrl}>Remove</PrimaryBgBtn>
            )}
          </CenterButtonBoxArea>
        </Box>
      </CustomDialog>

      <CustomDialog isOpen={isDialogOpen2} onClose={closeDialog2} width="xs">
        <Box textAlign="center" p={2}>
          <Typography>
            Are you sure you want to delete your profile picture?
          </Typography>
          <CenterButtonBoxArea mt={2}>
            <PrimaryBgBtn onClick={deleteUserProfilePicture}>Yes</PrimaryBgBtn>
            <PrimaryOutlineBtn onClick={closeDialog2}>No</PrimaryOutlineBtn>
          </CenterButtonBoxArea>
        </Box>
      </CustomDialog>
    </Box>
  );
};

export default ProfileImg;
