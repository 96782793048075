import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { montArr } from "../../../_Components/Common/MonthArray";
import { AiOutlineEdit } from "react-icons/ai";
import { IoTrashOutline } from "react-icons/io5";
import Scrollbar from "react-scrollbars-custom";

const ViewFeeStructure = ({ onEditData, allData, successMessage, setLoading }) => {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const nToken = localStorage.getItem("nToken");
  const financialYear = localStorage.getItem("AY");
  const [feeStructure, setFeeStructure] = useState([]);
  const [classData, setClassData] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);

  useEffect(() => {
    const baseURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection?schoolcode=${userinfo[0]?.institution}`;
    axios
      .get(baseURL, {
        headers: { Authorization: `Bearer ${nToken}` },
      })
      .then((response) => setClassData(response.data.class_section));
  }, []);

  const fetchFeeStructure = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_nodewsPrefix}/FeeStructureView?schoolcode=${userinfo[0]?.institution}&academic_year=${financialYear}&class_name=${selectedClass}`,
        {
          headers: { Authorization: `Bearer ${nToken}` },
        }
      );
      setFeeStructure(response.data.fee_strcture_data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedClass.length > 0) {
      fetchFeeStructure();
    }
  }, [selectedClass, successMessage]);

  const onHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedClass(typeof value === "string" ? value.split(",") : value);
  };

  const uniqueClassData = Array.from(
    new Map(classData.map((data) => [data.class, data])).values()
  );

  const handleEditFeeStructure = async (data, monthIndex) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_nodewsPrefix}/getFeeData?admission_no=&schoolcode=${userinfo[0]?.institution}&student_name=&class=${selectedClass}&section=&father_name=&financial_year=${financialYear}&month=1,2,3,4,5,6,7,8,9,10,11,12`,
        {
          headers: { Authorization: `Bearer ${nToken}` },
        }
      );
      onEditData(data, monthIndex, response.data, true);
    } catch (error) {
      console.error("Error fetching fee data:", error);
      setLoading(true);
    }
  };

  const handleDeleteFeeStructure = async (value) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/ClassFeeStructureDelete`,
        {
          fee_structureID: value,
        },
        {
          headers: { Authorization: `Bearer ${nToken}` },
        }
      );
      await fetchFeeStructure();
      console.log("deleted");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <FormControl sx={{ width: 250 }}>
        <InputLabel id="Selected-class-label">Class</InputLabel>
        <Select
          labelId="Selected-class-label"
          id="Selected-class"
          multiple
          value={selectedClass}
          onChange={onHandleChange}
          input={<OutlinedInput label="Class" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {uniqueClassData.map((data) => (
            <MenuItem key={data.id} value={data.class}>
              <Checkbox checked={selectedClass?.includes(data.class)} />
              <ListItemText primary={data.class} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Scrollbar style={{ width: "100%", height: 380 }}>
        <Box>
          {!selectedClass.length ? (
            <Typography>Please select a class</Typography>
          ) : feeStructure.length > 0 ? (
            <Table stickyHeader>
              <TableHead sx={{ backgroundColor: "table.headerBg" }}>
                <TableRow>
                  <TableCell>Class</TableCell>
                  <TableCell>Month</TableCell>
                  <TableCell>Details</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {feeStructure?.flatMap((row) => {
                  const feeEntries = Object.entries(row.fee_type);
                  return feeEntries.map(([month, details], index) => (
                    <TableRow key={`${row.class}-${month}`}>
                      {index === 0 ? (
                        <TableCell rowSpan={feeEntries.length}>
                          {row.class}
                        </TableCell>
                      ) : null}
                      <TableCell>{montArr[month - 1]}</TableCell>
                      <TableCell>
                        {Object.entries(details).map(([key, value]) => (
                          <Box
                            key={key}
                            sx={{ width: "200px" }}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              {key}:
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                textTransform: "capitalize",
                              }}
                            >
                              {Number(value).toLocaleString()}
                            </Typography>
                          </Box>
                        ))}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleEditFeeStructure(row, month)}
                        >
                          <AiOutlineEdit color="profileButton.main" />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteFeeStructure(row.id)}
                        >
                          <IoTrashOutline />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ));
                })}
              </TableBody>
            </Table>
          ) : (
            <Typography>Structure is not created</Typography>
          )}
        </Box>
      </Scrollbar>
    </>
  );
};

export default ViewFeeStructure;
