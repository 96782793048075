import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getFeeDataSlice = createAsyncThunk(
  "feeData/getFeeData",
  async ({
    admission_no,
    userinfo,
    student_name,
    selectedClass,
    section,
    financialYear,
    month,
    nToken,
  }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_nodewsPrefix}/getFeeData?admission_no=${admission_no}&schoolcode=${userinfo[0].institution}&student_name=${student_name}&class=${selectedClass}&section=${section}&father_name=""&financial_year=${financialYear}&month=${month}`,
      {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      }
    );
    return response.data;
  }
);

const getFeeData = createSlice({
  name: "getFeeData",
  initialState: {
    getFeeDataloading: false,
    getFeeInfoData: [],
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeeDataSlice.pending, (state) => {
        state.getFeeDataloading = true;
        state.error = null;
      })
      .addCase(getFeeDataSlice.fulfilled, (state, action) => {
        state.getFeeDataloading = false;
        state.getFeeInfoData = action.payload;
      })
      .addCase(getFeeDataSlice.rejected, (state, action) => {
        state.getFeeDataloading = false;
        state.error = action.error.message;
      });
  },
});


export default getFeeData.reducer;