import React, { useEffect } from "react";
import SimpleSlider from "./Slider/Slider";
import { Box, Container, Grid, Typography } from "@mui/material";
import "./Home.scss";
import Features from "./Features";
import FeatureCard from "../../_Components/Common/Cards/FeatureCard";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const nToken = localStorage.getItem("nToken");

  useEffect(()=>{

    if(nToken != null){
      axios.get(`${process.env.REACT_APP_nodewsPrefix}/isauthorised`,{
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      }).then((resp)=>{
        console.log(" Hooooooooooooooooooooooooooooooooooooooooo");
      })
    }

    axios.interceptors.response.use(
      (response) => {
        if (!response.config.url.includes(".php")) {
          // console.log(response.data, " 899999999999999999999999999999999999999");
          if (response.data.success != undefined && !response.data.success) {
            localStorage.clear();
            navigate("/");
            window.location.reload();
          }
        }
        return response;
      },
      (error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          // localStorage.clear();
          // navigate("/");
        }
        return Promise.reject(error);
      }
    );

  }, [])

  return (
    <Box className="homeWrapper">
      <SimpleSlider />
      <Box sx={{ backgroundColor: "#fdf5e6" }}>
        <Features />
      </Box>
      <Box className="OurVision spacing" pb={0}>
        <Container maxWidth="xl">
          <Typography variant="h2" align="center" className="title">
            Our Vision
          </Typography>
          <Grid container spacing={8} sx={{display:"flex", alignItems:'center' , flexDirection: { xs: "column-reverse", md: "row"}}}>
            <Grid item xs={12} sm={9} md={7} sx={{paddingTop: { xs: `${20}px !important`, md: 0}}}>
              <Typography >
                Our vision is to transform the learning experience by creating
                an innovative platform that seamlessly integrates e-books,
                attendance tracking, comprehensive reporting and efficient
                assignment management. We aim to empower educators and learners,
                fostering engagement, efficiency and excellence in education.
              </Typography>
            </Grid>
            <Grid item md={5} className="visionSec" sx={{marginTop: { xs: 4, md: 0}}}>
              <Box className="visionImgSec" sx={{textAlign:'center'}}>
                <img
                  src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/slider/vision.png`}
                  alt="A lady imparting knowledge to a student, while in another scene, a boy is working on a computer."
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="countSec spacing">
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xs={12} sm={6} md={3} px={1} sx={{marginBottom: { xs: 4, md: 0}}}>
              <Box className="numberSec">10K+</Box>
              <Box className="textSec">Number of Schools All Over India</Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3} px={1} sx={{marginBottom: { xs: 4, md: 0}}}>
              <Box className="numberSec">30L+</Box>
              <Box className="textSec">Number of Students</Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3} px={1} sx={{marginBottom: { xs: 4, md: 0}}}>
              <Box className="numberSec">20+</Box>
              <Box className="textSec">Year in Digital Publication </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3} px={1} sx={{marginBottom: { xs: 4, md: 0}}}>
              <Box className="numberSec">30+</Box>
              <Box className="textSec">Types of Digital Assets</Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="aimSec spacing">
        <Container maxWidth="xl">
          <Typography variant="h2" align="center" className="title">
            Our Aim
          </Typography>
          <Grid container spacing={4} mt={4}>
            <FeatureCard
              cardHeading="Empowering Educators"
              cardIcons="aimIcon1.png"
              altTxt="Empowering"
              className="aimSec" lgSize={3} mdSize={4} sxSize={6}
            >
              <Typography>
                Provide tools and resources that empower educators to create
                engaging, interactive and effective learning experiences.
              </Typography>
            </FeatureCard>

            <FeatureCard
              cardHeading="Accessible Learning"
              cardIcons="aimIcon2.png"
              altTxt="Accessible-Learning"
              className="aimSec" lgSize={3} mdSize={4} sxSize={6}
            >
              <Typography>
                Ensure accessibility for all learners, anywhere, anytime.
              </Typography>
            </FeatureCard>
            <FeatureCard
              cardHeading="Data-driven Insights"
              cardIcons="aimIcon3.png"
              altTxt="Data-driven"
              className="aimSec" lgSize={3} mdSize={4} sxSize={6}
            >
              <Typography>
                Utilize data analytics to provide actionable insights for
                educators to optimize teaching methods and improve student
                outcomes.
              </Typography>
            </FeatureCard>
            <FeatureCard
              cardHeading="Collaborative Learning"
              cardIcons="aimIcon4.png"
              altTxt="Collaborative"
              className="aimSec" lgSize={3} mdSize={4} sxSize={6}
            >
              <Typography>
                Foster a collaborative learning environment that encourages
                interaction, discussion and knowledge sharing among learners and
                educators.
              </Typography>
            </FeatureCard>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
