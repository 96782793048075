import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  CenterButtonBoxArea,
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../../../_Components/Common/Buttons/CustomBtns";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchClassSection } from "../../../../redux/apiSlice/classSectionSlice";
import CustomDialog from "../../../../_Components/Common/CustomDialog/CustomDialog";
import { montArr } from "../../../../_Components/Common/MonthArray";
import ViewDiscountForm from "./ViewDiscountForm";

const DiscountForm = () => {
  const nToken = localStorage.getItem("nToken");
  const financialYear = localStorage.getItem("AY");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [classData, setClassData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [customAlert, setCustomAlert] = useState(false);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});
  const [customDialogData, setCustomDialogData] = useState({
    titleHead: "",
    message: "",
  });

  const discount = [
    { label: "Amount (Rs)", value: "amount (Rs)" },
    { label: "Percentage (%)", value: "percentage (%)" },
  ];
  const [triggerDiscountView, setTriggerDiscountView] = useState(false);
  const [formData, setFormData] = useState({
    getMonth: "",
    selectedMonths: [],
    discount: "",
    discountType: "",
    classSec: "",
  });

  const [editFormData, setEditFormData] = useState({
    getMonth: "",
    selectedMonths: [],
    discount: "",
    discountType: "",
    classSec: "",
    discountID: "",
  });

  const dispatch = useDispatch();
  const { classSectionData, classSectionLoading } = useSelector(
    (data) => data.classSectionInfo
  );

  const handleDurationChange = (duration) => {
    console.log(duration, "duration");
    let updatedMonths = [];
    let disableCustomSelection = false;

    switch (duration) {
      case 0:
        updatedMonths = [];
        break;
      case 1:
        disableCustomSelection = true;
        break;
      case 2:
        disableCustomSelection = true;
        break;
      case 3:
        updatedMonths = montArr.map((_, index) => index + 1);
        disableCustomSelection = true;
        break;
      default:
        break;
    }

    setFormData((prev) => ({
      ...prev,
      getMonth: duration,
      selectedMonths: updatedMonths,
      disableCustomSelection,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (editing) {
      setEditFormData((prev) => ({ ...prev, [name]: value }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleAlertClose = () => {
    setCustomAlert(false);
  };
  useEffect(() => {
    if (!classSectionLoading) {
      dispatch(
        fetchClassSection({
          userinfo,
          nToken,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!classSectionLoading && classSectionData?.length > 0) {
      setClassData(classSectionData);
    }
  }, [classSectionLoading, classSectionData]);

  useEffect(() => {
    const getMonth = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_nodewsPrefix}/getFeeperiod`,
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );
        setMonthData(response.data.feePeriodData);
      } catch (error) {
        console.log(error);
      }
    };
    getMonth();
  }, [nToken]);

  const validation = () => {
    let errors = {};
    if (!formData.getMonth) {
      errors.getMonth = "Please select a month";
    }
    if (!formData.classSec) {
      errors.classSec = "Please select a class";
    }
    if (!formData.discount) {
      errors.discount = "Please enter a discount";
    }
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormData = async (e) => {
    e.preventDefault();

    if (validation()) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_nodewsPrefix}/classFeeDiscountAdd`,
          {
            discount: formData.discount,
            fee_period_id: formData.getMonth,
            discount_type: formData.discountType,
            class_name: formData.classSec,
            added_by: userinfo[0].id,
            academic_year: financialYear,
            schoolcode: userinfo[0].institution,
            discount_period: formData.selectedMonths.join(","),
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );
        const successMessage = response?.data?.message;
        setCustomDialogData({
          titleHead: response?.data?.error ? "Failed" : "Success",
          message: successMessage,
        });
        setCustomAlert(true);
        setFormData({
          getMonth: "",
          selectedMonths: [],
          discount: "",
          discountType: "",
          classSec: "",
        });
        if (customDialogData.message) {
          setTriggerDiscountView(true);
        }
      } catch (error) {
        console.error("Error adding fee structure:", error);
      }
    }
  };

  const handleEditFormData = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_nodewsPrefix}/classFeeDiscountUpdate`,
        {
          class_fee_discountID: editFormData.discountID,
          class_name: editFormData.classSec,
          discount: editFormData.discount,
          discount_type: editFormData.discountType,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      const successMessage = response?.data?.message;
      setCustomDialogData({
        titleHead: response?.data?.error ? "Failed" : "Success",
        message: successMessage,
      });
      setEditFormData({
        getMonth: "",
        selectedMonths: [],
        discount: "",
        discountType: "",
        classSec: "",
      });
      if (customDialogData.message) {
        setTriggerDiscountView(true);
      }
    } catch (error) {
      console.error("Error editing fee structure:", error);
    }
  };


  const isFormEmpty =
    !formData.getMonth ||
    !formData.discount ||
    !formData.classSec ||
    (formData.selectedMonths.length === 0 && formData.getMonth !== 3);

  const getDiscountEdit = (editedData) => {
    console.log(editedData, "editedData");
    setEditFormData({
      getMonth: editedData.fee_period_id,
      selectedMonths: editedData.discount_period,
      discount: editedData.discount,
      discountType: editedData.discount_type,
      classSec: editedData.class,
      discountID: editedData.class_fee_structureID,
    });
    setEditing(true);
  };


  const handleResetData = () => {
    console.log("handle rest data")
    setEditFormData({
      getMonth: "",
      selectedMonths: [],
      discount: "",
      discountType: "",
      classSec: "",
      discountID: "",
    });
    setFormData({
      getMonth: "",
      selectedMonths: [],
      discount: "",
      discountType: "",
      classSec: "",
    })
  }

  return (
    <>
      <Box
        component={"form"}
        onSubmit={editing ? handleEditFormData : handleFormData}
        sx={{ padding: 0 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="classSecLabel">Class</InputLabel>
              <Select
                labelId="classSecLabel"
                id="classSec"
                value={formData.classSec || editFormData.classSec}
                label="Class"
                name="classSec"
                onChange={handleChange}
                disabled={editing}
              >
                {Array.from(
                  new Set(
                    classData.map((obj) => obj.class).sort((a, b) => a - b)
                  )
                ).map((data) => (
                  <MenuItem key={data} value={data}>
                    Class {data}
                  </MenuItem>
                ))}
              </Select>
              {error.classSec && (
                <Typography color="error">{error.classSec}</Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel id="duration-label">Duration</InputLabel>
              <Select
                labelId="duration-label"
                id="duration"
                value={formData.getMonth || editFormData.getMonth}
                label="Duration"
                name="getMonth"
                onChange={(event) => {
                  handleChange(event);
                  handleDurationChange(event.target.value);
                }}
                disabled={editing}
              >
                {monthData.map((data) => (
                  <MenuItem key={data.id} value={data.id}>
                    {data.time_period}
                  </MenuItem>
                ))}
              </Select>
              {error.getMonth && (
                <Typography color="error">{error.getMonth}</Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel id="Months-label">Months</InputLabel>
              <Select
                disabled={editing}
                labelId="Months-label"
                id="Months"
                multiple
                value={
                  formData.selectedMonths || editFormData.selectedMonths
                }
                onChange={(event) => {
                  const { value } = event.target;
                  let updatedMonths = value;

                  if (formData.getMonth === 1 && value.length > 0) {
                    const startMonth = Math.min(...value);
                    updatedMonths = [
                      startMonth,
                      startMonth + 1,
                      startMonth + 2,
                    ].filter((month) => month <= 12);
                  } else if (formData.getMonth === 2 && value.length > 0) {
                    const startMonth = Math.min(...value);
                    updatedMonths = [
                      startMonth,
                      startMonth + 1,
                      startMonth + 2,
                      startMonth + 3,
                      startMonth + 4,
                      startMonth + 5,
                    ].filter((month) => month <= 12);
                  } else if (formData.getMonth === 3) {
                    updatedMonths = montArr.map((_, index) => index + 1);
                  }

                  setFormData((prev) => ({
                    ...prev,
                    selectedMonths: updatedMonths,
                  }));
                }}
                name="selectedMonths"
                label="Months"
                renderValue={(selected) =>
                  selected.map((index) => montArr[index - 1]).join(", ")
                }
              >
                {montArr.map((data, index) => {
                  const monthIndex = index + 1;
                  const isDisabled =
                    formData.getMonth === "quarter" ||
                      formData.getMonth === "half yearly"
                      ? formData.selectedMonths.length > 0 &&
                      !formData.selectedMonths.includes(monthIndex) &&
                      !formData.selectedMonths.some(
                        (selected) =>
                          monthIndex >= selected &&
                          monthIndex <
                          selected +
                          (formData.getMonth === "quarter" ? 3 : 6)
                      )
                      : formData.getMonth === "annually";

                  return (
                    <MenuItem
                      key={monthIndex}
                      value={monthIndex}
                      disabled={isDisabled}
                    >
                      <Checkbox
                        checked={formData.selectedMonths?.includes(monthIndex)}
                      />
                      <ListItemText primary={data} />
                    </MenuItem>
                  );
                })}
              </Select>
              {error.selectedMonths && (
                <Typography color="error">{error.selectedMonths}</Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel id="discountType-label">Discounts Type</InputLabel>
              <Select
                labelId="discountType-label"
                id="discountType"
                name="discountType"
                label="Discounts Type"
                value={editing ? editFormData.discountType : formData.discountType}
                onChange={handleChange}
              >
                {discount.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
              {error.discountType && <Typography color="error">{error.discountType}</Typography>}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <TextField
                id="discount"
                label="Discount"
                variant="outlined"
                name="discount"
                type="number"
                value={editing ? editFormData.discount : formData.discount}
                onChange={handleChange}
              />
              {error.discount && <Typography color="error">{error.discount}</Typography>}
            </FormControl>
          </Grid>
        </Grid>

        <CenterButtonBoxArea mt={2}>
          <PrimaryOutlineBtn onClick={handleResetData} disabled={!editing}>
            Reset
          </PrimaryOutlineBtn>
          <PrimaryBgBtn
            type="submit"
            disabled={!editing ? isFormEmpty : (
              !editFormData.getMonth ||
              !editFormData.discount ||
              !editFormData.classSec ||
              (editFormData.selectedMonths.length === 0 && editFormData.getMonth !== 3)
            )}>
            Submit
          </PrimaryBgBtn>
        </CenterButtonBoxArea>
      </Box>

      <CustomDialog
        isOpen={customAlert}
        cardTitle={customDialogData.titleHead}
        onClose={handleAlertClose}
      >
        <Box p={2} textAlign={"center"}>
          {customDialogData.message}
        </Box>
      </CustomDialog>
      <ViewDiscountForm
        trigger={triggerDiscountView}
        onDiscountEdit={getDiscountEdit}
      />
    </>
  );
};

export default DiscountForm;
