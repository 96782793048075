export default function ProcessText(text) {
  const lastUnderscoreIndex = text.lastIndexOf('_');
  const numberAfterUnderscore = text.slice(lastUnderscoreIndex + 1);
  const number = parseInt(numberAfterUnderscore.charAt(0)); 
  const secondUnderscoreIndex = text.indexOf('_', text.indexOf('_') + 1);
  const extractedText = text.slice(secondUnderscoreIndex + 1, lastUnderscoreIndex).trim();
  console.log(extractedText, "text")
  return {
    number: number,
    text: extractedText
  };
}
