import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import "./FeeStatus.scss";
import { useState } from "react";
// import Popupclose from "../../_Components/Utils/Popup/PopupClose";
import axios from "axios";
import { montArr } from "../../_Components/Common/MonthArray";
import { PrimaryBgBtn } from "../../_Components/Common/Buttons/CustomBtns";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import Scrollbar from "react-scrollbars-custom";

const FeeInfo = ({ data, curLanguage, userinfo, fetchFeeData }) => {
  let feeData = data;
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const nToken = localStorage.getItem("nToken");
  const [monthFee, setMonthFee] = useState([]);
  const financialYear = localStorage.getItem("AY");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState({});
  const [feeData1, setFeeData] = useState(data);


  const handleClose = () => {
    setOpen(false);
    setOpen1(false);
  };

  const handlePayment = async (
    userAdmissionNo,
    stdName,
    stdClass,
    paymentMonth,
    totalAmount
  ) => {
    setOpen1(false); // ✅ Close popup immediately

    // ✅ Update feeData locally (force UI update)
    const updatedFeeData = { ...feeData };
    if (!updatedFeeData.paymentData[userAdmissionNo]) {
      updatedFeeData.paymentData[userAdmissionNo] = {};
    }
    updatedFeeData.paymentData[userAdmissionNo][paymentMonth] = { status: 1 };

    // ✅ Force React to re-render
    setFeeData(updatedFeeData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/insertFeePayment`,
        {
          class_fee_discount_id: 1,
          months: String(paymentMonth + 1),
          payment_log: { payment_method: "paypal" },
          status: 1,
          amount: totalAmount,
          added_by: userinfo[0].id,
          academic_year: financialYear,
          schoolcode: userinfo[0].institution,
          studentid: userAdmissionNo,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );

      if (response.status === 200) {
        await fetchFeeData(); // ✅ Fetch updated status from the backend
      }
    } catch (error) {
      console.error("Payment Error:", error);
    }
  };




  const getMonthName = (monthNumber) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[monthNumber - 1];
  };
  const monthNames = Object.keys(monthFee).map((monthNumber) =>
    getMonthName(monthNumber)
  );

  // const selectedMonths = searchCriteria.month || [];
  // const matchingDiscount = feeData.discountData.find((discount) => {
  //   const discountMonths = discount.discount_period
  //     .split(",")
  //     .map((m) => Number(m.trim()));
  //   return (
  //     selectedMonths.length === discountMonths.length &&
  //     selectedMonths.every((month) => discountMonths.includes(month))
  //   );
  // });

  return (
    <Box className="feeInfo" mt={3}>


      {Object.keys(feeData.feetypeData).length > 0 ?

        <>
          <Typography variant="h6">
            {curLanguage.fees} {curLanguage.status}
          </Typography>
          <TableContainer className="customTable">
            <Scrollbar style={{ width: "100%", height: `calc(57vh - 50px)` }}>
              <Table sx={{ minWidth: 700 }} stickyHeader>
                <TableHead sx={{ backgroundColor: "table.headerBg" }}>
                  <TableRow>
                    <TableCell sx={{ color: "table.contrast" }}>Sr.No</TableCell>
                    <TableCell sx={{ color: "table.contrast" }}>
                      Admission No
                    </TableCell>
                    <TableCell sx={{ color: "table.contrast" }} align="left">
                      Name
                    </TableCell>
                    <TableCell sx={{ color: "table.contrast" }} align="left">
                      Class & Sec
                    </TableCell>
                    {/* <TableCell sx={{ color: "table.contrast" }} align="left">
                Father Name
              </TableCell> */}
                    <TableCell sx={{ color: "table.contrast" }} align="left">
                      Month
                    </TableCell>
                    <TableCell sx={{ color: "table.contrast" }} align="left">
                      Amount
                    </TableCell>
                    <TableCell sx={{ color: "table.contrast" }} align="left">
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {feeData?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No Data Available
                      </TableCell>
                    </TableRow>
                  ) : (
                    feeData.users.map((user, index) =>
                      Object.keys(feeData.feetypeData).map((feeKey, feeIndex) => {
                        const paymentRecord =
                          feeData.paymentData[user.student_id]?.[feeKey];
                        const isPaid = paymentRecord?.status === 1;
                        const totalAmount = Object.values(
                          feeData.feetypeData[feeKey]
                        ).reduce((sum, amount) => sum + Number(amount), 0);
                        return (
                          <TableRow key={`${index}-${feeIndex}`}>
                            {feeIndex === 0 && (
                              <>
                                <TableCell
                                  rowSpan={Object.keys(feeData.feetypeData).length}
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  rowSpan={Object.keys(feeData.feetypeData).length}
                                >
                                  {user.admission_no}
                                </TableCell>
                                <TableCell
                                  rowSpan={Object.keys(feeData.feetypeData).length}
                                >
                                  {user.name}
                                </TableCell>
                                <TableCell
                                  rowSpan={Object.keys(feeData.feetypeData).length}
                                >
                                  {user.class_name}
                                </TableCell>
                                {/* <TableCell
                          rowSpan={Object.keys(feeData.feetypeData).length}
                        >
                          {user.father_name || "N/A"}
                        </TableCell> */}
                              </>
                            )}
                            <TableCell>{montArr[feeIndex]}</TableCell>
                            <TableCell>
                              {Object.entries(feeData.feetypeData[feeKey]).map(
                                ([feeType, amount]) => (
                                  <Box
                                    key={feeType}
                                    sx={{ width: "200px" }}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {feeType}:
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {Number(amount).toLocaleString()}
                                    </Typography>
                                  </Box>
                                )
                              )}
                              <Box
                                sx={{
                                  width: "200px",
                                  borderTop: "1px solid #ddd",
                                  mt: 1,
                                  pt: 1,
                                }}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}>
                                <Typography
                                  sx={{ fontSize: "14px", fontWeight: "500" }}
                                >
                                  Total
                                </Typography>
                                <Typography sx={{ fontSize: "14px" }}>
                                  {totalAmount.toLocaleString()}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              {console.log(isPaid, "ffffffffffffff")}
                              {/* {feeData.paymentData[user.student_id]?.[feeIndex]?.status === 1 ? ( */}
                              {isPaid ?
                                <Typography className="status success">Paid</Typography>
                                : (
                                  <PrimaryBgBtn
                                    onClick={() => {
                                      const paymentMonthKey = feeKey; // Ensure the correct key is used

                                      const feeDetails = feeData.feetypeData[paymentMonthKey] || {}; // Get data properly
                                      const tuitionFee = feeDetails["Tuition Fee"] || 0;
                                      const academicFee = feeDetails["Academic Fee"] || 0;
                                      const annualFee = feeDetails["Annual Fee"] || 0;
                                      setSelectedPayment({

                                        userAdmissionNo: user.admission_no,
                                        stdName: user.name,
                                        stdClass: user.class_name,
                                        paymentMonth: feeIndex,
                                        tuitionFee,
                                        academicFee,
                                        annualFee,  
                                        
                                        totalAmount: totalAmount,
                                      });
                                      setOpen1(true);
                                    }}
                                    className="status failed"
                                  >
                                    Not Paid
                                  </PrimaryBgBtn>
                                )}
                            </TableCell>

                          </TableRow>
                        );
                      })
                    )
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
        </>
        :
        <Typography>
          No record found
        </Typography>
      }
      {/* dialog for all month  */}

      <CustomDialog isOpen={open} onClose={handleClose}>
        <TableContainer className="customTable dilogTbl">
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ backgroundColor: "table.headerBg" }}>
              <TableRow>
                <TableCell sx={{ color: "table.contrast", minWidth: "180px" }}>
                  Month
                </TableCell>
                <TableCell sx={{ color: "table.contrast" }} align="left">
                  <TableRow className="insideRow">
                    <TableCell sx={{ color: "table.contrast" }} align="left">
                      Time Period
                    </TableCell>
                    <TableCell sx={{ color: "table.contrast" }} align="left">
                      Amount
                    </TableCell>
                    <TableCell sx={{ color: "table.contrast" }} align="left">
                      Status
                    </TableCell>
                  </TableRow>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(monthFee).map((month, i) => (
                <TableRow key={month}>
                  <TableCell sx={{ color: "cardColor.contrast" }}>
                    <h3>{monthNames[i]}</h3>
                  </TableCell>
                  <TableCell sx={{ color: "cardColor.contrast" }}>
                    {Array.isArray(monthFee[month].amount) ? (
                      monthFee[month].amount.map((amount, i) => (
                        <TableRow key={i} className="insideRow">
                          <TableCell sx={{ color: "cardColor.contrast" }}>
                            {monthFee[month].time_period[i]}
                          </TableCell>
                          <TableCell sx={{ color: "cardColor.contrast" }}>
                            ₹{amount}
                          </TableCell>
                          <TableCell sx={{ color: "cardColor.contrast" }}>
                            {monthFee[month].paid_status[i]}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <Typography style={{ textAlign: "center" }}>
                        <small> No Data found</small>
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomDialog>
      {/* <CustomDialog isOpen={open1} onClose={handleClose}>
        <Box textAlign={"Center"} p={2}>
          <Typography>Payment Done</Typography>
        </Box>
      </CustomDialog> */}
      <CustomDialog isOpen={open1} onClose={handleClose}>
  <Box textAlign="center" p={2}>
    <Typography>Month: {montArr[selectedPayment?.paymentMonth] || "N/A"}</Typography>
    {console.log("Selected Month Fee Data:", feeData.feetypeData[selectedPayment?.paymentMonth] || {})}

    {/* ✅ Fetch and display only allowed fees */}
    {Object.entries(feeData.feetypeData[selectedPayment?.paymentMonth] || {})
      .filter(([feeType]) => 
        !["Tuition Fee", "Academic Fee", "Annual Fee"].includes(feeType) // ✅ Exclude specific fee types
      )
      .map(([feeType, amount]) => {
        console.log(`Fee Type: ${feeType}, Amount: ₹${Number(amount).toLocaleString()}`);
        return (
          <Typography key={feeType}>
            {feeType}: ₹{Number(amount).toLocaleString()}
          </Typography>
        );
      })}


    <Typography>Total Amount: ₹{selectedPayment?.totalAmount?.toLocaleString() ?? "0"}</Typography>

    <Box mt={2}>
      <PrimaryBgBtn
        onClick={async () => {
          setFeeData((prevData) => {
            const updatedData = { ...prevData, paymentData: { ...prevData.paymentData } };

            if (!updatedData.paymentData[selectedPayment.userAdmissionNo]) {
              updatedData.paymentData[selectedPayment.userAdmissionNo] = {};
            }

            updatedData.paymentData[selectedPayment.userAdmissionNo] = {
              ...updatedData.paymentData[selectedPayment.userAdmissionNo],
              [selectedPayment.paymentMonth]: { status: 1 },
            };

            return updatedData; // ✅ Ensures React updates state correctly
          });

          setOpen1(false); // ✅ Close modal immediately

          await handlePayment(
            selectedPayment.userAdmissionNo,
            selectedPayment.stdName,
            selectedPayment.stdClass,
            selectedPayment.paymentMonth,
            selectedPayment.totalAmount
          );
        }}
      >
        OK
      </PrimaryBgBtn>
    </Box>
  </Box>
</CustomDialog>








    </Box>
  );
};

export default FeeInfo;
