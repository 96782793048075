import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { PrimaryBgBtn, SecondaryOutlineBtn } from "../../../_Components/Common/Buttons/CustomBtns";
import Loader from "../../../_Components/Utils/loader/Loader";
import "./StudentList.scss"
import { MdLockOpen, MdLockOutline } from "react-icons/md";

const StudentList = (props) => {
  const recive = props.onSaveSuccess
  console.log(recive);

  const reciveSdata = props.studentData;
  const class_section = props.class_section;
  console.log(reciveSdata);


  const classReceive = class_section[0].stdClass;
  const SectonReceive = class_section[0].stdsection;
  const academic_year = props.academic_year;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const nToken = localStorage.getItem("nToken");
  const token = localStorage.getItem("nToken") || "";
  const financialYear = localStorage.getItem("AY");
  const [students, setStudents] = useState(reciveSdata);
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isLoader, setIsLoader] = useState(false)
  const department = userinfo[0]?.department === "Pedagogy" ? "teacher" : 'admin'
  const [isEditing, setIsEditing] = useState(false);
  const schoolCode = userinfo[0].institution
  const [currentTerm, setCurrentTerm] = useState(null);
  const [editableTerm, setEditableTerm] = useState(null);



  const handleOpen = (student) => {
    setSelectedStudent(student);
    setOpen(true);
  };
  const handleEditClick = () => {
    setIsEditing(true);
    setCurrentTerm(selectedStudent?.active_exam_number); // Set the current term
  };


  const toggleEdit = (term) => {
    setEditableTerm((prev) => (prev === term ? null : term));
  };


  console.log(selectedStudent?.active_exam_number, "setIsEditingff");
  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
    setSelectedStudent(null);
    setEditableTerm(null)
  };

  const handleMarksChange = (term, subjectId, newMarks) => {
    const marks = Number(newMarks);
    if (marks < 0 || marks > 100) {
      alert("Marks should be between 0 and 100");
      return;
    }
    setSelectedStudent((prevStudent) => {
      const updatedGrades = { ...prevStudent.grades };
      updatedGrades[term] = updatedGrades[term].map((subject) =>
        subject.subjectid === subjectId
          ? { ...subject, subject_marks: Number(newMarks) }
          : subject
      );

      return { ...prevStudent, grades: updatedGrades };
    });
  };

  useEffect(() => {
    setStudents([...reciveSdata]);
  }, [reciveSdata]);
  const handleSave = async () => {

    setIsLoader(true);
    setIsEditing(false);
    setStudents((prevStudents) =>
      prevStudents.map((student) =>
        student.studentid === selectedStudent.studentid
          ? selectedStudent
          : student
      )
    );
    console.log(students, selectedStudent, "student");
    function addOrReplaceStudent(students, selectedStudent) {
      console.log(students);

      const index = students.findIndex(student => student.studentid === selectedStudent.studentid);
      if (index !== -1) {
        students[index] = selectedStudent;
      } else {
        students.push(selectedStudent);
      }
    }
    addOrReplaceStudent(students, selectedStudent);
    console.log(students);
    const insertData = {
      added_by: "1",
      class: classReceive,
      section: SectonReceive,
      school_code: userinfo[0]?.institution,
      studentData: students,
      academic_year: academic_year,
      active_exam_number: currentTerm, // Add the current term to the data
    };
    console.log(students, "student");
    const insertDataURL = `${process.env.REACT_APP_nodewsPrefix}/addMarksReports`;
    axios
      .post(insertDataURL, insertData, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },

      })

      .then(async (response) => {
        console.log(response.data);

        await handleSubmitAPI()
      })
      .catch((err) => {
        console.log(err);
      });

  };
  const handleSubmitAPI = async () => {
    try {
      const submitData = {
        class: classReceive,
        section: SectonReceive,
        school_code: userinfo[0]?.institution,
        academic_year: academic_year,
      };
      const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getMarksReports?schoolcode=${userinfo[0]?.institution}&class=${classReceive}&section=${SectonReceive}&academic_year=${academic_year}&department=${department}`;
      const response = await axios.get(baseURL, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      });
      setIsLoader(false)
      setStudents([...response.data.studentData]);
      handleClose();
      console.log(" Submit API Response:", response.data);
      setEditableTerm("")

    } catch (error) {
      console.error(" Error submitting data:", error);
    }
  };
  const handleHPC = (studentid, schoolcode, academic_year, added_by) => {
    const baseUrl =
      `${process.env.REACT_APP_wsPrefix}/HPC/grade_1_2/contents/index.html`;
    const params = new URLSearchParams({
      studentid,
      schoolcode,
      academic_year,
      added_by,
      token,
    }).toString();

    window.open(`${baseUrl}?${params}`, "_blank");
  };

  useEffect(() => {
    if (reciveSdata.length > 0) {
      const termKeys = Object.keys(reciveSdata[0].grades || {});
      const dynamicColumns = termKeys.map((term) => ({
        field: `${term} Grades`,
        headerName: `${term.replace(/(\D)(\d)/, "$1 $2").charAt(0).toUpperCase() + term.replace(/(\D)(\d)/, "$1 $2").slice(1)} Average`,
        flex: 0.5,
        valueGetter: (params) => params.row[`${term}_average`] || "N/A",
      }));

      const staticColumns = [
        { field: "rollNumber", headerName: "Roll Number", flex: 0.5 },
        { field: "name", headerName: "Student Name", flex: 1 },

      ];
      const hpcreport = {
        field: "action",
        headerName: "HPC",
        flex: 0.5,
        renderCell: (params) => (
          <PrimaryBgBtn
            color="primary"
            onClick={() =>
              handleHPC(
                params.row.id,
                schoolCode,
                academic_year,
                1
              )
            }
          >
            HPC
          </PrimaryBgBtn>
        ),
      };

      const actionColumn = {
        field: "actions",
        headerName: "View Report",
        flex: 0.5,
        renderCell: (params) => (
          <PrimaryBgBtn color="primary" onClick={() => handleOpen(params.row)}>View Report</PrimaryBgBtn>
        ),
      };

      setColumns([...staticColumns, ...dynamicColumns, actionColumn, hpcreport]);
    }
  }, [reciveSdata]);
  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <Box>
          <h2>Class: {classReceive} - Section: {SectonReceive}</h2>
        </Box>
      </Box>
      <Box sx={{ width: "100%", overflowX: "auto" }}>
  <DataGrid
    autoHeight
        rows={students
          ?.map((student) => ({
            id: student.studentid,
            rollNumber: student.rollno,
            ...student,
          }))
          .sort((a, b) => a.rollNumber - b.rollNumber)
        }
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        disableRowSelectionOnClick
        sx={{
          minWidth: 900, 
          '& .MuiDataGrid-columnHeaders': { minWidth: 900  },
          '& .MuiDataGrid-cell:last-child, .MuiDataGrid-cell:nth-last-child(2)': { padding: { xs: '0 !important', md: '0px 10px !important' }, justifyContent: { xs: 'center !important', md: 'flex-start !important' } },
          '& .MuiDataGrid-cell .btn': { minWidth: { xs: '95px', md: "120px" }},
        }}
      />
    </Box>

      {/* USe CustomDialog for this */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth sx={{ minHeight: 250 }}>
        <DialogTitle>Progress Report - {selectedStudent?.name}</DialogTitle>
        {isLoader ? <Loader /> :
          <DialogContent>
            {selectedStudent && (
              <Box>
                <table>
                  <thead>
                    <tr>
                      <th>Subject</th>
                      {Object.keys(selectedStudent.grades).map((term) => (
                        <th key={term}>{term.replace(/(\D)(\d)/, "$1 $2").charAt(0).toUpperCase() + term.replace(/(\D)(\d)/, "$1 $2").slice(1)}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(selectedStudent.grades)[0]?.map((subject) => (
                      <tr key={subject.subjectid} className="subjectList">
                        <td>{subject.subject_name}</td>
                        {Object.keys(selectedStudent.grades).map((term) => {
                          const isCurrentTerm =
                            term === `term${selectedStudent?.active_exam_number}` || term === `semester${selectedStudent?.active_exam_number}`;

                          const termData = selectedStudent.grades[term].find(
                            (item) => item.subjectid === subject.subjectid
                          );
                          const editableTermInfo = term === editableTerm
                          return (

                            <td key={term}>
                              <TextField
                                value={termData?.subject_marks || ""}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  if (/^\d{0,3}(\.\d{0,2})?$/.test(value) || value === "") {
                                    handleMarksChange(term, subject.subjectid, value);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (["e", "E", "+", "-"].includes(e.key) || (e.key === "." && e.target.value.includes("."))) {
                                    e.preventDefault();
                                  }
                                }}
                                size="small"
                                type="number"
                                step={0.1}

                                disabled={!isEditing || !(isCurrentTerm || editableTermInfo)} // Disable if not editing or not the current term and enable when MdLockOpen
                              />
                              {
                                (isEditing && userinfo[0]?.department.toLowerCase() === "admin") &&
                                <td className="lockIcon">
                                  <IconButton onClick={() => toggleEdit(term)}>
                                    {editableTerm === term ? <MdLockOpen /> : <MdLockOutline />}
                                  </IconButton>
                                </td>
                              }

                            </td>
                          );
                        })}

                      </tr>
                    ))}
                  </tbody>
                </table>

                <Box marginTop="20px">
                  <h3>Remarks</h3>
                  {Object.keys(selectedStudent.grades).map((term) => {
                    const isCurrentTerm =
                      term === `term${selectedStudent?.active_exam_number}` || term === `semester${selectedStudent?.active_exam_number}`;
                    const editableTermInfo = term === editableTerm
                    return (
                      <TextField
                        marginBottom="10px"
                        key={term}
                        label={`${term.replace(/^./, char => char.toUpperCase()).replace(/(\D)(\d)/, "$1 $2")} Remarks`}
                        multiline
                        fullWidth
                        rows={2}
                        value={selectedStudent?.remarks?.[term] || ""}
                        onChange={(e) =>
                          setSelectedStudent((prev) => ({
                            ...prev,
                            remarks: { ...prev.remarks, [term]: e.target.value },
                          }))
                        }
                        disabled={!isEditing || !(isCurrentTerm || editableTermInfo)}
                        sx={{ mt: 1 }}

                      />

                    );
                  })}
                </Box>

                <Box display="flex" justifyContent="flex-end" marginTop="20px" gap={2}>
                  <SecondaryOutlineBtn color="primary" onClick={handleClose}>
                    Cancel
                  </SecondaryOutlineBtn>
                  {isEditing ? (
                    <PrimaryBgBtn color="primary" onClick={handleSave}>Save</PrimaryBgBtn>
                  ) : (
                    <PrimaryBgBtn color="primary" onClick={handleEditClick}>Edit</PrimaryBgBtn>
                  )}
                </Box>
              </Box>
            )}
          </DialogContent>}
      </Dialog>
    </>
  );
};

export default StudentList;
